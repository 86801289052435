import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const ConsultaAvisosEntreFechas = () => {
  const { store } = useContext(Context);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [reporte, setReporte] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [formData, setFormData] = useState({
    empresa_terminal_id: "",
    empresa: "",
    fecha_inicio: "",
    fecha_final: "",
    filter: "",
  });

  const fetchempresasysuscps = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_empresas_terminal_solo_cps",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setEmpresas(response.data.empresas_terminal);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar las empresas disponibles.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  useEffect(() => {
    fetchempresasysuscps();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const openCompanySelection = () => {
    MySwal.fire({
      title: (
        <p>
          <i
            className="fa-solid fa-user-tie"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Empresas de Transporte
        </p>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${empresas
            .map(
              (company) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${company.id}"
              data-name="${company.empresa.nombre}">  <!-- Añadimos data-name con el nombre -->
              ${company.empresa.nombre} <br> ${company.empresa.rif}
            </button>`
            )
            .join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredEmpresas = empresas.filter(
            (company) =>
              company.empresa.nombre.toLowerCase().includes(query) ||
              company.empresa.rif.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredEmpresas
            .map(
              (company) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${company.id}"
                  data-name="${company.empresa.nombre}">  <!-- Añadimos data-name con el nombre -->
                  ${company.empresa.nombre} <br> ${company.empresa.rif}
                </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const companyId = button.dataset.id;
              const companyName = button.dataset.name; // Usamos el nombre de la empresa
              selectCompany(companyId, companyName); // Pasamos el ID y el nombre
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const companyId = button.dataset.id;
            const companyName = button.dataset.name;
            selectCompany(companyId, companyName); // Pasamos el ID y el nombre
          });
        });
      },
    });
  };

  const deleteAviso = async (activationId) => {
    try {
      const result = await MySwal.fire({
        title: "Confirmar eliminación",
        text: "¿Estás seguro de que deseas eliminar este registro?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Eliminar",
      });

      if (result.isConfirmed) {
        const response = await axios.delete(
          `${process.env.BACKEND_URL}/api/eliminar_aviso_de_cobro/${activationId}`,
          {
            headers: { Authorization: `Bearer ${store.token}` },
          }
        );
        await MySwal.fire("Eliminado", response.data.message, "success");

        // Simular un evento para buscarRegistros
        const simulatedEvent = { preventDefault: () => {} };
        buscarRegistros(simulatedEvent); // Llamar la función tal como estaba definida
        return true;
      }
    } catch (error) {
      console.error("Error en la eliminación:", error);
      const errorMsg =
        error.response?.data?.message || "Error al eliminar el registro.";
      await MySwal.fire("Error", errorMsg, "error");
    }
    return false;
  };

  const selectCompany = (companyId, companyName) => {
    // Encuentra la empresa seleccionada
    const empresaSeleccionada = empresas.find(
      (empresa) => empresa.id === parseInt(companyId)
    );

    setFormData({
      ...formData,
      empresa: companyName, // Nombre de la empresa
      empresa_terminal_id: companyId, // ID de la empresa
      numero_cps: "", // Resetea el campo de CPS
    });

    setCpsOptions(cpsOptions); // Guarda las opciones de CPS para el <select>
    MySwal.close(); // Cierra el modal
  };

  const buscarRegistros = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/get_avisos_cobro",
        {
          headers: { Authorization: "Bearer " + store.token },
          params: formData, // Este es el cambio, 'params' pasa los datos como query parameters
        }
      );

      Swal.fire("Éxito", "Enviado Exitosamente", "success");
      setReporte(response.data.avisos);
    } catch (error) {
      Swal.fire(
        "Error",
        error.response?.data?.message || "Error interno",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const showRecordsPdfDownload = async (records) => {
    try {
      setIsLoading(true);

      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Elaborando PDF...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const detalles = records.detalles_por_dia;
      const totales = records.totales_generales;

      if (Array.isArray(detalles) && detalles.length > 0) {
        Swal.close();

        const formatDate = (dateString) => {
          const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
          const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
          const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
          const year = date.getUTCFullYear();
          return `${day}-${month}-${year}`;
        };

        // Generar el PDF
        const pdfBlob = await pdf(
          <EntreFechasDetailPDF
            records={detalles}
            fechaInicio={formatDate(fechaInicio)}
            fechaFinal={formatDate(fechaFinal)}
            formatDate={formatDate}
            totales={totales}
          />
        ).toBlob();

        // Crear un enlace de descarga programático
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download =
          formatDate(fechaInicio) +
          "-" +
          formatDate(fechaFinal) +
          "-reporteRL.pdf";
        document.body.appendChild(link);
        link.click(); // Simular clic para descargar el archivo
        document.body.removeChild(link);
      } else {
        Swal.fire({
          title: `No datos suficientes para poder generar un reporte`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos. Por favor, intentelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-file"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Busca los avisos de cobro entre fechas.</p>
        </div>
        <div className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100">
          <div
            className="container asklnduiashndaijsnxansxsaas3"
            style={{ marginBottom: "20px" }}
          >
            <form
              onSubmit={buscarRegistros}
              className="formularioDeLogin s6d54fsd89f7sd98f4we98f7wr89 w-100"
            >
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="date"
                    required
                    name="fecha_inicio"
                    value={formData.fecha_inicio}
                    onChange={handleChange}
                  />
                  <label>Fecha Inicio</label>
                </div>
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="date"
                    required
                    name="fecha_final"
                    value={formData.fecha_final}
                    onChange={handleChange}
                  />
                  <label>Fecha Final</label>
                </div>
              </div>
              {/* <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="text"
                    name="empresa"
                    onClick={openCompanySelection}
                    readOnly
                    onChange={handleChange}
                    value={formData.empresa}
                    required
                    style={{ textTransform: "uppercase" }}
                  />
                  <label>Línea de Transporte (opcional)</label>
                </div>
                <div className="input-group-login2">
                  <select
                    className="inputDeLogin"
                    name="filter"
                    value={formData.filter}
                    onChange={handleChange}
                    style={{ cursor: "pointer" }}
                  >
                    <option value="" disabled>
                      Selecciona filtro
                    </option>
                    <option value="PAGADOS">PAGADOS</option>
                    <option value="NO_PAGOS">NO PAGADOS</option>
                  </select>
                  <label>Filtro (Opcional)</label>
                </div>
              </div> */}
              <button
                className="buttonCargadeDatosDeLoginregistromovimiento"
                disabled={isSubmitting}
                type="submit"
                style={{ width: "200px" }}
              >
                {isSubmitting ? "Buscando..." : "Buscar Avisos"}{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </form>
          </div>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
        <hr style={{ width: "90%" }}></hr>
        {reporte && (
          <div style={{ padding: "10px", width: "100%" }}>
            <div
              className="custom-table-container m-0"
              style={{
                maxWidth: "100%",
                padding: "10px",
                overflow: "auto",
                maxHeight: "80vh",
              }}
            >
              <table
                className="custom-table rounded-table"
                style={{ minWidth: "600px" }}
              >
                <thead>
                  {/* Pendiente colocar background segun el estatus del aviso de cobro */}
                  <tr>
                    <th
                      className="table-header"
                      style={{ minWidth: "80px", fontSize: "11px" }}
                    >
                      Fecha Viaje
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "80px", fontSize: "11px" }}
                    >
                      Valor BCV
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      Número Listín
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      Empresa
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      Destino Final
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Adultos
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Tercera Edad
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Discapa-citados
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Niños 4 o menos
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Cortesias
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Total Pasajeros
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      Total Venta (Bs.)
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "80px", fontSize: "11px" }}
                    >
                      Listín (Bs.)
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "80px", fontSize: "11px" }}
                    >
                      Tasa Salida (Bs.)
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      Total (Bs.)
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reporte.map((aviso) => {
                    return (
                      <tr key={aviso.id} className="table-row">
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.listin.activation.fecha_de_viaje}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.valor_dolar} Bs.
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.listin.n_listin_terminal}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.listin.activation.empresa.nombre}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {
                            aviso.listin.activation.ruta.destino_final
                              .destino_final_detalle.descripcion
                          }
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.adultos}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.tercera_edad}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.discapacitados}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.ninos_4_menos}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.cortesias}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.adultos +
                            aviso.tercera_edad +
                            aviso.discapacitados +
                            aviso.ninos_4_menos +
                            aviso.cortesias}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.total_venta_bolivares.toFixed(2)} Bs.
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.precio_listin_bolivares.toFixed(2)} Bs.
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.precio_tasa_bolivares.toFixed(2)} Bs.
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.total_bolivares.toFixed(2)} Bs.
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          <button
                            className="btn btn-danger d-flex"
                            onClick={() => deleteAviso(aviso.id)}
                            style={{
                              color: "white",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <i
                              className="fa-solid fa-trash"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
