import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../store/appContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

export const DestinoFinal = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    destino_final: "",
    km_distancia: "",
    destino_final_front: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [destino, setDestino] = useState([]);

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/public/GetDestinoGeneral"
      );

      setDestino(response.data.destinos_finales);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar los destinos, por favor vuelve a intentarlo.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Desactiva el botón

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/destinofinalKmDistanciaTerminal",
        formData,
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setFormData({
        destino_final: "",
        km_distancia: "",
      });
      mostrarAlerta1();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar el destino";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar", error);
    } finally {
      setIsSubmitting(false); // Reactiva el botón después del envío
    }
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Destino Registrado con Éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar el destino</p>,
      html: <p>{errorMessage}</p>,
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const fetchTerminal = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Destinos...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_destino_final_por_terminal",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.destinos_terminal;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Destinos Finales",
          html: `<p style="text-align:center; margin:20px;">No se encontraron Destinos.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
            <tr>
              <th class="table-header" style="text-align: start">Code</th>
              <th class="table-header" style="text-align: start">Destino Final</th>
              <th class="table-header" style="text-align: start">Precio</th>
              <th class="table-header" style="text-align: start">Distancia (km)</th>
            </tr>
          `;

        const tableRows = data
          .map(
            (item) => `
                <tr>
                  <td style="text-align: center">${item.destino_final_detalle.code}</td>
                  <td>${item.destino_final_detalle.descripcion}</td>
                  <td style="text-align: center">${item.precio} $</td>
                  <td style="text-align: center">${item.km_distancia} km</td>
                </tr>
              `
          )
          .join("");

        const table = `
            <input type="text" id="search-listines" placeholder="Buscar..." class="swal2-input" style="margin-bottom: 10px;">
            <div style="max-height: 400px; overflow-y: auto;">
              <table class="custom-table rounded-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4" style="border-collapse: collapse; font-size: 12px; min-width: 450px">
                <thead>
                  ${tableHeaders}
                </thead>
                <tbody id="listines-list" style="text-align: start">
                  ${tableRows}
                </tbody>
              </table>
            </div>
          `;

        MySwal.fire({
          title: "Empresas",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const searchInput = document.getElementById("search-listines");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const listinesList = document.getElementById("listines-list");
              const filteredRows = data
                .filter(
                  (item) =>
                    item.destino_final_detalle.descripcion
                      .toLowerCase()
                      .includes(searchTerm) ||
                    item.destino_final_detalle.code
                      .toLowerCase()
                      .includes(searchTerm)
                )
                .map(
                  (item) => `
                      <tr>
                        <td style="text-align: center">${item.destino_final_detalle.code}</td>
                        <td>${item.destino_final_detalle.descripcion}</td>
                        <td style="text-align: center">${item.precio} $</td>
                        <td style="text-align: center">${item.km_distancia} km</td>
                      </tr>
                    `
                )
                .join("");
              listinesList.innerHTML = filteredRows;
            });

            // Delegación de eventos para los botones de cambio de estado
            document
              .getElementById("listines-list")
              .addEventListener("click", async (event) => {
                if (event.target.classList.contains("change-status")) {
                  const button = event.target;
                  const userId = button.getAttribute("data-id");
                  const userStatus = button.getAttribute("data-status");

                  const actionText =
                    userStatus === "ACTIVE" ? "bloquear" : "activar";
                  const confirmationText =
                    userStatus === "ACTIVE"
                      ? "¡Si haces esto el usuario no podrá usar más su cuenta!"
                      : "¡El usuario podrá usar su cuenta nuevamente!";

                  const confirmation = await MySwal.fire({
                    title: `¿Estás seguro de que quieres ${actionText} este usuario?`,
                    text: confirmationText,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: `Sí, ${actionText}`,
                    cancelButtonText: "Cancelar",
                  });

                  if (confirmation.isConfirmed) {
                    MySwal.fire({
                      title: `${actionText.charAt(0).toUpperCase() + actionText.slice(1)}...`,
                      html: `<p style="text-align:center; margin:20px;">Actualizando estado del usuario...</p>`,
                      allowOutsideClick: false,
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });

                    try {
                      await axios.put(
                        `${process.env.BACKEND_URL}/api/empresa/${userId}/updateStatus`,
                        {},
                        {
                          headers: {
                            Authorization: `Bearer ${store.token}`,
                          },
                        }
                      );

                      MySwal.fire({
                        title: `Usuario ${actionText === "bloquear" ? "bloqueado" : "activado"}`,
                        text: `El usuario ha sido ${actionText === "bloquear" ? "bloqueado" : "activado"} exitosamente.`,
                        icon: "success",
                        confirmButtonText: "Cerrar",
                      });

                      fetchTerminal();
                    } catch (error) {
                      MySwal.fire({
                        title: "Error",
                        text: "Hubo un error al intentar actualizar el estado del usuario",
                        icon: "error",
                        confirmButtonText: "Cerrar",
                      });
                      console.error(
                        "Error al cambiar el estado del usuario:",
                        error
                      );
                    }
                  }
                }
              });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener usuarios", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener usuarios. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const openDestinoSelection = () => {
    MySwal.fire({
      title: (
        <div className="d-flex text-align-center justify-content-center align-item-center">
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          <h6 style={{ margin: "0" }}>Destino Final</h6>
        </div>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${destino.map((destino) => `<button data-id="${destino.id}" data-destino-final="${destino.destino_final}" class="btn company-option company-list-activation-list"><strong>${destino.code} - </strong> ${destino.destino_final}</button>`).join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredCompanies = destino.filter(
            (destino) =>
              destino.destino_final.toLowerCase().includes(query) ||
              destino.code.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredCompanies
            .map(
              (destino) =>
                `<button 
                    class="btn company-option company-list-activation-list" 
                    data-id="${destino.id}" 
                    data-destino-final="${destino.destino_final}">
                    <strong>${destino.code} - </strong> ${destino.destino_final}
                </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const selectedCompany = {
                id: button.getAttribute("data-id"),
                destino_final: button.getAttribute("data-destino-final"),
              };
              selectCompany(selectedCompany);
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const selectedCompany = {
              id: button.getAttribute("data-id"),
              destino_final: button.getAttribute("data-destino-final"),
            };
            selectCompany(selectedCompany);
          });
        });
      },
    });
  };

  const selectCompany = (selectedCompany) => {
    setFormData((prevData) => ({
      ...prevData,
      destino_final: selectedCompany.id, // Asigna el id
      destino_final_front: selectedCompany.destino_final, // Asigna el texto
    }));

    Swal.close(); // Cierra el modal
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-route"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Registra tus Destinos Finales</p>
        </div>
        <div
          className="container asklnduiashndaijsnxansxsaas3"
          style={{ marginBottom: "20px" }}
        >
          <form
            onSubmit={handleSubmit}
            className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="destino_final_front"
                  required
                  value={formData.destino_final_front || ""}
                  readOnly
                  onClick={openDestinoSelection}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Destino Final</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="km_distancia"
                  required
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      km_distancia: e.target.value,
                    }))
                  }
                  value={formData.km_distancia}
                  placeholder="Ejemplo: 253.53"
                />
                <label>Km Distancia</label>
              </div>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Registrando..." : "Registrar"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
          <button
            className="asd98q7wd65q1wd8xqw49x8e4wc"
            onClick={fetchTerminal}
          >
            <i
              className="fa-solid fa-list"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Ver destinos
          </button>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
