import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../store/appContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "../../component/scrollToTop";
import { BackendURL } from "../../component/backendURL";
import "../../../styles/AddDriverForm.css";

import LoadingOverlay from "../../pages/LadingOverlay";

import { Front2 } from "../../pages/Front2";
import { Navbar } from "./Navbar";
import { Navbar_sup } from "./Navbar_sup";
import { UserHome } from "./userHome";
import { AyudaYSoporte } from "./DIR_RECAUDACION/AyudaYSoporte";
import { Elab_listin_rl } from "./DIR_RECAUDACION/Elab_listin_rl";
import { Cierre_control_diario } from "./DIR_RECAUDACION/Cierre_control_diario";
import { AvisoDeCobro } from "./DIR_RECAUDACION/AvisoDeCobro";
import { RegistrosCpsEditRegistroDeCpsEditNuevo } from "./DIR_RECAUDACION/RegistroDeCpsEditNuevo";
import { EnvioAvisosDeCobro } from "./DIR_RECAUDACION/EnvioAvisosDeCobro";
import { ConsultaAvisosEntreFechas } from "./DIR_RECAUDACION/ConsultaAvisosEntreFechas";
import { RegistroUsuarios } from "./DIR_RECAUDACION/RegistroUsuarios";
import { RegistroDePago1 } from "./DIR_RECAUDACION/RegistroDePago1";
import { RegistroDePago2 } from "./DIR_RECAUDACION/RegistroDePago2";
import { RegistroDePago3Definitivo } from "./DIR_RECAUDACION/RegistroDePago3Definitivo";
import { EnvioAvisosDeCobroIndividual } from "./DIR_RECAUDACION/EnvioAvisosDeCobroIndividual";
import { PagosNoCerrados } from "./DIR_RECAUDACION/PagosNoCerrados";
import { CierreCajaDiario } from "./DIR_RECAUDACION/CierreCajaDiario";
import { VerCierres } from "./DIR_RECAUDACION/VerCierres";

export const Layout_dir_recau = () => {
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleRouteChange = (event) => {
    localStorage.setItem("lastVisitedRoute", event.url);
  };

  const basename = process.env.BASENAME || "";

  if (!process.env.BACKEND_URL || process.env.BACKEND_URL === "")
    return <BackendURL />;

  return (
    <div style={{ display: "flex" }}>
      <BrowserRouter basename={basename}>
        <ScrollToTop>
          <Navbar />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              overflow: "auto",
            }}
          >
            <Navbar_sup />
            <Routes onChange={handleRouteChange}>
              <Route element={<Front2 />} path="/" />
              <Route element={<AvisoDeCobro />} path="/AvisoDeCobro" />
              <Route element={<RegistroUsuarios />} path="/RegistroUsuarios" />
              <Route element={<RegistroDePago1 />} path="/RegistroPago" />
              <Route element={<RegistroDePago2 />} path="/RegistroPago2" />
              <Route
                element={<RegistroDePago3Definitivo />}
                path="/RegistroPago3"
              />
              <Route element={<PagosNoCerrados />} path="/PagosNoCerrados" />
              <Route element={<CierreCajaDiario />} path="/CierreCajaDiario" />
              <Route element={<VerCierres />} path="/Cierres" />
              <Route
                element={<ConsultaAvisosEntreFechas />}
                path="/ConsultaAvisosEntreFechas"
              />
              <Route
                element={<EnvioAvisosDeCobro />}
                path="/EnvioAvisoDeCobro"
              />
              <Route
                element={<EnvioAvisosDeCobroIndividual />}
                path="/EnvioAvisoDeCobroIndividual"
              />
              <Route
                element={<RegistrosCpsEditRegistroDeCpsEditNuevo />}
                path="/RegistrosCpsVision"
              />
              <Route element={<AyudaYSoporte />} path="/AyudaYSoporte" />
              <Route
                element={<Cierre_control_diario />}
                path="/CierreListinesRutasLargas"
              />
              <Route
                element={<Elab_listin_rl />}
                path="/ElaboracionListinRutasLargas"
              />
              <Route element={<Front2 />} path="/Configuraciones" />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </ScrollToTop>
      </BrowserRouter>
      {isLoading && <LoadingOverlay />}
    </div>
  );
};
