import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../store/appContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "../../component/scrollToTop";
import { BackendURL } from "../../component/backendURL";
import "../../../styles/AddDriverForm.css";

import LoadingOverlay from "../../pages/LadingOverlay";

import { Front2 } from "../../pages/Front2";
import { Navbar } from "./Navbar";
import { Navbar_sup } from "./Navbar_sup";
import { UserHome } from "./userHome";
import { RegistroActivacionesHoy } from "../Dir/DIR_TERMINAL/RegistroActivacionesHoy";
import { AyudaYSoporte } from "../Dir/DIR_TERMINAL/AyudaYSoporte";
import { RegistrosCpsEditRegistroDeCpsEditNuevo } from "../Dir/DIR_TERMINAL/RegistroDeCpsEditNuevo";
import { ActivacionesHoyStatusNuevo } from "../Dir/DIR_TERMINAL/ActivacionesHoyStatusNuevo";

export const Layout_pista = () => {
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleRouteChange = (event) => {
    localStorage.setItem("lastVisitedRoute", event.url);
  };

  const basename = process.env.BASENAME || "";

  if (!process.env.BACKEND_URL || process.env.BACKEND_URL === "")
    return <BackendURL />;

  return (
    <div style={{ display: "flex" }}>
      <BrowserRouter basename={basename}>
        <ScrollToTop>
          <Navbar />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              overflow: "auto",
            }}
          >
            <Navbar_sup />
            <Routes onChange={handleRouteChange}>
              <Route element={<UserHome />} path="/" />
              <Route
                element={<ActivacionesHoyStatusNuevo />}
                path="/RegistroActivacionesHoy"
              />
              <Route
                element={<RegistrosCpsEditRegistroDeCpsEditNuevo />}
                path="/RegistrosCpsVision"
              />
              <Route element={<Front2 />} path="/Configuraciones" />
              <Route element={<AyudaYSoporte />} path="/AyudaYSoporte" />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </ScrollToTop>
      </BrowserRouter>
      {isLoading && <LoadingOverlay />}
    </div>
  );
};
