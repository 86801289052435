import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const EnvioAvisosDeCobro = () => {
  const { store } = useContext(Context);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [reporte, setReporte] = useState(null);
  const [records, setRecords] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const enviarCorreos = async () => {
    const confirmResult = await Swal.fire({
      title: "¿Estás seguro?",
      text: "Esto enviará los avisos de cobro a todas las Empresas que tengan un correo electrónico asignado. ¿Quieres continuar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#24827c",
      cancelButtonColor: "#980000",
      confirmButtonText: "Sí, enviar",
      cancelButtonText: "Cancelar",
    });

    if (confirmResult.isConfirmed) {
      setIsSubmitting(true);
      MySwal.fire({
        title: "Enviando Correos...",
        html: `<p>Por favor, no reinicies la página, esto podría tardar varios minutos...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });

      try {
        const response = await axios.post(
          process.env.BACKEND_URL + "/api/avisos_cobro/enviar",
          { fecha_inicio: fechaInicio, fecha_final: fechaFinal },
          {
            headers: { Authorization: "Bearer " + store.token },
          }
        );

        MySwal.close();
        Swal.fire("Éxito", "Enviado Exitosamente", "success");
        setReporte(response.data);
        fetchTodayRecords();
      } catch (error) {
        Swal.fire(
          "Error",
          error.response?.data?.message || "Error interno",
          "error"
        );
      } finally {
        setIsSubmitting(false);
      }
    } else {
      Swal.fire("Cancelado", "El envío de correos fue cancelado", "info");
    }
  };

  const fetchTodayRecords = async () => {
    try {
      // Configurar el token de autorización en los headers
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/audit_envio_avisos",
        {
          headers: {
            Authorization: `Bearer ${store.token}`, // Corrige el uso del token
          },
        }
      );
      setRecords(response.data.audit_envio); // Guarda los datos obtenidos
    } catch (error) {
      console.error("Error al obtener registros de hoy:", error);
    }
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-envelope"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>
            Envía los Avisos de cobro a los correos electrónicos de las Empresas
            de Transporte.
          </p>
        </div>
        <div className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100">
          <div
            className="container asklnduiashndaijsnxansxsaas3"
            style={{ marginBottom: "20px", marginTop: "-40px" }}
          >
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento m-0"
              onClick={enviarCorreos}
              disabled={isSubmitting}
              style={{ width: "200px" }}
            >
              {isSubmitting ? "Enviando..." : "Enviar Correos"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
        <hr style={{ width: "90%" }}></hr>
        <div style={{ padding: "10px", width: "100%" }}>
          <div
            className="custom-table-container m-0"
            style={{
              maxWidth: "100%",
              padding: "10px",
              overflow: "auto",
              maxHeight: "80vh",
            }}
          >
            <table
              className="custom-table rounded-table"
              style={{ minWidth: "100%" }}
            >
              <thead>
                {/* Pendiente colocar background segun el estatus del aviso de cobro */}
                <tr>
                  <th className="table-header">Fecha</th>
                  <th className="table-header">Enviados por</th>
                </tr>
              </thead>
              <tbody>
                {records &&
                  records &&
                  records.map((envio, index) => (
                    <tr key={envio.id} style={{ fontSize: "11px" }}>
                      <td>{new Date(envio.timestamp).toLocaleString()}</td>
                      <td>{envio.enviados_por}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
