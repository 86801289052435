import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const AvisoDeCobro = () => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    listin_id: "",
    vista: "",
    adultos: 0,
    tercera_edad: 0,
    discapacitados: 0,
    ninos_4_menos: 0,
    cortesias: 0,
    empresa: "",
  });
  const [activaciones, setActivaciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [records, setRecords] = useState([]);
  const [listines, setListines] = useState([]);
  const [empresaId, setEmpresaId] = useState(0);
  const [loadingDriver, setLoadingDriver] = useState({
    conductor_1: false,
    conductor_2: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchTodayRecords = async () => {
    try {
      // Configurar el token de autorización en los headers
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/listines_filtrados",
        {
          headers: {
            Authorization: `Bearer ${store.token}`, // Corrige el uso del token
          },
        }
      );
      setRecords(response.data.listines); // Guarda los datos obtenidos
    } catch (error) {
      console.error("Error al obtener registros de hoy:", error);
    } finally {
      setLoading(false); // Cambia a `false` al terminar la carga
    }
  };

  useEffect(() => {
    fetchTodayRecords();

    // Limpiar efectos al desmontar el componente (si tienes intervalos o eventos)
    return () => setLoading(false);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const openActivationSelection = () => {
    setEmpresaId(0);
    setFormData({
      listin_id: "",
      vista: "",
      adultos: 0,
      tercera_edad: 0,
      discapacitados: 0,
      ninos_4_menos: 0,
      cortesias: 0,
      empresa: "",
    });
    MySwal.fire({
      title: (
        <p>
          <i
            className="fa-solid fa-bus"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Listines Pendientes
        </p>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar listin"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${records
            .map(
              (listin) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${listin.id}"
              data-listin="${listin.n_listin_terminal}"
              data-empresa="${listin.activation.empresa.nombre}"
              >
              ${listin.activation.empresa.nombre} <br> listín: ${listin.n_listin_terminal} <br> Fecha Viaje: ${listin.activation.fecha_de_viaje}
            </button>`
            )
            .join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredEmpresas = records.filter(
            (listin) =>
              listin.activation.empresa.nombre.toLowerCase().includes(query) ||
              listin.n_listin_terminal.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredEmpresas
            .map(
              (listin) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${listin.id}"
                  data-listin="${listin.n_listin_terminal}"
                  data-empresa="${listin.activation.empresa.nombre}"
                  >
                    ${listin.activation.empresa.nombre} <br> listín: ${listin.n_listin_terminal} <br> Fecha Viaje: ${listin.activation.fecha_de_viaje}
                </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const listinId = button.dataset.id;
              const listin = button.dataset.listin;
              const empresa = button.dataset.empresa;
              selectListin(listinId, listin, empresa); // Pasamos el ID y el nombre
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const listinId = button.dataset.id;
            const listin = button.dataset.listin;
            const empresa = button.dataset.empresa;
            selectListin(listinId, listin, empresa); // Pasamos el ID y el nombre
          });
        });
      },
    });
  };

  const selectListin = (listinId, listin, empresa) => {
    setFormData({
      ...formData,
      listin_id: listinId,
      vista: listin,
      empresa: empresa,
    });
    setEmpresaId(empresaId);
    MySwal.close(); // Cerrar la ventana de SweetAlert
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Enviar los datos del formulario al backend
      await axios.post(
        process.env.BACKEND_URL + "/api/register_aviso_cobro",
        formData,
        {
          headers: { Authorization: "Bearer " + store.token },
        }
      );

      // Limpiar el formulario
      setFormData({
        listin_id: "",
        vista: "",
        adultos: 0,
        tercera_edad: 0,
        discapacitados: 0,
        ninos_4_menos: 0,
        cortesias: 0,
        empresa: "",
      });

      mostrarAlerta1();
      fetchTodayRecords();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar el aviso de cobro";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Aviso de Cobro Registrado con Éxito!</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar el Aviso de Cobro</p>,
      html: <p>{errorMessage}</p>,
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-file-invoice"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Elaboración de Avisos de Cobro Andén A</p>
        </div>
        <div
          className="container asklnduiashndaijsnxansxsaas3"
          style={{ marginBottom: "20px" }}
        >
          <form
            onSubmit={handleSubmit}
            className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
            method="GET"
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="activacion"
                  value={formData.vista}
                  onClick={openActivationSelection}
                  readOnly
                  required
                />
                <label>Listines</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="empresa"
                  value={formData.empresa}
                  readOnly
                  required
                />
                <label>Empresa</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="adultos"
                  value={formData.adultos}
                  onChange={handleChange}
                  required
                  min="0"
                  max="90"
                />
                <label>General</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="tercera_edad"
                  value={formData.tercera_edad}
                  onChange={handleChange}
                  required
                  min="0"
                  max="90"
                />
                <label>Tercera Edad</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="discapacitados"
                  value={formData.discapacitados}
                  onChange={handleChange}
                  required
                  style={{ textTransform: "uppercase" }}
                  min="0"
                  max="90"
                />
                <label>Discapacitados</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="ninos_4_menos"
                  value={formData.ninos_4_menos}
                  onChange={handleChange}
                  required
                  min="0"
                  max="90"
                />
                <label>Niños hasta 4 años</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="cortesias"
                  value={formData.cortesias}
                  onChange={handleChange}
                  required
                  min="0"
                  max="90"
                />
                <label>Cortesías</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="total"
                  value={
                    Number(formData.cortesias) +
                    Number(formData.adultos) +
                    Number(formData.ninos_4_menos) +
                    Number(formData.discapacitados) +
                    Number(formData.tercera_edad)
                  }
                  onChange={handleChange}
                  min="0"
                  max="90"
                  readOnly
                />
                <label>Total pasajeros</label>
              </div>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Registrando..." : "Registrar"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
