import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import { Link } from "react-router-dom";

const styles = StyleSheet.create({
  page: { padding: 20 },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: { width: 90, height: 30 },
  logo2: { width: 100, height: 20 },
  letter: { fontSize: 9, padding: 3 },
  letter2: { fontSize: 10 },
  letter3: { fontSize: 8.5, padding: 2 },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableColHeaderNumber: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeaderOther: {
    width: "22.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeaderOther2: {
    width: "11.25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColNumber: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableColOther: {
    width: "22.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableColOther2: {
    width: "11.25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: { margin: 5, fontSize: 9, fontWeight: "bold" },
  tableCell: { margin: 4, fontSize: 9 },
  bottomText: {
    position: "absolute",
    bottom: 30,
    left: 20,
    right: 20,
    textAlign: "center",
    fontSize: 8,
  },
  bottomText2: {
    position: "absolute",
    bottom: 50,
    left: 20,
    right: 20,
    textAlign: "center",
    fontSize: 11,
    fontWeight: "bold",
  },
});

const formatDate = (dateString) => {
  const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
  const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
};

const ListinPDF = ({ formData }) => (
  <Document>
    <Page style={styles.page} size="A4">
      <View style={styles.header}>
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
          style={styles.logo2}
        />
      </View>
      <View style={styles.header}>
        <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
        <View>
          <Text
            style={styles.letter2}
          >{`LISTIN Nº ${formData.numero_listin}`}</Text>
          <Text style={styles.letter2}>Rutas Interurbanas</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          borderWidth: 1,
          borderColor: "#000",
        }}
      >
        <View>
          <Text style={styles.letter}>{`Línea: ${formData.empresa}`}</Text>
          <Text style={styles.letter}>{`Destino: ${formData.destino}`}</Text>
          <Text style={styles.letter}>{`Unidad: ${formData.unidad}`}</Text>
          <Text style={styles.letter}>{`Placa: ${formData.placa}`}</Text>
        </View>

        <View>
          <Text style={styles.letter}>{`Anden: A`}</Text>
          <Text
            style={styles.letter}
          >{`Servicio: ${formData.tipo_servicio}`}</Text>
          <Text
            style={styles.letter}
          >{`Conductor 1: ${formData.nombre_1}`}</Text>
          <Text
            style={styles.letter}
          >{`Conductor 2: ${formData.nombre_2}`}</Text>
        </View>
        <View>
          <Text
            style={styles.letter}
          >{`Fecha: ${formatDate(formData.fecha_de_viaje)}`}</Text>
          <Text
            style={styles.letter}
          >{`Hora de salida: ${formData.hora_salida}`}</Text>
          <Text
            style={styles.letter}
          >{`Cédula 1: ${formData.conductor_1}`}</Text>
          <Text
            style={styles.letter}
          >{`Cédula 2: ${formData.conductor_2}`}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          borderWidth: 1,
          borderColor: "#000",
        }}
      >
        <Text style={styles.letter3}>{`Ruta: ${formData.ruta}`}</Text>
      </View>
      <View style={{ marginTop: 10 }}></View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeaderNumber}>
            <Text style={styles.tableCellHeader}>N°</Text>
          </View>
          <View style={styles.tableColHeaderOther}>
            <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
          </View>
          <View style={styles.tableColHeaderOther2}>
            <Text style={styles.tableCellHeader}>Cédula</Text>
          </View>
          <View style={styles.tableColHeaderOther2}>
            <Text style={styles.tableCellHeader}>Destino</Text>
          </View>
          <View style={styles.tableColHeaderNumber}>
            <Text style={styles.tableCellHeader}>N°</Text>
          </View>
          <View style={styles.tableColHeaderOther}>
            <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
          </View>
          <View style={styles.tableColHeaderOther2}>
            <Text style={styles.tableCellHeader}>Cédula</Text>
          </View>
          <View style={styles.tableColHeaderOther2}>
            <Text style={styles.tableCellHeader}>Destino</Text>
          </View>
        </View>
        {[...Array(30)].map((_, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={styles.tableColNumber}>
              <Text style={styles.tableCell}>{index * 2 + 1}</Text>
            </View>
            <View style={styles.tableColOther}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColOther2}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColOther2}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColNumber}>
              <Text style={styles.tableCell}>{index * 2 + 2}</Text>
            </View>
            <View style={styles.tableColOther}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColOther2}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColOther2}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>
        ))}
      </View>
      <Text style={styles.bottomText2}>ORIGINAL: INTRAVIALCA</Text>
      <Text style={styles.bottomText}>
        El valor del listín es el 3% de la tarifa pagada por pasajero, de
        acuerdo a la publicación del Ministerio del Poder Popular para el
        Transporte, en la Gaceta Oficial de la República Bolivariana de
        Venezuela N° 42.462 de fecha 14/09/2022
      </Text>
    </Page>
    <Page style={styles.page} size="A4">
      <View style={styles.header}>
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
          style={styles.logo2}
        />
      </View>
      <View style={styles.header}>
        <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
        <View>
          <Text
            style={styles.letter2}
          >{`LISTIN Nº ${formData.numero_listin}`}</Text>
          <Text style={styles.letter2}>Rutas Interurbanas</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          borderWidth: 1,
          borderColor: "#000",
        }}
      >
        <View>
          <Text style={styles.letter}>{`Línea: ${formData.empresa}`}</Text>
          <Text style={styles.letter}>{`Destino: ${formData.destino}`}</Text>
          <Text style={styles.letter}>{`Unidad: ${formData.unidad}`}</Text>
          <Text style={styles.letter}>{`Placa: ${formData.placa}`}</Text>
        </View>

        <View>
          <Text style={styles.letter}>{`Anden: A`}</Text>
          <Text
            style={styles.letter}
          >{`Servicio: ${formData.tipo_servicio}`}</Text>
          <Text
            style={styles.letter}
          >{`Conductor 1: ${formData.nombre_1}`}</Text>
          <Text
            style={styles.letter}
          >{`Conductor 2: ${formData.nombre_2}`}</Text>
        </View>
        <View>
          <Text
            style={styles.letter}
          >{`Fecha: ${formatDate(formData.fecha_de_viaje)}`}</Text>
          <Text
            style={styles.letter}
          >{`Hora de salida: ${formData.hora_salida}`}</Text>
          <Text
            style={styles.letter}
          >{`Cédula 1: ${formData.conductor_1}`}</Text>
          <Text
            style={styles.letter}
          >{`Cédula 2: ${formData.conductor_2}`}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          borderWidth: 1,
          borderColor: "#000",
        }}
      >
        <Text style={styles.letter3}>{`Ruta: ${formData.ruta}`}</Text>
      </View>
      <View style={{ marginTop: 10 }}></View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeaderNumber}>
            <Text style={styles.tableCellHeader}>N°</Text>
          </View>
          <View style={styles.tableColHeaderOther}>
            <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
          </View>
          <View style={styles.tableColHeaderOther2}>
            <Text style={styles.tableCellHeader}>Cédula</Text>
          </View>
          <View style={styles.tableColHeaderOther2}>
            <Text style={styles.tableCellHeader}>Destino</Text>
          </View>
          <View style={styles.tableColHeaderNumber}>
            <Text style={styles.tableCellHeader}>N°</Text>
          </View>
          <View style={styles.tableColHeaderOther}>
            <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
          </View>
          <View style={styles.tableColHeaderOther2}>
            <Text style={styles.tableCellHeader}>Cédula</Text>
          </View>
          <View style={styles.tableColHeaderOther2}>
            <Text style={styles.tableCellHeader}>Destino</Text>
          </View>
        </View>
        {[...Array(30)].map((_, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={styles.tableColNumber}>
              <Text style={styles.tableCell}>{index * 2 + 1}</Text>
            </View>
            <View style={styles.tableColOther}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColOther2}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColOther2}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColNumber}>
              <Text style={styles.tableCell}>{index * 2 + 2}</Text>
            </View>
            <View style={styles.tableColOther}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColOther2}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColOther2}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>
        ))}
      </View>
      <Text style={styles.bottomText2}>COPIA: LÍNEA TRANSPORTE</Text>
      <Text style={styles.bottomText}>
        El valor del listín es el 3% de la tarifa pagada por pasajero, de
        acuerdo a la publicación del Ministerio del Poder Popular para el
        Transporte, en la Gaceta Oficial de la República Bolivariana de
        Venezuela N° 42.462 de fecha 14/09/2022
      </Text>
    </Page>
    <Page style={styles.page} size="A4">
      <View style={styles.header}>
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
          style={styles.logo2}
        />
      </View>
      <View style={styles.header}>
        <Text style={styles.letter2}>TERMINAL LA BANDERA</Text>
        <View>
          <Text
            style={styles.letter2}
          >{`LISTIN Nº ${formData.numero_listin}`}</Text>
          <Text style={styles.letter2}>Rutas Interurbanas</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          borderWidth: 1,
          borderColor: "#000",
        }}
      >
        <View>
          <Text style={styles.letter}>{`Línea: ${formData.empresa}`}</Text>
          <Text style={styles.letter}>{`Destino: ${formData.destino}`}</Text>
          <Text style={styles.letter}>{`Unidad: ${formData.unidad}`}</Text>
          <Text style={styles.letter}>{`Placa: ${formData.placa}`}</Text>
        </View>

        <View>
          <Text style={styles.letter}>{`Anden: A`}</Text>
          <Text
            style={styles.letter}
          >{`Servicio: ${formData.tipo_servicio}`}</Text>
          <Text
            style={styles.letter}
          >{`Conductor 1: ${formData.nombre_1}`}</Text>
          <Text
            style={styles.letter}
          >{`Conductor 2: ${formData.nombre_2}`}</Text>
        </View>
        <View>
          <Text
            style={styles.letter}
          >{`Fecha: ${formatDate(formData.fecha_de_viaje)}`}</Text>
          <Text
            style={styles.letter}
          >{`Hora de salida: ${formData.hora_salida}`}</Text>
          <Text
            style={styles.letter}
          >{`Cédula 1: ${formData.conductor_1}`}</Text>
          <Text
            style={styles.letter}
          >{`Cédula 2: ${formData.conductor_2}`}</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          borderWidth: 1,
          borderColor: "#000",
        }}
      >
        <Text style={styles.letter3}>{`Ruta: ${formData.ruta}`}</Text>
      </View>
      <View style={{ marginTop: 10 }}></View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeaderNumber}>
            <Text style={styles.tableCellHeader}>N°</Text>
          </View>
          <View style={styles.tableColHeaderOther}>
            <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
          </View>
          <View style={styles.tableColHeaderOther2}>
            <Text style={styles.tableCellHeader}>Cédula</Text>
          </View>
          <View style={styles.tableColHeaderOther2}>
            <Text style={styles.tableCellHeader}>Destino</Text>
          </View>
          <View style={styles.tableColHeaderNumber}>
            <Text style={styles.tableCellHeader}>N°</Text>
          </View>
          <View style={styles.tableColHeaderOther}>
            <Text style={styles.tableCellHeader}>Nombre y Apellido</Text>
          </View>
          <View style={styles.tableColHeaderOther2}>
            <Text style={styles.tableCellHeader}>Cédula</Text>
          </View>
          <View style={styles.tableColHeaderOther2}>
            <Text style={styles.tableCellHeader}>Destino</Text>
          </View>
        </View>
        {[...Array(30)].map((_, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={styles.tableColNumber}>
              <Text style={styles.tableCell}>{index * 2 + 1}</Text>
            </View>
            <View style={styles.tableColOther}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColOther2}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColOther2}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColNumber}>
              <Text style={styles.tableCell}>{index * 2 + 2}</Text>
            </View>
            <View style={styles.tableColOther}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColOther2}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableColOther2}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>
        ))}
      </View>
      <Text style={styles.bottomText2}>COPIA: INTT</Text>
      <Text style={styles.bottomText}>
        El valor del listín es el 3% de la tarifa pagada por pasajero, de
        acuerdo a la publicación del Ministerio del Poder Popular para el
        Transporte, en la Gaceta Oficial de la República Bolivariana de
        Venezuela N° 42.462 de fecha 14/09/2022
      </Text>
    </Page>
  </Document>
);

const MySwal = withReactContent(Swal);

export const Elab_listin_rl = () => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    numero_listin: "",
    fecha_de_viaje: "",
    tipo_servicio: "",
    photo_cara_link: "",
    photo_cara_link_2: "",
    unidad: "",
    url_pdf: "",
    placa: "",
    numero_puestos: "",
    destino: "",
    anden: "",
    destino_final: "",
    hora_salida: "",
    ruta: "",
    empresa: "",
    rif: "",
    conductor_1: "",
    cedula_1: "",
    nombre_1: "",
    conductor_2: "",
    cedula_2: "",
    nombre_2: "",
    vista: "",
    activationId: "",
    conductor_1_id: "",
    conductor_2_id: "",
    empresaId: "",
    km_destino_final: 0.0,
    url_pdf: "",
  });
  const [activaciones, setActivaciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [records, setRecords] = useState([]);
  const [listines, setListines] = useState([]);
  const [empresaId, setEmpresaId] = useState(0);
  const [loadingDriver, setLoadingDriver] = useState({
    conductor_1: false,
    conductor_2: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchTodayRecords = async () => {
    try {
      // Configurar el token de autorización en los headers
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/get_activations_new_recau",
        {
          headers: {
            Authorization: `Bearer ${store.token}`, // Corrige el uso del token
          },
        }
      );
      setRecords(response.data.activations); // Guarda los datos obtenidos
    } catch (error) {
      console.error("Error al obtener registros de hoy:", error);
    } finally {
      setLoading(false); // Cambia a `false` al terminar la carga
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchTodayRecords();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Verificar si es uno de los campos de cédula y tiene al menos 6 caracteres
    if (
      (name === "conductor_1" || name === "conductor_2") &&
      value.length >= 6
    ) {
      fetchConductorData(name, value);
    }
  };

  const fetchConductorData = async (fieldName, cedula) => {
    setLoadingDriver((prevLoading) => ({ ...prevLoading, [fieldName]: true }));
    try {
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/conductor/${cedula}/${empresaId}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`, // Asegúrate de tener el token en el store
          },
        }
      );

      const conductorData = response.data;

      // Actualizar el campo correspondiente con el nombre completo
      if (fieldName === "conductor_1") {
        setFormData((prevData) => ({
          ...prevData,
          nombre_1: `${conductorData.first_name} ${conductorData.last_name}`,
          conductor_1_id: `${conductorData.id}`,
          photo_cara_link: `${conductorData.photo_cara_link}`,
        }));
      } else if (fieldName === "conductor_2") {
        setFormData((prevData) => ({
          ...prevData,
          nombre_2: `${conductorData.first_name} ${conductorData.last_name}`,
          conductor_2_id: `${conductorData.id}`,
          photo_cara_link_2: `${conductorData.photo_cara_link}`,
        }));
      }
    } catch (error) {
      console.error(`Error al obtener datos del conductor (${cedula}):`, error);
      if (fieldName === "conductor_1") {
        setFormData((prevData) => ({
          ...prevData,
          cedula_1: "",
          nombre_1: "",
          conductor_1_id: "",
          photo_cara_link: "",
        }));
      } else if (fieldName === "conductor_2") {
        setFormData((prevData) => ({
          ...prevData,
          cedula_2: "",
          nombre_2: "",
          conductor_2_id: "",
          photo_cara_link_2: "",
        }));
      }
    } finally {
      setLoadingDriver((prevLoading) => ({
        ...prevLoading,
        [fieldName]: false,
      })); // Desactivar loading
    }
  };

  const openActivationSelection = () => {
    setEmpresaId(0);
    setFormData({
      numero_listin: "",
      fecha_de_viaje: "",
      tipo_servicio: "",
      unidad: "",
      url_pdf: "",
      placa: "",
      numero_puestos: "",
      destino: "",
      anden: "",
      destino_final: "",
      hora_salida: "",
      ruta: "",
      empresa: "",
      rif: "",
      conductor_1: "",
      cedula_1: "",
      nombre_1: "",
      conductor_2: "",
      cedula_2: "",
      nombre_2: "",
      vista: "",
      activationId: "",
      conductor_1_id: "",
      conductor_2_id: "",
      empresaId: "",
      km_destino_final: 0.0,
      url_pdf: "",
    });
    MySwal.fire({
      title: (
        <p>
          <i
            className="fa-solid fa-bus"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Activaciones de hoy
        </p>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar activación"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${records
            .map(
              (activation) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${activation.id}"
              data-name="${activation.empresa.nombre}"
              data-unidad="${activation.numero_de_unidad}"
              data-rif="${activation.empresa.rif}"
              data-placa="${activation.dt_terminal_empresa.placa}"
              data-salida="${activation.hora_de_salida}"
              data-destino="${activation.ruta.destino_final.destino_final_detalle.descripcion}"
              data-ruta="${activation.ruta.ruta}"
              data-empresaid="${activation.empresa_terminal_id}"
              data-fecha="${activation.fecha_de_viaje}"
              data-anden="${activation.anden}"
              data-kmdistancia="${activation.ruta.destino_final.km_distancia}"
              >
              ${activation.empresa.nombre} <br> Unidad: ${activation.numero_de_unidad}
            </button>`
            )
            .join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredEmpresas = records.filter(
            (activation) =>
              activation.empresa.nombre.toLowerCase().includes(query) ||
              activation.numero_de_unidad.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredEmpresas
            .map(
              (activation) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${activation.id}"
                  data-name="${activation.empresa.nombre}"
                  data-unidad="${activation.numero_de_unidad}"
                  data-rif="${activation.empresa.rif}"
                  data-placa="${activation.dt_terminal_empresa.placa}"
                  data-salida="${activation.hora_de_salida}"
                  data-destino="${activation.ruta.destino_final.destino_final_detalle.descripcion}"
                  data-ruta="${activation.ruta.ruta}"
                  data-empresaid="${activation.empresa_terminal_id}"
                  data-fecha="${activation.fecha_de_viaje}"
                  data-anden="${activation.anden}"
                  data-kmdistancia="${activation.ruta.destino_final.km_distancia}"
                  >
                  ${activation.empresa.nombre} <br> Unidad: ${activation.numero_de_unidad}
                </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const activationId = button.dataset.id;
              const empresa = button.dataset.name;
              const numero_unidad = button.dataset.unidad;
              const rif = button.dataset.rif;
              const placa = button.dataset.placa;
              const hora_de_salida = button.dataset.salida;
              const destino_final = button.dataset.destino;
              const ruta = button.dataset.ruta;
              const empresaId = button.dataset.empresaid;
              const fecha_de_viaje = button.dataset.fecha;
              const anden = button.dataset.anden;
              const km_distancia = button.dataset.kmdistancia;
              selectCompany(
                activationId,
                empresa,
                numero_unidad,
                rif,
                placa,
                hora_de_salida,
                destino_final,
                ruta,
                empresaId,
                fecha_de_viaje,
                anden,
                km_distancia
              ); // Pasamos el ID y el nombre
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const activationId = button.dataset.id;
            const empresa = button.dataset.name;
            const numero_unidad = button.dataset.unidad;
            const rif = button.dataset.rif;
            const placa = button.dataset.placa;
            const hora_de_salida = button.dataset.salida;
            const destino_final = button.dataset.destino;
            const ruta = button.dataset.ruta;
            const empresaId = button.dataset.empresaid;
            const fecha_de_viaje = button.dataset.fecha;
            const anden = button.dataset.anden;
            const km_distancia = button.dataset.kmdistancia;
            selectCompany(
              activationId,
              empresa,
              numero_unidad,
              rif,
              placa,
              hora_de_salida,
              destino_final,
              ruta,
              empresaId,
              fecha_de_viaje,
              anden,
              km_distancia
            ); // Pasamos el ID y el nombre
          });
        });
      },
    });
  };

  const selectCompany = (
    activationId,
    empresa,
    numero_de_unidad,
    rif,
    placa,
    hora_de_salida,
    destino_final,
    ruta,
    empresaId,
    fecha_de_viaje,
    anden,
    km_distancia
  ) => {
    setFormData({
      ...formData,
      activationId: activationId,
      vista: empresa + " - " + numero_de_unidad,
      empresa: empresa,
      rif: rif,
      placa: placa,
      hora_salida: hora_de_salida,
      unidad: numero_de_unidad,
      destino: destino_final,
      ruta: ruta,
      fecha_de_viaje: fecha_de_viaje,
      anden: anden,
      empresaId: empresaId,
      km_destino_final: km_distancia,
    });
    setEmpresaId(empresaId);
    MySwal.close(); // Cerrar la ventana de SweetAlert
  };

  const generatePDF = async (numeroListin) => {
    try {
      // Actualizamos el formData con el número del listín recibido
      const updatedFormData = { ...formData, numero_listin: numeroListin };

      // Generar el PDF con el número de listín incluido
      const pdfBlob = await pdf(
        <ListinPDF formData={updatedFormData} />
      ).toBlob();

      // Convertir el PDF Blob a File para subirlo
      return new File([pdfBlob], "listin" + { numeroListin } + ".pdf", {
        type: "application/pdf",
      });
    } catch (error) {
      console.error("Error al generar el PDF:", error);
      throw error;
    }
  };

  const handlePDFUpload = async (file, folderPath) => {
    try {
      // Solicitar firma y número de listín al backend
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/generar_firma_cloudinary_listin_rl",
        { folder: folderPath },
        {
          headers: { Authorization: "Bearer " + store.token },
        }
      );

      const {
        signature,
        timestamp,
        api_key,
        cloud_name,
        folder,
        numero_listin,
      } = response.data;

      // Generar el PDF con el número de listín
      const pdfFile = await generatePDF(numero_listin);

      // Crear FormData para subir a Cloudinary
      const formData = new FormData();
      formData.append("file", pdfFile);
      formData.append("timestamp", timestamp);
      formData.append("signature", signature);
      formData.append("api_key", api_key);
      formData.append("folder", folder);

      // Subir el PDF a Cloudinary
      const uploadResponse = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/auto/upload`,
        formData
      );

      // Retornar la URL del PDF subido y el número de listín
      return { pdfUrl: uploadResponse.data.secure_url, numero_listin };
    } catch (error) {
      console.error("Error al subir el PDF:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const folderPath = "TERMINALES/TLB/LISTINES_RL";

      // Solicitar firma, generar y subir PDF a Cloudinary
      const { pdfUrl, numero_listin } = await handlePDFUpload(null, folderPath);

      // Normalizar los datos: convertir "" a null para campos opcionales
      const normalizeData = (data) =>
        Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key,
            value === "" ? null : value,
          ])
        );

      // Actualizar y normalizar los datos del formulario
      const updatedFormData = normalizeData({
        ...formData,
        url_pdf: pdfUrl,
        numero_listin: numero_listin,
      });

      // Enviar los datos del formulario al backend
      await axios.post(
        process.env.BACKEND_URL + "/api/ElabPostListinRl",
        updatedFormData,
        {
          headers: { Authorization: "Bearer " + store.token },
        }
      );

      // Limpiar el formulario
      setFormData({
        numero_listin: "",
        fecha_de_viaje: "",
        tipo_servicio: "",
        unidad: "",
        url_pdf: "",
        photo_cara_link_2: "",
        placa: "",
        numero_puestos: "",
        photo_cara_link: "",
        destino: "",
        anden: "",
        destino_final: "",
        hora_salida: "",
        ruta: "",
        empresa: "",
        rif: "",
        conductor_1: "",
        cedula_1: "",
        nombre_1: "",
        conductor_2: "",
        cedula_2: "",
        nombre_2: "",
        vista: "",
        activationId: "",
        conductor_1_id: "",
        conductor_2_id: "",
        empresaId: "",
        km_destino_final: 0,
      });

      mostrarAlerta1();
      fetchTodayRecords();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar el listín";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Listin Registrado con Éxito!</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar el Listín</p>,
      html: <p>{errorMessage}</p>,
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const showListinesDetails = async () => {
    try {
      // Mostrar ventana de carga
      MySwal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando Listines de hoy...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/get_listines_today`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Extraer datos de la empresa
      const listines = response.data.listines;
      setListines(listines); // Guardar rutas en el estado

      MySwal.close();

      // Verificar si la empresa tiene rutas
      if (listines.length > 0) {
        MySwal.fire({
          title: `Listines de hoy`,
          html: `<div class="custom-table-container m-0 d-flex" style="flex-direction: column">
            <table class="custom-table rounded-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4" style="width: 750px;">
              <thead>
                <tr>
                  <th class="table-header" style="font-size: 14px;">N° Listín</th>
                  <th class="table-header" style="font-size: 14px;">Empresa</th>
                  <th class="table-header" style="font-size: 14px;">Unidad</th>
                  <th class="table-header" style="font-size: 14px;">Placa</th>
                  <th class="table-header" style="font-size: 14px;">Acciones</th>
                </tr>
              </thead>
              <tbody>
                ${listines
                  .map(
                    (listines, index) => `
                  <tr key="${index}" class="table-row">
                    <td class="table-cell" style="font-size: 12px;">
                      ${listines.n_listin_terminal}
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${listines.activation.empresa.nombre}
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${listines.activation.numero_de_unidad}
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${listines.activation.dt_terminal_empresa.placa}
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                    <div class="d-flex">
                      <button
                          class="btn d-flex btn-${listines.estatus == "ACTIVE" ? "success" : "danger"} listin-change-status"
                          data-id="${listines.id}" data-status="${listines.estatus}"
                          style="margin-right: 10px"
                        >
                        <i
                          class="fa-solid fa-lock${listines.estatus == "ACTIVE" ? "-open" : ""}"
                          style="font-size: 17px"
                        ></i>
                      </button>
                      <button
                          class="btn d-flex btn-info"
                          onclick="window.open('${listines.url_pdf}', '_blank')"
                        >
                        <i
                          class="fa-solid fa-file-pdf"
                          style="font-size: 17px; color: white"
                        ></i>
                      </button>
                    </div>
                    </td>
                  </tr>`
                  )
                  .join("")}
              </tbody>
            </table>
          </div>`,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const buttonRoute = document.getElementById(
              "showRouteDetailsPdfButton"
            );
            if (buttonRoute) {
              buttonRoute.addEventListener("click", () => {
                showRouteDetailsPdf(id);
              });
            }
            const buttonRouteMobile = document.getElementById(
              "showRouteDetailsPdfButtonMobile"
            );
            if (buttonRouteMobile) {
              buttonRouteMobile.addEventListener("click", () => {
                showRouteDetailsPdfDownload(id);
              });
            }
            const buttonDT = document.getElementById("showDTDetailsPdfButton");
            if (buttonDT) {
              buttonDT.addEventListener("click", () => {
                showDTDetailsPdf(id);
              });
            }
            const buttonDTMobile = document.getElementById(
              "showDTDetailsPdfButtonMobile"
            );
            if (buttonDTMobile) {
              buttonDT.addEventListener("click", () => {
                showDTDetailsPdfDownload(id);
              });
            }
            const buttonDriver = document.getElementById(
              "showDriverDetailsPdfButton"
            );
            if (buttonDriver) {
              buttonDriver.addEventListener("click", () => {
                showDriverDetailsPdf(id);
              });
            }
            const buttonDriverMobile = document.getElementById(
              "showDriverDetailsPdfButtonMobile"
            );
            if (buttonDriverMobile) {
              buttonDriverMobile.addEventListener("click", () => {
                showDriverDetailsPdfDownload(id);
              });
            }
            document
              .querySelectorAll(".listin-change-status")
              .forEach((button) => {
                button.addEventListener("click", async () => {
                  const dt_id = button.dataset.id; // Obtener el ID de la ruta
                  const currentStatus = button.dataset.status; // Obtener el estado actual
                  const newStatus =
                    currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE"; // Alternar el estado

                  try {
                    MySwal.fire({
                      title: "Actualizando estado",
                      text: `El Listín seleccionado está siendo marcado como ${newStatus === "ACTIVE" ? "ACTIVO" : "ANULADO"}.`,
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });
                    // Llamada a la API para actualizar el estado
                    const response = await axios.put(
                      `${process.env.BACKEND_URL}/api/toggle_listin_status/${dt_id}`,
                      { status: newStatus },
                      {
                        headers: {
                          Authorization: `Bearer ${store.token}`,
                        },
                      }
                    );

                    if (response.status === 200) {
                      MySwal.fire({
                        title: "Estado actualizado",
                        text: `El Listón ha sido marcado como ${newStatus === "ACTIVE" ? "ACTIVO" : "ANULADO"}.`,
                        icon: "success",
                      });
                      showListinesDetails();
                    } else {
                      throw new Error("No se pudo actualizar el estado.");
                    }
                  } catch (error) {
                    console.error("Error al actualizar el estado:", error);
                    MySwal.fire({
                      title: "Error",
                      text: "No se pudo actualizar el estado del Listín. Inténtelo nuevamente.",
                      icon: "error",
                    });
                  }
                });
              });
          },
        });
      } else {
        MySwal.fire({
          title: `Aún no se ha registrado ningún listín para hoy`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-file-invoice"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Elaboración de Listines Andén A</p>
        </div>
        <div
          className="container asklnduiashndaijsnxansxsaas3"
          style={{ marginBottom: "20px" }}
        >
          <form
            onSubmit={handleSubmit}
            className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="activacion"
                  value={formData.vista}
                  onClick={openActivationSelection}
                  readOnly
                  required
                />
                <label>Programación de hoy</label>
              </div>
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="tipo_servicio"
                  onChange={handleChange}
                  value={formData.tipo_servicio}
                  required
                >
                  <option value="" disabled>
                    Selecciona un Servicio
                  </option>
                  <option value="BUSCAMA">BUSCAMA</option>
                  <option value="EJECUTIVO">EJECUTIVO</option>
                  <option value="YUTONG">YUTONG</option>
                </select>
                <label>Tipo de Servicio</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="unidad"
                  value={formData.unidad}
                  onChange={handleChange}
                  required
                  readOnly
                />
                <label>Unidad</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="placa"
                  value={formData.placa}
                  onChange={handleChange}
                  required
                  readOnly
                  style={{ textTransform: "uppercase" }}
                />
                <label>Placa</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="destino"
                  value={formData.destino}
                  onChange={handleChange}
                  required
                  readOnly
                />
                <label>Destino Final</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <textarea
                  className="inputDeLogin"
                  type="text"
                  name="ruta"
                  value={formData.ruta}
                  onChange={handleChange}
                  required
                  readOnly
                  style={{ minHeight: "62px" }}
                />
                <label>Ruta</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="time"
                  name="hora_salida"
                  value={formData.hora_salida}
                  onChange={handleChange}
                  required
                  readOnly
                />
                <label>Hora de Salida</label>
              </div>

              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="empresa"
                  value={formData.empresa}
                  onChange={handleChange}
                  required
                  readOnly
                />
                <label>Empresa</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="rif"
                  value={formData.rif}
                  onChange={handleChange}
                  required
                  readOnly
                />
                <label>RIF</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="conductor_1"
                  value={formData.conductor_1}
                  onChange={handleChange}
                  required
                  maxLength={20}
                />
                <label>Cédula 1</label>
                {loadingDriver.conductor_1 && (
                  <div className="loading-spinner"></div>
                )}
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="nombre_1"
                  value={formData.nombre_1}
                  onChange={handleChange}
                  required
                  readOnly
                />
                <label>Nombre completo 1</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="conductor_2"
                  value={formData.conductor_2}
                  onChange={handleChange}
                  maxLength={20}
                />
                <label>Cédula 2</label>
                {loadingDriver.conductor_2 && (
                  <div className="loading-spinner"></div>
                )}
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="nombre_2"
                  value={formData.nombre_2}
                  onChange={handleChange}
                  readOnly
                />
                <label>Nombre Completo 2</label>
              </div>
            </div>

            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Registrando..." : "Registrar"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
          <button
            className="asd98q7wd65q1wd8xqw49x8e4wc"
            onClick={showListinesDetails}
          >
            <i
              className="fa-solid fa-list"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Ver Listines
          </button>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
