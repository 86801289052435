import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../store/appContext";
import { Link, Navigate } from "react-router-dom";
import "../../../styles/CompanyAdminNavbar.css";

export const Navbar = () => {
  const { store, actions } = useContext(Context);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.reload();
  };
  const handleClick = (menu) => {
    setSelectedMenu(menu);
  };

  useEffect(() => {
    // Obtén el pathname de la ubicación actual
    const pathname = location.pathname;

    // Mapea el pathname a un nombre de menú y establece el menú seleccionado
    const menuMapping = {
      "/": "/",
      "": "",
      "/RegistroCps": "/RegistroCps",
      "/EmpresasDeTransporte": "/EmpresasDeTransporte",
      "/Configuraciones": "/Configuraciones",
      "/RegistroUsuarios": "/RegistroUsuarios",
      "/Destinos": "/Destinos",
      "/Programacion": "/Programacion",
      "/ActivacionCalendario": "/ActivacionCalendario",
      "/PrecioDestinoFinalRC": "/PrecioDestinoFinalRC",
      "/PrecioDestinoFinalRL": "/PrecioDestinoFinalRL",
      "/KmDistancia": "/KmDistancia",
      "/RegistrosCpsEdit": "/RegistrosCpsEdit",
      "/Autorizacionporpuesto": "/Autorizacionporpuesto",
      "/CierresRutasCortas": "/CierresRutasCortas",
      "/RegistroActivacionesHoy": "/RegistroActivacionesHoy",
      "/AyudaYSoporte": "/AyudaYSoporte",
      "/Terminales": "/Terminales",
      "/LineasTransporte": "/LineasTransporte",
      "/RegistroCpsGeneral": "/RegistroCpsGeneral",
      "/TipoTerminal": "/TipoTerminal",
      "/RegistroTasa": "/RegistroTasa",
    };

    const menu = menuMapping[pathname];
    if (menu) {
      setSelectedMenu(menu);
    }
  }, [location.pathname]);

  return (
    <div className="ideaAdminCompany-container2">
      <div className="ideaAdminCompany-container" style={{ position: "fixed" }}>
        <div className="as56d4s9f87er98fwe4fwr987fwee98d4qw987">
          <img
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1713119110/INTRAVIALCA/truxqodtlg1vzishxhot.png"
            alt="Logo"
            style={{ height: "35px" }}
            className="d65f4sd9f8w7ef98we7qw654er987fw"
          />
          <i
            className="fa-solid a6s54d89f7v98wedq89wre98we87icneassa9d87 fa-bars"
            style={{ color: "white" }}
          ></i>
        </div>
        <div className="sdsdf98u7yt9htg7wfqe987879467434">
          {/* A partir de aqui coloca el nuevo menu aqui dentro de este div */}
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/" ? "selected-menu" : ""
            }`}
            to="/"
            onClick={() => handleClick("/")}
          >
            <i className="fa-solid seraUnMarinsupre fa-globe"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Inicio
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/RegistroTasa" ? "selected-menu" : ""
            }`}
            to="/RegistroTasa"
            onClick={() => handleClick("/RegistroTasa")}
          >
            <i className="fa-solid seraUnMarinsupre fa-dollar-sign"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Registro Tasa
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/Terminales" ? "selected-menu" : ""
            }`}
            to="/Terminales"
            onClick={() => handleClick("/Terminales")}
          >
            <i className="fa-solid seraUnMarinsupre fa-school"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Terminales
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/LineasTransporte" ? "selected-menu" : ""
            }`}
            to="/LineasTransporte"
            onClick={() => handleClick("/LineasTransporte")}
          >
            <i className="fa-solid seraUnMarinsupre fa-user-tie"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Líneas de Transporte
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/RegistroCpsGeneral" ? "selected-menu" : ""
            }`}
            to="/RegistroCpsGeneral"
            onClick={() => handleClick("/RegistroCpsGeneral")}
          >
            <i className="fa-solid seraUnMarinsupre fa-rectangle-list"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              CPS Empresas
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/TipoTerminal" ? "selected-menu" : ""
            }`}
            to="/TipoTerminal"
            onClick={() => handleClick("/TipoTerminal")}
          >
            <i className="fa-solid seraUnMarinsupre fa-font-awesome"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Tipo Terminal
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/DestinoFinal" ? "selected-menu" : ""
            }`}
            to="/DestinoFinal"
            onClick={() => handleClick("/DestinoFinal")}
          >
            <i className="fa-solid seraUnMarinsupre fa-route"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Destino Final
            </div>
          </Link>

          <div className="s547"></div>
        </div>
        <div className="d56f4d89g7wfq98d7et98re4f6t5er1eq56w64">
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "Configuraciones" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("Configuraciones")}
            to="/Configuraciones"
          >
            <i className="fa-solid seraUnMarinsupre fa-gear"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Configuraciones
            </div>
          </Link>
        </div>
        <div className="d56f4d89g7wfq98d7et98re4f6t5er1eq56w64">
          <a
            className="subtitulosMenuUserHomeCompany logoutbuttonCompany"
            onClick={handleLogout}
            href="/"
          >
            <i className="fa-solid seraUnMarinsupre fa-right-from-bracket"></i>
            <div
              href="#"
              className="LetterInvisible2"
              style={{ fontSize: "12px" }}
            >
              Cerrar sesión
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
