import React, { useState, useContext } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../store/appContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#FFF",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#24827c",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeader2: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#8c1010",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
    color: "white",
  },
  tableCell: {
    margin: 4,
    fontSize: 7,
    textAlign: "start",
  },
});

tableStyles.tableColHeaderOthertaxi = {
  ...tableStyles.tableColHeader,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi = {
  ...tableStyles.tableCol,
  width: "50%", // Mayor ancho para los demás
};

// Modificar anchos específicos aquí
tableStyles.tableColHeaderNumber = {
  ...tableStyles.tableColHeader,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "10%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther4 = {
  ...tableStyles.tableColHeader,
  width: "27.5%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOtherDT = {
  ...tableStyles.tableColHeader,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther2 = {
  ...tableStyles.tableColHeader2,
  width: "13.6%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther3 = {
  ...tableStyles.tableColHeader2,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColNumber = {
  ...tableStyles.tableCol,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "10%", // Mayor ancho para los demás
};
tableStyles.tableColOther2 = {
  ...tableStyles.tableCol,
  width: "27.5%", // Mayor ancho para los demás
};

tableStyles.tableColOtherDT = {
  ...tableStyles.tableCol,
  width: "15%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOthertaxi2 = {
  ...tableStyles.tableColHeader,
  width: "33.3%", // Mayor ancho para los demás
  backgroundColor: "#8c1010",
};
tableStyles.tableColHeaderOthertaxi3 = {
  ...tableStyles.tableColHeader,
  width: "15%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi2 = {
  ...tableStyles.tableCol,
  width: "33.3%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  page: {
    padding: 20, // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 10,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    paddingTop: 5,
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
});

const DriverDetailsPDF = ({ users }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header} fixed>
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
          style={styles.logo2}
        />
      </View>
      {/* <View style={styles.header}>
        <Text style={styles.letter2}>
          Rutas de {empresaDetalle.empresa.empresa.nombre}
        </Text>
      </View> */}
      <View>
        {/* Primera columna */}
        <View>
          <Text style={{ textAlign: "center" }}>USUARIOS</Text>
          <Text
            style={{ textAlign: "center", fontSize: "12px", color: "gray" }}
          >
            DIRECTOR TERMINAL
          </Text>
        </View>
      </View>
      {/* Rutas CPS */}
      <View style={{ marginTop: 10 }}></View>
      <View style={styles.section}>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow}>
            <View style={tableStyles.tableColHeaderOther4}>
              <Text style={tableStyles.tableCellHeader}>Nombre y Apellido</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Cédula</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther4}>
              <Text style={tableStyles.tableCellHeader}>Email</Text>
            </View>
            <View style={tableStyles.tableColHeaderOthertaxi3}>
              <Text style={tableStyles.tableCellHeader}>Teléfono</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Terminal</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Estatus</Text>
            </View>
          </View>
          {users.map((users, index) => (
            <View style={tableStyles.tableRow} key={index}>
              <View style={tableStyles.tableColOther2}>
                <Text style={tableStyles.tableCell}>
                  {users.first_name} {users.last_name}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>{users.user_cedula}</Text>
              </View>
              <View style={tableStyles.tableColOther2}>
                <Text style={tableStyles.tableCell}>{users.email}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>{users.user_phone}</Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>{users.terminal.code}</Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {users.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.footer} fixed>
        <Text>
          Reporte generado por el sistema de INTRAVIALCA
          login.intravialca-ve.com
        </Text>
      </View>
    </Page>
  </Document>
);

export const RegistroUsuarios = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    account_type: "",
    terminal_ubication: "",
    company: "",
    company_rif: "",
    email: "",
    rol: "",
    user_cedula: "",
    user_phone: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado para controlar el estado de envío
  const [isLoading, setIsLoading] = useState(false); // Nuevo estado para controlar el estado de envío

  const showDriverDetailsPdfDownload = async () => {
    try {
      setIsLoading(true);

      // Mostrar ventana de carga
      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando Conductores de la Empresa Seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/GetUsers`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const users = response.data.users;

      // Verificar si hay rutas disponibles
      if (users.length > 0) {
        Swal.close(); // Cerrar la ventana de carga

        // Generar el PDF
        const pdfBlob = await pdf(<DriverDetailsPDF users={users} />).toBlob();

        // Crear un enlace de descarga programático
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download = "usuarios-details.pdf";
        document.body.appendChild(link);
        link.click(); // Simular clic para descargar el archivo
        document.body.removeChild(link);
      } else {
        Swal.fire({
          title: `No hay Usuarios creados`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const showDriverDetailsPdf = async (id) => {
    try {
      setIsLoading(true);

      // Mostrar ventana de carga
      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando Rutas de la Empresa Seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/GetUsers`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const users = response.data.users;

      // Verificar si hay rutas disponibles
      if (users.length > 0) {
        Swal.close(); // Cerrar la ventana de carga

        // Generar el PDF
        const pdfBlob = await pdf(<DriverDetailsPDF users={users} />).toBlob();

        // Crear una URL temporal para el Blob y abrirla en una nueva ventana
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      } else {
        Swal.fire({
          title: `No hay usuarios creados.`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Desactiva el botón

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/signupIntravialca",
        formData,
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setFormData({
        first_name: "",
        last_name: "",
        account_type: "",
        terminal_ubication: "",
        company: "",
        company_rif: "",
        email: "",
        rol: "",
        user_cedula: "",
        user_phone: "",
      });
      mostrarAlerta1();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar al usuario";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar", error);
    } finally {
      setIsSubmitting(false); // Reactiva el botón después del envío
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Usuario Registrado con éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar al usuario</p>,
      html: <p>{errorMessage}</p>,
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  // Diccionario para roles y estados
  const roleMap = {
    ADMINISTRADOR_TER: "DIRECTOR TERMINAL",
    TER_ANALISTA_TER: "ANALISTA",
    TER_PISTA_TER: "OPERADOR",
  };
  const statusMap = {
    ACTIVE: "ACTIVO",
    NO_ACTIVE: "BLOQUEADO",
  };

  // Función para asignar eventos a los botones de cambio de estado
  const addStatusChangeEventListeners = () => {
    document.querySelectorAll(".change-status").forEach((button) => {
      button.addEventListener("click", async (event) => {
        const userId = event.currentTarget.getAttribute("data-id");

        const confirmation = await MySwal.fire({
          title: "¿Estás seguro?",
          text: "¡Si haces esto el usuario no podrá usar más su cuenta!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Sí, bloquear",
          cancelButtonText: "Cancelar",
        });

        if (confirmation.isConfirmed) {
          MySwal.fire({
            title: "Bloqueando...",
            html: `<p style="text-align:center; margin:20px;">Bloqueando Actividades de Usuario...</p>`,
            allowOutsideClick: false,
            didOpen: () => {
              MySwal.showLoading();
            },
          });

          try {
            await axios.put(
              `${process.env.BACKEND_URL}/api/user/${userId}/updateStatus`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${store.token}`,
                },
              }
            );

            MySwal.fire({
              title: "Usuario Bloqueado",
              text: "El Usuario ha sido bloqueado exitosamente.",
              icon: "success",
              confirmButtonText: "Cerrar",
            });

            // Refrescar los usuarios después del cambio de estado
            fetchUsers();
          } catch (error) {
            MySwal.fire({
              title: "Error",
              text: "Hubo un error al intentar bloquear el usuario",
              icon: "error",
              confirmButtonText: "Cerrar",
            });
            console.error("Error al bloquear al usuario:", error);
          }
        }
      });
    });
  };

  // Función para filtrar usuarios y actualizar la tabla
  const filterAndDisplayUsers = (data, searchTerm) => {
    const listinesList = document.getElementById("listines-list");
    const filteredRows = data
      .filter(
        (item) =>
          item.first_name.toLowerCase().includes(searchTerm) ||
          item.last_name.toLowerCase().includes(searchTerm) ||
          item.rol.toLowerCase().includes(searchTerm)
      )
      .map(
        (item) => `
          <tr>
            <td>${item.first_name + " " + item.last_name}</td>
            <td>${roleMap[item.rol] || item.rol}</td>
            <td>${item.email}</td>
            <td>
              <button id="change-status-user" class="change-status btn btn-${item.status == "ACTIVE" ? "success" : "danger"}" data-id="${item.id}" style="color: white; border: none; padding: 5px 10px; cursor: pointer; font-size: 14px">
                <i class="fa-solid fa-arrow-right-arrow-left"></i>
                ${statusMap[item.status] || item.status}
              </button>
              <button data-id="${item.id}" class="reenviar-correo btn btn-secondary" style="color: white; border: none; padding: 5px 10px; cursor: pointer; font-size: 14px; margin-left: 5px">
                <i class="fa-solid fa-envelope-circle-check"></i>
              </button>
            </td>
          </tr>
      `
      )
      .join("");

    listinesList.innerHTML = filteredRows;

    // Vuelve a asignar los event listeners después de actualizar la lista
    addStatusChangeEventListeners();
  };

  const fetchUsers = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Usuarios...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/GetUsers",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.users;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Usuarios",
          html: `<p style="text-align:center; margin:20px;">No se encontraron Usuarios.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
          <tr>
            <th class="table-header" style="text-align: start; font-size: 12px">Nombre y Apellido</th>
            <th class="table-header" style="text-align: start; font-size: 12px">Rol</th>
            <th class="table-header" style="text-align: start; font-size: 12px">Correo</th>
            <th class="table-header" style="text-align: start; font-size: 12px">Acciones</th>
          </tr>
        `;
        const roleMap = {
          TER_LISTINES_RL_TER: "LISTINES RL",
          ADMINISTRADOR_RECAU_TER: "DIRECTOR DE RECAUDACIÓN",
        };
        const statusMap = {
          ACTIVE: "ACTIVO",
          NO_ACTIVE: "BLOQUEADO",
        };

        const tableRows = data
          .map(
            (item) => `
              <tr>
                <td>${item.first_name + " " + item.last_name}</td>
                <td>${roleMap[item.rol] || item.rol}</td>
                <td>${item.email}</td>
                <td>
                  <button id="change-status-user" class="change-status btn btn-${item.status == "ACTIVE" ? "success" : "danger"}" data-id="${item.id}" style="color: white; border: none; padding: 5px 10px; cursor: pointer; font-size: 14px">
                    <i class="fa-solid fa-arrow-right-arrow-left"></i>
                    ${statusMap[item.status] || item.status}
                  </button>
                  <button data-id="${item.id}" class="reenviar-correo btn btn-secondary" style="color: white; border: none; padding: 5px 10px; cursor: pointer; font-size: 14px; margin-left: 5px">
                    <i class="fa-solid fa-envelope-circle-check"></i>
                  </button>
                </td>
              </tr>
            `
          )
          .join("");

        const table = `
          <input type="text" id="search-listines" placeholder="Buscar..." class="swal2-input" style="margin-bottom: 10px;">
          <div class="d-flex" style="width: 100%; position: absolute">
              <button
                id="showDriverDetailsPdfButton"
                class="btn btn-info text-light desktop-er65g48e9r7we984fwe594"
                style="font-size: 15px; margin-right: 10px"
              >
                <i class="fa-solid fa-file-pdf m-0" style="color: white; font-size: 12px"></i>
              </button>
              <button
                id="showDriverDetailsPdfButtonMobile"
                class="btn btn-info text-light mobile-er65g48e9r7we984fwe594"
                style="font-size: 15px; margin-right: 10px"
              >
                <i class="fa-solid fa-file-pdf m-0" style="color: white; font-size: 12px"></i>
              </button>
          </div>
          <div style="max-height: 400px; overflow-y: auto;">
            <table class="custom-table rounded-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4" style="width: 740px; border-collapse: collapse; font-size: 12px; min-width: 100%">
              <thead>
                ${tableHeaders}
              </thead>
              <tbody id="listines-list" style="text-align: start">
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;

        MySwal.fire({
          title: "Usuarios",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const buttonDriver = document.getElementById(
              "showDriverDetailsPdfButton"
            );
            if (buttonDriver) {
              buttonDriver.addEventListener("click", () => {
                showDriverDetailsPdf();
              });
            }
            const buttonDriverMobile = document.getElementById(
              "showDriverDetailsPdfButtonMobile"
            );
            if (buttonDriverMobile) {
              buttonDriverMobile.addEventListener("click", () => {
                showDriverDetailsPdfDownload();
              });
            }
            const searchInput = document.getElementById("search-listines");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const listinesList = document.getElementById("listines-list");
              const filteredRows = data
                .filter(
                  (item) =>
                    item.first_name.toLowerCase().includes(searchTerm) ||
                    item.last_name.toLowerCase().includes(searchTerm) ||
                    item.rol.toLowerCase().includes(searchTerm)
                )
                .map(
                  (item) => `
                    <tr>
                      <td>${item.first_name + " " + item.last_name}</td>
                      <td>${roleMap[item.rol] || item.rol}</td>
                      <td>${item.email}</td>
                      <td>
                        <button id="change-status-user" class="change-status btn btn-${item.status == "ACTIVE" ? "success" : "danger"}" data-id="${item.id}" style="color: white; border: none; padding: 5px 10px; cursor: pointer; font-size: 14px">
                          <i class="fa-solid fa-arrow-right-arrow-left"></i>
                          ${statusMap[item.status] || item.status}
                        </button>
                        <button data-id="${item.id}" class="reenviar-correo btn btn-secondary" style="color: white; border: none; padding: 5px 10px; cursor: pointer; font-size: 14px; margin-left: 5px">
                          <i class="fa-solid fa-envelope-circle-check"></i>
                        </button>
                      </td>
                    </tr>
                  `
                )
                .join("");
              listinesList.innerHTML = filteredRows;
            });

            // Delegación de eventos para los botones de cambio de estado
            document
              .getElementById("listines-list")
              .addEventListener("click", async (event) => {
                const button = event.target.closest("button"); // Encuentra el botón que fue clickeado

                if (!button) return; // Si no hay botón, salir

                if (button.classList.contains("change-status")) {
                  const userId = button.getAttribute("data-id");
                  const user = data.find(
                    (item) => item.id === parseInt(userId, 10)
                  );

                  if (user) {
                    handleChangeStatus(user); // Pasando el objeto correcto
                  } else {
                    console.error("Usuario no encontrado para el ID:", userId);
                  }
                }

                if (button.classList.contains("reenviar-correo")) {
                  const userId = button.getAttribute("data-id");

                  // Mostrar confirmación antes de proceder
                  const confirmation = await MySwal.fire({
                    title: "¿Está seguro?",
                    text: "Esto cambiará la clave del usuario y le asignará una nueva. ¿Desea continuar?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Sí, cambiar la clave",
                    cancelButtonText: "No, cancelar",
                  });

                  if (confirmation.isConfirmed) {
                    try {
                      const response = await axios.post(
                        `${process.env.BACKEND_URL}/api/user/${userId}/resetPassword`,
                        {}, // Puedes enviar un cuerpo vacío si no es necesario
                        {
                          headers: {
                            Authorization: `Bearer ${store.token}`,
                          },
                        }
                      );

                      MySwal.fire({
                        icon: "success",
                        title: response.data.message,
                      });
                    } catch (error) {
                      MySwal.fire({
                        icon: "error",
                        title: "Error al reenviar el correo",
                        text: error.response?.data?.message || "Error interno",
                      });
                    }
                  }
                }
              });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener usuarios", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener usuarios. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handleChangeStatus = (record) => {
    MySwal.fire({
      title: (
        <h6>
          <i
            className="fa-solid fa-pen-to-square"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Editar Usuario
        </h6>
      ),
      html: `
        <div class="input-group-login2">
          <select id="swal-rol" class="inputDeLogin" style="width: 100%">
            <option value="TER_LISTINES_RL_TER" ${record.rol === "TER_LISTINES_RL_TER" ? "selected" : ""}>LISTINES RL</option>
            <option value="TER_AVISOS_RECAU_TER" ${record.rol === "TER_AVISOS_RECAU_TER" ? "selected" : ""}>AVISOS DE COBRO</option>
            <option value="TER_COBRANZA_RECAU_TER" ${record.rol === "TER_COBRANZA_RECAU_TER" ? "selected" : ""}>COBRANZA</option>
          </select>
          <label>Rol</label>
        </div>
        <div class="input-group-login2" style="margin-top: 20px">
          <select id="swal-status" class="inputDeLogin" style="width: 100%">
            <option value="ACTIVE" ${record.status === "ACTIVE" ? "selected" : ""}>ACTIVO</option>
            <option value="NO_ACTIVE" ${record.status === "NO_ACTIVE" ? "selected" : ""}>BLOQUEADO</option>
          </select>
          <label>Estatus</label>
        </div>
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      preConfirm: () => {
        const rol = document.getElementById("swal-rol").value;
        const estatus = document.getElementById("swal-status").value;

        if (!estatus) {
          Swal.showValidationMessage("Debes seleccionar un estatus!");
          return false;
        }

        return { rol, estatus }; // Devuelve los valores necesarios
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { rol, estatus } = result.value;

        try {
          const response = await axios.put(
            `${process.env.BACKEND_URL}/api/user/${record.id}/updateStatus`,
            {
              rol, // Envía el rol al backend
              estatus, // Envía el estatus al backend
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          Swal.fire({
            icon: "success",
            title: response.data.message,
          });

          fetchUsers();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error al cambiar el estatus",
            text: error.response?.data?.message || "Error interno",
          });
        }
      }
    });
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-users"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Registra tus Usuarios en intravialca-ve.com</p>
        </div>
        <div
          className="container asklnduiashndaijsnxansxsaas3"
          style={{ marginBottom: "20px" }}
        >
          <form
            onSubmit={handleSubmit}
            className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="first_name"
                  required
                  onChange={handleChange}
                  value={formData.first_name}
                />
                <label>Nombre</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="last_name"
                  required
                  onChange={handleChange}
                  value={formData.last_name}
                />
                <label>Apellido</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="email"
                  name="email"
                  required
                  onChange={handleChange}
                  value={formData.email}
                />
                <label>Correo Electrónico</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="rol"
                  onChange={handleChange}
                  value={formData.rol}
                  required
                >
                  <option value="" disabled>
                    Selecciona un rol
                  </option>
                  <option value="TER_LISTINES_RL_TER">LISTINES RL</option>
                  <option value="TER_AVISOS_RECAU_TER">AVISOS DE COBRO</option>
                  <option value="TER_COBRANZA_RECAU_TER">COBRANZA</option>
                </select>
                <label>Rol Usuario</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="user_cedula"
                  onChange={handleChange}
                  value={formData.user_cedula}
                  required
                />
                <label>Cédula Usuario</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="user_phone"
                  onChange={handleChange}
                  value={formData.user_phone}
                  required
                />
                <label>Teléfono Usuario</label>
              </div>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Registrando..." : "Registrarse"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
          <button className="asd98q7wd65q1wd8xqw49x8e4wc" onClick={fetchUsers}>
            <i
              className="fa-solid fa-list"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Ver Usuarios
          </button>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
