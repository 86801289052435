import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../../styles/RegistroActivacionesHoy.css";
import { Context } from "../../../store/appContext.js";
import { useNavigate, useParams } from "react-router-dom";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

export const PagosNoCerrados = () => {
  const [records, setRecords] = useState([]);
  const navigate = useNavigate();
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { store } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [avisos, setAvisos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendAvisoCobro = async (registroPagoId) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Quieres enviar el aviso de cobro a esta empresa?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, enviar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true); // Muestra un indicador de carga
        try {
          // Llama a la API para enviar el aviso de cobro
          const response = await axios.post(
            process.env.BACKEND_URL + "/api/avisos_cobro/enviar_unico",
            { registroPagoId }, // Datos enviados en el cuerpo de la solicitud
            {
              headers: {
                Authorization: "Bearer " + store.token, // Incluye el token de autenticación
              },
            }
          );

          if (response.status === 200) {
            // Notificar éxito
            Swal.fire({
              icon: "success",
              title: "Aviso de cobro enviado",
              text: "El aviso de cobro se ha enviado exitosamente.",
            });
          } else {
            throw new Error("Error inesperado en la respuesta.");
          }
        } catch (error) {
          console.error("Error al enviar aviso de cobro:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Hubo un problema al enviar el aviso de cobro. Inténtelo nuevamente.",
          });
        } finally {
          setIsLoading(false); // Oculta el indicador de carga
        }
      }
    });
  };

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/pagos_no_cerrados_recau",
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setRecords(response.data.pagos_no_cerrados);
      setFilteredRecords(response.data.pagos_no_cerrados);
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    } finally {
      setLoading(false); // Cambia a `false` al terminar la carga
    }
  };

  const formatDate = (timestamp) => {
    // Dividir fecha y zona horaria
    const [datePart, timePart] = timestamp.split("T");
    const [year, month, day] = datePart.split("-");
    const [time, offset] = timePart.split("-");

    // Extraer la hora, minutos y segundos
    const [hours, minutes] = time.split(":");

    // Formatear en dd/mm/yyyy hh:mm
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterAndSortRecords(e.target.value, statusFilter, sortOrder);
  };

  const filterAndSortRecords = (search, status, order) => {
    let filtered = records.filter((record) => {
      return (
        (!status || record.empresa.status === status) &&
        (!search ||
          record.empresa.nombre.toLowerCase().includes(search.toLowerCase()) ||
          record.empresa.rif.toLowerCase().includes(search.toLowerCase()) ||
          record.code.toLowerCase().includes(search.toLowerCase()))
      );
    });

    setFilteredRecords(filtered);
  };

  return (
    <div className="container-fluid contarinerGeneralUserHomejs h-auto">
      <div className="container asklnduiashndaijsnxansxsaas6548 m-0 p-0">
        {/* Filtro y Ordenación */}
        <div className="filter-bar p-3 sd56f4df98g4f8g9hb7er89g4ewr89e7r9we4f98ef9we4v4ds2">
          <div
            style={{ display: "flex", alignItems: "center", color: "white" }}
            className="sddv4er98f4we9cw4edivderegis"
          >
            <i
              className="fa-solid fa-magnifying-glass"
              style={{ fontSize: "20px" }}
            ></i>
            <p style={{ margin: "0", marginLeft: "7px", fontSize: "20px" }}>
              Buscar
            </p>
          </div>
          <input
            type="text"
            placeholder="Buscar por rif o nombre de Empresa"
            value={searchTerm}
            onChange={handleSearchChange}
            className="form-control mb-2"
            style={{ borderRadius: "20px", padding: "10px 15px" }}
          />
        </div>
        {loading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <img
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1731494258/1/dvhl0hwbx4adb8uceymr.gif"
              alt="Cargando..."
              style={{ width: "150px" }}
            />
          </div>
        ) : (
          <div className="contianer-fluid p-0 m-0 w-100">
            <div className="container asklnduiashndaijsnxansxsaas mobile-er65g48e9r7we984fwe594 p-0">
              {filteredRecords.map((record) => {
                return (
                  <div
                    key={record.id}
                    className="container asklnduiashndaijsnxansxsaas"
                  >
                    <div
                      className="container asune-rv56er48v9wec-s2 mgjmnh98-sdf9w8e7f9wewefwes"
                      style={{ height: "auto" }}
                    >
                      <div
                        className="container aoshdnuqiwhnd897"
                        style={{ flexDirection: "column" }}
                      >
                        <div
                          className="container ds897fq4w9dc9w8c4-we89c7w2 w-100"
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                                fontSize: "10px",
                              }}
                              id="pderegistros"
                            >
                              <strong>{formatDate(record.timestamp)}</strong>
                            </p>
                            <p
                              style={{
                                margin: "0",
                                fontSize: "12px",
                              }}
                              id="pderegistros"
                            >
                              {record.registered_by}
                            </p>
                          </div>
                          <h5 style={{ marginTop: "15px" }}>
                            {record.empresa.nombre}
                          </h5>
                        </div>
                        <div
                          className="asdw-w9d84c9w8e4dcd"
                          style={{ marginBottom: "5px" }}
                        ></div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div style={{ minWidth: "200px" }}>
                            <strong>{record.code}</strong> -{" "}
                            {record.total_pagado_bs} Bs.
                          </div>
                          <div
                            className="container ds9f848dsfwef498we4fwd-57982 w-20"
                            style={{
                              width: "100%",
                              height: "100% !important",
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            <a
                              className="btn btn-info d-flex"
                              href={record.pdf_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "white",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa-solid fa-file-pdf"
                                style={{ fontSize: "17px" }}
                              ></i>
                            </a>
                            <a
                              className="btn btn-warning d-flex"
                              onClick={() => handleSendAvisoCobro(record.id)}
                              style={{
                                color: "white",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                                cursor: "pointer", // Asegúrate de que parezca clicable
                              }}
                            >
                              <i
                                className="fa-solid fa-envelope"
                                style={{ fontSize: "17px" }}
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Vista Escritorio::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::.. */}
            <div className="custom-table-container desktop-er65g48e9r7we984fwe594 m-0">
              <table className="custom-table rounded-table">
                <thead>
                  <tr>
                    <th className="table-header">Fecha y Hora</th>
                    <th className="table-header">Empresa</th>
                    <th className="table-header">Code</th>
                    <th className="table-header">Registrado</th>
                    <th className="table-header">Total Bs</th>
                    <th className="table-header">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecords.map((record) => (
                    <tr key={record.id} className="table-row">
                      <td className="table-cell">
                        {formatDate(record.timestamp)}
                      </td>
                      <td className="table-cell">{record.empresa.nombre}</td>
                      <td className="table-cell">{record.code}</td>
                      <td className="table-cell">{record.registered_by}</td>
                      <td className="table-cell">{record.total_pagado_bs}</td>
                      <td className="table-cell d-flex w-100">
                        <a
                          className="btn btn-info d-flex"
                          href={record.pdf_url}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{
                            color: "white",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-pdf"
                            style={{ fontSize: "17px" }}
                          ></i>
                        </a>
                        <a
                          className="btn btn-warning d-flex"
                          onClick={() => handleSendAvisoCobro(record.id)}
                          style={{
                            color: "white",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                            cursor: "pointer", // Asegúrate de que parezca clicable
                          }}
                        >
                          <i
                            className="fa-solid fa-envelope"
                            style={{ fontSize: "17px" }}
                          ></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
