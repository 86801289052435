import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../store/appContext";
import { Link, Navigate } from "react-router-dom";
import "../../../styles/CompanyAdminNavbar.css";

export const Navbar = () => {
  const { store, actions } = useContext(Context);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.reload();
  };
  const handleClick = (menu) => {
    setSelectedMenu(menu);
  };

  useEffect(() => {
    // Obtén el pathname de la ubicación actual
    const pathname = location.pathname;

    // Mapea el pathname a un nombre de menú y establece el menú seleccionado
    const menuMapping = {
      "/": "/",
      "": "",
      "/RegistroCps": "/RegistroCps",
      "/EmpresasDeTransporte": "/EmpresasDeTransporte",
      "/Configuraciones": "/Configuraciones",
      "/RegistroUsuarios": "/RegistroUsuarios",
      "/Destinos": "/Destinos",
      "/Programacion": "/Programacion",
      "/ActivacionCalendario": "/ActivacionCalendario",
      "/PrecioDestinoFinalRC": "/PrecioDestinoFinalRC",
      "/PrecioDestinoFinalRL": "/PrecioDestinoFinalRL",
      "/KmDistancia": "/KmDistancia",
      "/RegistrosCpsEdit": "/RegistrosCpsEdit",
      "/Autorizacionporpuesto": "/Autorizacionporpuesto",
      "/CierresRutasCortas": "/CierresRutasCortas",
      "/RegistroActivacionesHoy": "/RegistroActivacionesHoy",
      "/AyudaYSoporte": "/AyudaYSoporte",
      "/EmpresaTerminal": "/EmpresaTerminal",
      "/RegistrosCpsVision": "/RegistrosCpsVision",
      "/ElaboracionListinRutasLargas": "/ElaboracionListinRutasLargas",
      "/CierreListinesRutasLargas": "/CierreListinesRutasLargas",
      "/AvisoDeCobro": "/AvisoDeCobro",
      "/EnvioAvisoDeCobro": "/EnvioAvisoDeCobro",
      "/EnvioAvisoDeCobroIndividual": "/EnvioAvisoDeCobroIndividual",
      "/ConsultaAvisosEntreFechas": "/ConsultaAvisosEntreFechas",
      "/RegistroPago": "/RegistroPago",
      "/PagosNoCerrados": "/PagosNoCerrados",
      "/Cierres": "/Cierres",
    };

    const menu = menuMapping[pathname];
    if (menu) {
      setSelectedMenu(menu);
    }
  }, [location.pathname]);

  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);
  const [isOpen3, setIsOpen3] = useState(true);

  return (
    <div className="ideaAdminCompany-container2">
      <div className="ideaAdminCompany-container" style={{ position: "fixed" }}>
        <div className="as56d4s9f87er98fwe4fwr987fwee98d4qw987">
          <img
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1713119110/INTRAVIALCA/truxqodtlg1vzishxhot.png"
            alt="Logo"
            style={{ height: "35px" }}
            className="d65f4sd9f8w7ef98we7qw654er987fw"
          />
          <i
            className="fa-solid a6s54d89f7v98wedq89wre98we87icneassa9d87 fa-bars"
            style={{ color: "white" }}
          ></i>
        </div>
        <div className="sdsdf98u7yt9htg7wfqe987879467434">
          {/* A partir de aqui coloca el nuevo menu aqui dentro de este div */}
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/" ? "selected-menu" : ""
            }`}
            to="/"
            onClick={() => handleClick("/")}
          >
            <i className="fa-solid seraUnMarinsupre fa-globe"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Inicio
            </div>
          </Link>

          <div
            style={{
              width: "100%",
              background: "#8080804a",
              height: ".07px",
              margin: "4px 0 4px 0",
            }}
          ></div>
          <div className="menu-container">
            <fieldset className={`menu-fieldset ${isOpen ? "open" : ""}`}>
              <legend
                onClick={() => setIsOpen(!isOpen)}
                className={`subtitulosMenuUserHomeCompany2 menu-legend m-0`}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  fontSize: "12px",
                  padding: "15px 10px 10px 24px",
                  cursor: "pointer",
                }}
              >
                <i
                  style={{
                    marginRight: "10px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  className="fa-solid fa-bolt-lightning"
                ></i>
                EMPRESA{" "}
                <i
                  style={{ marginLeft: "auto" }}
                  className="fa-solid fa-chevron-down"
                ></i>
              </legend>
              {isOpen && (
                <div className="menu-links">
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/ElaboracionListinRutasLargas" ? "selected-menu" : ""}`}
                    to="/ElaboracionListinRutasLargas"
                    onClick={() => handleClick("/ElaboracionListinRutasLargas")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-file-invoice"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Listines Rutas Largas
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/CierreListinesRutasLargas" ? "selected-menu" : ""}`}
                    to="/CierreListinesRutasLargas"
                    onClick={() => handleClick("/CierreListinesRutasLargas")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-file-zipper"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Cierre Listines
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu === "/RegistrosCpsVision"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("/RegistrosCpsVision")}
                    to="/RegistrosCpsVision"
                  >
                    <i className="fa-solid seraUnMarinsupre fa-list-check"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Listado Rutas Empresas
                    </div>
                  </Link>
                </div>
              )}
            </fieldset>
          </div>
          <div
            style={{
              width: "100%",
              background: "#8080804a",
              height: ".07px",
              margin: "4px 0 4px 0",
            }}
          ></div>

          <div className="menu-container">
            <fieldset className={`menu-fieldset ${isOpen2 ? "open" : ""}`}>
              <legend
                onClick={() => setIsOpen2(!isOpen2)}
                className={`subtitulosMenuUserHomeCompany2 menu-legend m-0`}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  fontSize: "12px",
                  padding: "15px 10px 10px 24px",
                  cursor: "pointer",
                }}
              >
                <i
                  style={{
                    marginRight: "10px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  className="fa-solid fa-bolt-lightning"
                ></i>
                AVISO DE COBRO{" "}
                <i
                  style={{ marginLeft: "auto" }}
                  className="fa-solid fa-chevron-down"
                ></i>
              </legend>
              {isOpen2 && (
                <div className="menu-links">
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/AvisoDeCobro" ? "selected-menu" : ""}`}
                    to="/AvisoDeCobro"
                    onClick={() => handleClick("/AvisoDeCobro")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-bell"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Aviso de Cobro
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/ConsultaAvisosEntreFechas" ? "selected-menu" : ""}`}
                    to="/ConsultaAvisosEntreFechas"
                    onClick={() => handleClick("/ConsultaAvisosEntreFechas")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-calendar-days"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Consulta Avisos
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/EnvioAvisoDecobro" ? "selected-menu" : ""}`}
                    to="/EnvioAvisoDecobro"
                    onClick={() => handleClick("/EnvioAvisoDecobro")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-envelope"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Envío Aviso de Cobro
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/EnvioAvisoDeCobroIndividual" ? "selected-menu" : ""}`}
                    to="/EnvioAvisoDeCobroIndividual"
                    onClick={() => handleClick("/EnvioAvisoDeCobroIndividual")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-envelope"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Envío Aviso de Cobro Individual
                    </div>
                  </Link>
                </div>
              )}
            </fieldset>
          </div>
          <div
            style={{
              width: "100%",
              background: "#8080804a",
              height: ".07px",
              margin: "4px 0 4px 0",
            }}
          ></div>
          <div className="menu-container">
            <fieldset className={`menu-fieldset ${isOpen3 ? "open" : ""}`}>
              <legend
                onClick={() => setIsOpen3(!isOpen3)}
                className={`subtitulosMenuUserHomeCompany2 menu-legend m-0`}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  fontSize: "12px",
                  padding: "15px 10px 10px 24px",
                  cursor: "pointer",
                }}
              >
                <i
                  style={{
                    marginRight: "10px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  className="fa-solid fa-hand-holding-dollar"
                ></i>
                PAGO{" "}
                <i
                  style={{ marginLeft: "auto" }}
                  className="fa-solid fa-chevron-down"
                ></i>
              </legend>
              {isOpen3 && (
                <div className="menu-links">
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/RegistroPago" ? "selected-menu" : ""}`}
                    to="/RegistroPago"
                    onClick={() => handleClick("/RegistroPago")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-cash-register"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Registro Pago
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/PagosNoCerrados" ? "selected-menu" : ""}`}
                    to="/PagosNoCerrados"
                    onClick={() => handleClick("/PagosNoCerrados")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-magnifying-glass-dollar"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Últimos Pagos Recibidos
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/CierreCajaDiario" ? "selected-menu" : ""}`}
                    to="/CierreCajaDiario"
                    onClick={() => handleClick("/CierreCajaDiario")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-check-to-slot"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Cierre Caja Diario
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/Cierres" ? "selected-menu" : ""}`}
                    to="/Cierres"
                    onClick={() => handleClick("/Cierres")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-folder-closed"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      visualizar Cierres
                    </div>
                  </Link>
                </div>
              )}
            </fieldset>
          </div>
          <div
            style={{
              width: "100%",
              background: "#8080804a",
              height: ".07px",
              margin: "4px 0 4px 0",
            }}
          ></div>

          <div className="menu-container">
            <fieldset className={`menu-fieldset ${isOpen1 ? "open" : ""}`}>
              <legend
                onClick={() => setIsOpen1(!isOpen1)}
                className={`subtitulosMenuUserHomeCompany2 menu-legend m-0`}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  fontSize: "12px",
                  padding: "15px 10px 10px 24px",
                  cursor: "pointer",
                }}
              >
                <i
                  style={{
                    marginRight: "10px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  className="fa-solid fa-users"
                ></i>
                USUARIOS{" "}
                <i
                  style={{ marginLeft: "auto" }}
                  className="fa-solid fa-chevron-down"
                ></i>
              </legend>
              {isOpen1 && (
                <div className="menu-links">
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/RegistroUsuarios" ? "selected-menu" : ""}`}
                    to="/RegistroUsuarios"
                    onClick={() => handleClick("/RegistroUsuarios")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-users"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Registro Usuarios
                    </div>
                  </Link>
                </div>
              )}
            </fieldset>
          </div>
          <div
            style={{
              width: "100%",
              background: "#8080804a",
              height: ".07px",
              margin: "4px 0 4px 0",
            }}
          ></div>

          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/AyudaYSoporte" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("/AyudaYSoporte")}
            to="/AyudaYSoporte"
          >
            <i className="fa-solid seraUnMarinsupre fa-headset"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Ayuda Y Soporte
            </div>
          </Link>

          <div className="s547"></div>
        </div>
        <div className="d56f4d89g7wfq98d7et98re4f6t5er1eq56w64">
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "Configuraciones" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("Configuraciones")}
            to="/Configuraciones"
          >
            <i className="fa-solid seraUnMarinsupre fa-gear"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Configuraciones
            </div>
          </Link>
        </div>
        <div className="d56f4d89g7wfq98d7et98re4f6t5er1eq56w64">
          <a
            className="subtitulosMenuUserHomeCompany logoutbuttonCompany"
            onClick={handleLogout}
            href="/"
          >
            <i className="fa-solid seraUnMarinsupre fa-right-from-bracket"></i>
            <div
              href="#"
              className="LetterInvisible2"
              style={{ fontSize: "12px" }}
            >
              Cerrar sesión
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
