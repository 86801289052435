import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../store/appContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "../../component/scrollToTop";
import { BackendURL } from "../../component/backendURL";
import "../../../styles/AddDriverForm.css";

import LoadingOverlay from "../../pages/LadingOverlay";

import { Front2 } from "../../pages/Front2";
import { Navbar } from "./Navbar";
import { Navbar_sup } from "./Navbar_sup";
import { UserHome } from "./userHome";
import { Terminales } from "./super/Terminales";
import { LineasTransporte } from "./super/LineasTransporte";
import { RegistroCpsGeneral } from "./super/RegistroCpsGeneral";
import { DestinoFinal } from "./super/DestinoFinal";
import { TypeTerminal } from "./super/TypeTerminal";
import { TasaDiariaBcv } from "./super/TasaDiariaBcv";

export const Layout_super_admin = () => {
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleRouteChange = (event) => {
    localStorage.setItem("lastVisitedRoute", event.url);
  };

  const basename = process.env.BASENAME || "";

  if (!process.env.BACKEND_URL || process.env.BACKEND_URL === "")
    return <BackendURL />;

  return (
    <div style={{ display: "flex" }}>
      <BrowserRouter basename={basename}>
        <ScrollToTop>
          <Navbar />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              overflow: "auto",
            }}
          >
            <Navbar_sup />
            <Routes onChange={handleRouteChange}>
              <Route element={<UserHome />} path="/" />
              <Route element={<Terminales />} path="/Terminales" />
              <Route element={<TypeTerminal />} path="/TipoTerminal" />
              <Route element={<DestinoFinal />} path="/DestinoFinal" />
              <Route element={<LineasTransporte />} path="/LineasTransporte" />
              <Route element={<TasaDiariaBcv />} path="/RegistroTasa" />
              <Route
                element={<RegistroCpsGeneral />}
                path="/RegistroCpsGeneral"
              />
              <Route element={<Front2 />} path="/Configuraciones" />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </ScrollToTop>
      </BrowserRouter>
      {isLoading && <LoadingOverlay />}
    </div>
  );
};
