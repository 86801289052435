import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

// const fetchTodayRecords = async () => {
//   try {
//     // Configurar el token de autorización en los headers
//     const response = await axios.get(
//       process.env.BACKEND_URL + "/api/audit_envio_avisos",
//       {
//         headers: {
//           Authorization: `Bearer ${store.token}`, // Corrige el uso del token
//         },
//       }
//     );
//     setRecords(response.data.audit_envio); // Guarda los datos obtenidos
//   } catch (error) {
//     console.error("Error al obtener registros de hoy:", error);
//   }
// };

// useEffect(() => {
//   fetchTodayRecords();
// }, []);

const MySwal = withReactContent(Swal);

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginLeft: 60,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#FFF",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#24827c",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeader2: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#b8b8b8",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 7,
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
  },
  tableCell: {
    margin: 4,
    fontSize: 7,
  },
});

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "17.5%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOtherinfinity = {
  ...tableStyles.tableColHeader,
  width: "14.28571428571428%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther3 = {
  ...tableStyles.tableColHeader,
  width: "33.33333333%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther44 = {
  ...tableStyles.tableColHeader2,
  width: "25%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther2 = {
  ...tableStyles.tableColHeader2,
  width: "16.66666666666%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderRuta = {
  ...tableStyles.tableColHeader,
  width: "30%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderRuta2 = {
  ...tableStyles.tableColHeader2,
  width: "16%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderRuta3 = {
  ...tableStyles.tableColHeader2,
  width: "33.333333%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderEstatus = {
  ...tableStyles.tableColHeader,
  width: "10%", // Mayor ancho para los demás
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "17.5%", // Mayor ancho para los demás
};

tableStyles.tableColOtherinfinity = {
  ...tableStyles.tableCol,
  width: "14.28571428571428%", // Mayor ancho para los demás
};

tableStyles.tableColOther20 = {
  ...tableStyles.tableCol,
  width: "16.6666666666%", // Mayor ancho para los demás
};

tableStyles.tableColOther3 = {
  ...tableStyles.tableCol,
  width: "33.333333333%", // Mayor ancho para los demás
};
tableStyles.tableColOther44 = {
  ...tableStyles.tableCol,
  width: "25%", // Mayor ancho para los demás
};

tableStyles.tableColRuta = {
  ...tableStyles.tableCol,
  width: "30%", // Mayor ancho para los demás
};
tableStyles.tableColRuta3 = {
  ...tableStyles.tableCol,
  width: "33.33333333%", // Mayor ancho para los demás
};

tableStyles.tableColRuta20 = {
  ...tableStyles.tableCol,
  width: "16%", // Mayor ancho para los demás
};

tableStyles.tableColEstatus = {
  ...tableStyles.tableCol,
  width: "10%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  page: {
    padding: 20, // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 10,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    paddingTop: 5,
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
});

const CierreDiario = (datos, formData) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header} fixed>
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
          style={styles.logo2}
        />
      </View>
      <View fixed>
        <View>
          <Text style={{ textAlign: "center", marginTop: 10 }}>
            REPORTE DIARIO OPERACIONES TERMINAL LA BANDERA
          </Text>
          <Text
            style={{ textAlign: "center", fontSize: "12px", color: "gray" }}
          >
            FECHA: {formData.fecha_cierre}
          </Text>
        </View>
      </View>
      <View>
        <View style={{ marginTop: 10 }}>
          <Text style={{ textAlign: "end", fontSize: 12 }}>
            Cierre hecho por:
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 10 }}></View>
      <View style={tableStyles.table}>
        <View style={tableStyles.tableRow} fixed>
          <View style={tableStyles.tableColHeaderOther3}>
            <Text style={tableStyles.tableCellHeader}>N° ACTIVACIONES</Text>
          </View>
          <View style={tableStyles.tableColHeaderOther3}>
            <Text style={tableStyles.tableCellHeader}>CANCELADOS</Text>
          </View>
          <View style={tableStyles.tableColHeaderOther3}>
            <Text style={tableStyles.tableCellHeader}>CON LISTÍN</Text>
          </View>
        </View>
        <View style={tableStyles.tableRow}>
          <View style={tableStyles.tableColOther3}>
            <Text style={tableStyles.tableCell}>{datos.num_activaciones}</Text>
          </View>
          <View style={tableStyles.tableColOther3}>
            <Text style={tableStyles.tableCell}></Text>
          </View>
          <View style={tableStyles.tableColOther3}>
            <Text style={tableStyles.tableCell}></Text>
          </View>
        </View>
      </View>
      <View style={{ marginTop: 10 }}></View>
      <View style={tableStyles.table}>
        <View style={tableStyles.tableRow} fixed>
          <View style={tableStyles.tableColHeaderOther44}>
            <Text style={tableStyles.tableCellHeader}>CON RETRASO</Text>
          </View>
          <View style={tableStyles.tableColHeaderOther44}>
            <Text style={tableStyles.tableCellHeader}>A TIEMPO</Text>
          </View>
          <View style={tableStyles.tableColHeaderOther44}>
            <Text style={tableStyles.tableCellHeader}>
              SALIDAS A TIEMPO (%)
            </Text>
          </View>
          <View style={tableStyles.tableColHeaderOther44}>
            <Text style={tableStyles.tableCellHeader}>NIVEL DE EFICACIA</Text>
          </View>
        </View>
        <View style={tableStyles.tableRow}>
          <View style={tableStyles.tableColOther44}>
            <Text style={tableStyles.tableCell}></Text>
          </View>
          <View style={tableStyles.tableColOther44}>
            <Text style={tableStyles.tableCell}></Text>
          </View>
          <View style={tableStyles.tableColOther44}>
            <Text style={tableStyles.tableCell}></Text>
          </View>
          <View style={tableStyles.tableColOther44}>
            <Text style={tableStyles.tableCell}></Text>
          </View>
        </View>
      </View>
      <View style={{ marginTop: 10 }}></View>
      {datos.activaciones.map((activacion, index) => (
        <View style={styles.section} wrap={false}>
          <View wrap={false}>
            <View style={{ marginTop: 10 }}>
              <Text style={{ fontSize: 11 }}>ACTIVACIÓN ({index + 1}):</Text>
            </View>
            <View style={tableStyles.table}>
              <View style={tableStyles.tableRow} fixed>
                <View style={tableStyles.tableColHeaderOtherinfinity}>
                  <Text style={tableStyles.tableCellHeader}>
                    FECHA Y HORA {"\n"} REGISTRO
                  </Text>
                </View>
                <View style={tableStyles.tableColHeaderOtherinfinity}>
                  <Text style={tableStyles.tableCellHeader}>FECHA VIAJE</Text>
                </View>
                <View style={tableStyles.tableColHeaderOtherinfinity}>
                  <Text style={tableStyles.tableCellHeader}>EMPRESA</Text>
                </View>
                <View style={tableStyles.tableColHeaderOtherinfinity}>
                  <Text style={tableStyles.tableCellHeader}>DESTINO</Text>
                </View>
                <View style={tableStyles.tableColHeaderOtherinfinity}>
                  <Text style={tableStyles.tableCellHeader}>HORA SALIDA</Text>
                </View>
                <View style={tableStyles.tableColHeaderOtherinfinity}>
                  <Text style={tableStyles.tableCellHeader}>UNIDAD</Text>
                </View>
                <View style={tableStyles.tableColHeaderOtherinfinity}>
                  <Text style={tableStyles.tableCellHeader}>ESTATUS FINAL</Text>
                </View>
              </View>
              <View style={tableStyles.tableRow}>
                <View style={tableStyles.tableColOtherinfinity}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
                <View style={tableStyles.tableColOtherinfinity}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
                <View style={tableStyles.tableColOtherinfinity}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
                <View style={tableStyles.tableColOtherinfinity}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
                <View style={tableStyles.tableColOtherinfinity}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
                <View style={tableStyles.tableColOtherinfinity}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
                <View style={tableStyles.tableColOtherinfinity}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
              </View>
            </View>
          </View>

          <View wrap={false}>
            <View style={{ marginTop: 10 }}>
              <Text style={{ fontSize: 11, marginLeft: 60 }}>
                CAMBIOS REALIZADOS ():
              </Text>
            </View>
            <View style={tableStyles.table2}>
              <View style={tableStyles.tableRow}>
                <View style={tableStyles.tableColHeaderOther2}>
                  <Text style={tableStyles.tableCellHeader}>FECHA Y HORA</Text>
                </View>
                <View style={tableStyles.tableColHeaderOther2}>
                  <Text style={tableStyles.tableCellHeader}>ESTATUS</Text>
                </View>
                <View style={tableStyles.tableColHeaderOther2}>
                  <Text style={tableStyles.tableCellHeader}>PLACA</Text>
                </View>
                <View style={tableStyles.tableColHeaderOther2}>
                  <Text style={tableStyles.tableCellHeader}>N° UNIDAD</Text>
                </View>
                <View style={tableStyles.tableColHeaderOther2}>
                  <Text style={tableStyles.tableCellHeader}>ÁNDEN</Text>
                </View>
                <View style={tableStyles.tableColHeaderOther2}>
                  <Text style={tableStyles.tableCellHeader}>CAMBIO POR</Text>
                </View>
              </View>
            </View>
            <View style={tableStyles.table2}>
              <View style={tableStyles.tableRow}>
                <View style={tableStyles.tableColOther20}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
                <View style={tableStyles.tableColOther20}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
                <View style={tableStyles.tableColOther20}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
                <View style={tableStyles.tableColOther20}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
                <View style={tableStyles.tableColOther20}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
                <View style={tableStyles.tableColOther20}>
                  <Text style={tableStyles.tableCell}></Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      ))}

      <View style={styles.footer} fixed>
        <Text>Reporte generado por el sistema login.intravialca-ve.com</Text>
      </View>
    </Page>
  </Document>
);

export const ReporteDiarioActivaciones = () => {
  const { store } = useContext(Context);
  const [reporte, setReporte] = useState(null);
  const navigate = useNavigate();
  const [records, setRecords] = useState(null);
  const [recordsTotals, setRecordsTotals] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [empresaId, setEmpresaId] = useState(0);
  const [formData2, setFormData2] = useState({
    fecha_cierre: "",
  });

  const handlePreviewPDF = async () => {
    try {
      // Mostrar mensaje de carga
      Swal.fire({
        title: "Generando vista previa...",
        html: "<p>Por favor, espera mientras preparamos el PDF.</p>",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      //   Solicitar los datos necesarios al backend
      const response = await axios.post(
        process.env.BACKEND_URL +
          "/api/generar_firma_cloudinary_activaciones_cierre_diario",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const todo = response.data;

      //   Generar PDF con los datos obtenidos
      const pdfBlob = await pdf(
        <CierreDiario datos={todo} formData={formData2} />
      ).toBlob();

      // Crear URL temporal para el PDF
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");

      Swal.close(); // Cerrar la ventana de carga
    } catch (error) {
      console.error("Error al generar PDF:", error);
      Swal.fire({
        title: "Error",
        text: "Ocurrió un error al generar la vista previa del PDF. Inténtalo nuevamente.",
        icon: "error",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Actualizar el estado del formulario
    setFormData2((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const CierreCaja = async () => {
    try {
      setIsSubmitting(true);

      // 1. Pedir confirmación al usuario
      const result = await MySwal.fire({
        title: "¿Estás seguro de que quieres hacer el cierre?",
        text: "Recuerda que solo puedes hacer un cierre de caja al día.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, cerrar",
        cancelButtonText: "Cancelar",
      });

      // Si el usuario cancela, no hacer nada
      if (!result.isConfirmed) {
        return;
      }

      // 2. Generar y subir el PDF
      const { pdfUrl, paqueteCode, timestamp } = await handlePDFUpload();

      if (!pdfUrl || !paqueteCode) {
        throw new Error(
          "No se pudo generar o subir el PDF. Intenta nuevamente."
        );
      }

      // 3. Procesar el pago después de subir el PDF
      await axios.post(
        `${process.env.BACKEND_URL}/api/register_cierre_caja_recau`,
        {
          paqueteCode,
          pdfUrl,
          timestamp,
          formData2,
        },
        {
          headers: { Authorization: `Bearer ${store.token}` },
        }
      );

      // Mostrar mensaje de éxito
      MySwal.fire({
        title: "Cierre Exitoso",
        text: "El cierre ha sido procesado correctamente.",
        icon: "success",
      }).then(() => {
        navigate("/Cierres");
      });
    } catch (error) {
      console.error("Error al procesar el cierre:", error);

      MySwal.fire({
        title: "Error",
        text: `Hubo un problema al procesar el pago: ${
          error.response?.data?.message ||
          error.message ||
          "Intenta nuevamente."
        }`,
        icon: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const generatePDF = async (
    timestamp_ve,
    nombre_apellido_user,
    email_user,
    paquete_code,
    detalle_paquetes,
    numero_de_avisos_de_cobro,
    numero_de_paquetes,
    total_pagado,
    totales_formas_pago
  ) => {
    try {
      const pdfBlob = await pdf(
        <CierreDiario
          timestamp={timestamp_ve}
          nombre_apellido_user={nombre_apellido_user}
          email_user={email_user}
          code={paquete_code}
          detalle_paquetes={detalle_paquetes}
          numero_de_avisos_de_cobro={numero_de_avisos_de_cobro}
          numero_de_paquetes={numero_de_paquetes}
          total_pagado={total_pagado}
          totales_formas_pago={totales_formas_pago}
        />
      ).toBlob();

      return new File([pdfBlob], `CIERRE_CAJA_.pdf`, {
        type: "application/pdf",
      });
    } catch (error) {
      console.error("Error al generar el PDF Blob:", error);
      throw new Error("Error al convertir el PDF a Blob.");
    }
  };

  const handlePDFUpload = async () => {
    try {
      const response = await axios.post(
        process.env.BACKEND_URL +
          "/api/generar_firma_cloudinary_activaciones_cierre_diario",
        formData2,
        {
          headers: { Authorization: "Bearer " + store.token },
        }
      );

      const {
        signature,
        timestamp_ve,
        timestamp,
        api_key,
        cloud_name,
        folder,
        email_user,
        nombre_apellido_user,
        paquete_code,
        detalle_paquetes,
        numero_de_avisos_de_cobro,
        numero_de_paquetes,
        total_pagado,
        totales_formas_pago,
      } = response.data;

      const pdfFile = await generatePDF(
        timestamp_ve,
        nombre_apellido_user,
        email_user,
        paquete_code,
        detalle_paquetes,
        numero_de_avisos_de_cobro,
        numero_de_paquetes,
        total_pagado,
        totales_formas_pago
      );

      const formData = new FormData();
      formData.append("file", pdfFile);
      formData.append("timestamp", timestamp);
      formData.append("signature", signature);
      formData.append("api_key", api_key);
      formData.append("folder", folder);

      const response2 = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/auto/upload`,
        formData
      );

      if (!response2.data || !response2.data.secure_url) {
        throw new Error("Cloudinary no devolvió una URL válida del PDF.");
      }

      return {
        pdfUrl: response2.data.secure_url,
        paqueteCode: paquete_code,
        timestamp: response.data,
      };
    } catch (error) {
      console.error("Error en handlePDFUpload:", error);
      throw new Error(
        error.response?.data?.message ||
          "Error al generar o subir el PDF. Intenta nuevamente."
      );
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-check-to-slot"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Diariamente, haz un cierre de todos los pagos recibidos.</p>
        </div>
        <div className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100">
          <div
            className="container asklnduiashndaijsnxansxsaas3"
            style={{ marginBottom: "20px", marginTop: "-40px" }}
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="date"
                  name="fecha_cierre"
                  onChange={handleChange}
                  value={formData2.fecha_cierre}
                  required
                />
                <label>Fecha de Cierre</label>
              </div>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento m-0"
              onClick={CierreCaja}
              disabled={isSubmitting}
              style={{ width: "200px" }}
            >
              {isSubmitting ? "Espera..." : "Hacer Cierre"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
        <hr style={{ width: "90%" }}></hr>
        {!isSubmitting ? (
          <div
            className="custom-table-container m-0"
            style={{
              maxWidth: "100%",
              overflow: "auto",
              maxHeight: "60vh",
            }}
          >
            {records ? (
              <div style={{ padding: "10px", width: "100%" }}>
                <table
                  className="custom-table rounded-table"
                  style={{ minWidth: "100%", marginBottom: "20px" }}
                >
                  <thead>
                    <tr>
                      <th className="table-header">N° Avisos</th>
                      <th className="table-header">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ fontSize: "11px" }}>
                      <td>{recordsTotals.num_registros}</td>
                      <td>{recordsTotals.total_bolivares_suma} Bs.</td>
                    </tr>
                  </tbody>
                </table>
                <h4 style={{ textAlign: "center" }}>
                  Listines Pagados el día <br></br>{" "}
                  {formatDate(formData2.fecha_cierre)}
                </h4>
                <div
                  className="custom-table-container m-0"
                  style={{
                    maxWidth: "100%",
                    overflow: "auto",
                    maxHeight: "60vh",
                  }}
                >
                  <table
                    className="custom-table rounded-table m-0"
                    style={{ minWidth: "100%", minWidth: "650px" }}
                  >
                    <thead>
                      <tr>
                        <th className="table-header">Fecha Viaje</th>
                        <th className="table-header">N° Listín</th>
                        <th className="table-header">Empresa</th>
                        <th className="table-header">Valor Dólar</th>
                        <th className="table-header">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.map((record, index) => (
                        <tr key={index} style={{ fontSize: "11px" }}>
                          <td>{record.fecha_de_viaje}</td>
                          <td>{record.n_listin}</td>
                          <td>{record.empresa}</td>
                          <td>{record.valor_dolar} Bs.</td>
                          <td>{record.total_bolivares} Bs.</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <img
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1731494258/1/dvhl0hwbx4adb8uceymr.gif"
              alt="Cargando..."
              style={{ width: "150px" }}
            />
          </div>
        )}
      </div>

      <div className="text-center mt-3">
        <button onClick={handlePreviewPDF} className="btn btn-primary">
          Vista Previa del Recibo
        </button>
      </div>
    </div>
  );
};

// CARGAR PREVIEW DEL PDFFFFFFFFFFFFFFFFFF___________::::::::::::::::::::::::::::::::::::::::::::::::. OJO
