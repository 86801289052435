import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../../styles/RegistroActivacionesHoy.css";
import { Context } from "../../../store/appContext.js";
import { useNavigate, useParams } from "react-router-dom";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#FFF",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#24827c",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeader2: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#8c1010",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
    color: "white",
  },
  tableCell: {
    margin: 4,
    fontSize: 7,
  },
});

tableStyles.tableColHeaderOthertaxi = {
  ...tableStyles.tableColHeader,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi = {
  ...tableStyles.tableCol,
  width: "50%", // Mayor ancho para los demás
};

// Modificar anchos específicos aquí
tableStyles.tableColHeaderNumber = {
  ...tableStyles.tableColHeader,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "13.6%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther4 = {
  ...tableStyles.tableColHeader,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOtherDT = {
  ...tableStyles.tableColHeader,
  width: "10%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOtherDT2 = {
  ...tableStyles.tableColHeader,
  width: "5%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOtherDT3 = {
  ...tableStyles.tableColHeader,
  width: "7%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther2 = {
  ...tableStyles.tableColHeader2,
  width: "13.6%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther3 = {
  ...tableStyles.tableColHeader2,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColNumber = {
  ...tableStyles.tableCol,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "13.6%", // Mayor ancho para los demás
};
tableStyles.tableColOther2 = {
  ...tableStyles.tableCol,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColOtherDT = {
  ...tableStyles.tableCol,
  width: "10%", // Mayor ancho para los demás
};
tableStyles.tableColOtherDT2 = {
  ...tableStyles.tableCol,
  width: "5%", // Mayor ancho para los demás
};
tableStyles.tableColOtherDT3 = {
  ...tableStyles.tableCol,
  width: "7%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOthertaxi2 = {
  ...tableStyles.tableColHeader,
  width: "83.33333%",
  color: "white",
};
tableStyles.tableColHeaderOthertaxi22 = {
  ...tableStyles.tableColHeader,
  width: "16.66666%",
  color: "white",
};
tableStyles.tableColHeaderOthertaxi3 = {
  ...tableStyles.tableColHeader,
  width: "100%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi2 = {
  ...tableStyles.tableCol,
  width: "33.3%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  page: {
    padding: 20, // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 10,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    paddingTop: 10,
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
});

const formatDate = (dateString) => {
  const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
  const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
};

const AvisosDeCobroPendientes = ({ avisos, nombre, rif }) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.header} fixed>
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
          style={styles.logo2}
        />
      </View>
      <View>
        <View>
          <Text style={{ textAlign: "center" }}>{`${nombre}`}</Text>
          <Text
            style={{ textAlign: "center", fontSize: "12px", color: "gray" }}
          >{`${rif}`}</Text>
        </View>
      </View>
      {/* Rutas CPS */}
      <View style={{ marginTop: 10 }}></View>
      <View style={styles.section}>
        <Text style={styles.header}>Avisos de Cobro Pendientes</Text>
        <View style={{ marginTop: 10 }}></View>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow} fixed wrap={false}>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>Nº Listín</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Unidad</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>Destino</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>Fecha{"\n"} Viaje</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Bs / $</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Adulto</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>3ra Edad</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Discap</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Niños</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Cortesías</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT3}>
              <Text style={tableStyles.tableCellHeader}>Venta(Bs)</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT3}>
              <Text style={tableStyles.tableCellHeader}>Listin(Bs.)</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT3}>
              <Text style={tableStyles.tableCellHeader}>T.Salida(Bs)</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT3}>
              <Text style={tableStyles.tableCellHeader}>Total(Bs)</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT3}>
              <Text style={tableStyles.tableCellHeader}>Ref ($)</Text>
            </View>
          </View>
          {avisos.avisos.map((avisos, index) => (
            <View style={tableStyles.tableRow} key={index} wrap={false}>
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>
                  {avisos.listines.n_listin_terminal}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>
                  {avisos.listines.activation.numero_de_unidad}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>
                  {
                    avisos.listines.activation.ruta.destino_final
                      .destino_final_detalle.descripcion
                  }
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>
                  {formatDate(avisos.listines.activation.fecha_de_viaje)}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>{avisos.valor_dolar}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>{avisos.adultos}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>{avisos.tercera_edad}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>
                  {avisos.discapacitados}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>
                  {avisos.ninos_4_menos}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>{avisos.cortesias}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT3}>
                <Text style={tableStyles.tableCell}>
                  {avisos.total_venta_bolivares}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT3}>
                <Text style={tableStyles.tableCell}>
                  {avisos.precio_listin_bolivares}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT3}>
                <Text style={tableStyles.tableCell}>
                  {avisos.precio_tasa_bolivares}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT3}>
                <Text style={tableStyles.tableCell}>
                  {avisos.total_bolivares}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT3}>
                <Text style={tableStyles.tableCell}>
                  {(avisos.total_bolivares / avisos.valor_dolar).toFixed(2)}
                </Text>
              </View>
            </View>
          ))}
          <View style={tableStyles.tableRow}>
            <View style={tableStyles.tableColHeaderOthertaxi2}>
              <Text style={tableStyles.tableCell}>TOTAL</Text>
            </View>
            <View style={tableStyles.tableColHeaderOthertaxi22}>
              <Text style={tableStyles.tableCell}>{avisos.deuda} Bs.</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.footer} fixed>
        <Text>
          Reporte generado por el sistema de INTRAVIALCA
          login.intravialca-ve.com
        </Text>
      </View>
    </Page>
  </Document>
);

const DriverDetailsPDF = ({ driver, empresaDetalle }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header} fixed>
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
          style={styles.logo2}
        />
      </View>
      {/* <View style={styles.header}>
        <Text style={styles.letter2}>
          Rutas de {empresaDetalle.empresa.empresa.nombre}
        </Text>
      </View> */}
      {empresaDetalle && (
        <View>
          {/* Primera columna */}
          <View>
            <Text
              style={{ textAlign: "center" }}
            >{`${empresaDetalle.empresa.nombre}`}</Text>
            <Text
              style={{ textAlign: "center", fontSize: "12px", color: "gray" }}
            >{`${empresaDetalle.empresa.rif}`}</Text>
          </View>
        </View>
      )}
      {/* Rutas CPS */}
      <View style={{ marginTop: 10 }}></View>
      <View style={styles.section}>
        <Text style={styles.header}>Conductores</Text>
        <View style={{ marginTop: 10 }}></View>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow}>
            <View style={tableStyles.tableColHeaderOther4}>
              <Text style={tableStyles.tableCellHeader}>Nombre y Apellido</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Cédula</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Nacimiento</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>
                <Text style={{ textAlign: "center" }}>
                  Vencimiento {"     "} licencia
                </Text>
              </Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Grado</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Estatus</Text>
            </View>
          </View>
          {driver.map((driver, index) => (
            <View style={tableStyles.tableRow} key={index}>
              <View style={tableStyles.tableColOther2}>
                <Text style={tableStyles.tableCell}>
                  {driver.first_name} {driver.last_name}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {driver.nacionalidad}-{driver.cedula}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {formatDate(driver.fecha_nacimiento)}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {formatDate(driver.fecha_vencimiento_licencia)}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {driver.grado_licencia}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {driver.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.footer} fixed>
        <Text>
          Reporte generado por el sistema de INTRAVIALCA
          login.intravialca-ve.com
        </Text>
      </View>
    </Page>
  </Document>
);

const MySwal = withReactContent(Swal);

const EstatusIcon = {
  ACTIVO: {
    icon: "fa-check",
    name: "ACTIVO",
    color: "linear-gradient(to right, #28a745, #31c353)", // Verde
  },
  INACTIVO: {
    icon: "fa-ban",
    name: "CANCELADO",
    color: "linear-gradient(to right, #dc3545, #f24d5e)", // Rojo
  },
};

export const RegistrosCpsEditRegistroDeCpsEditNuevo = () => {
  const [records, setRecords] = useState([]);
  const navigate = useNavigate();
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { store } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [avisos, setAvisos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_empresas_terminal_solo_cps_recau",
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setRecords(response.data.empresas_terminal);
      setFilteredRecords(response.data.empresas_terminal); // Inicializa el filtrado
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    } finally {
      setLoading(false); // Cambia a `false` al terminar la carga
    }
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterAndSortRecords(e.target.value, statusFilter, sortOrder);
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    filterAndSortRecords(searchTerm, e.target.value, sortOrder);
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
    filterAndSortRecords(searchTerm, statusFilter, e.target.value);
  };

  const filterAndSortRecords = (search, status, order) => {
    let filtered = records.filter((record) => {
      return (
        (!status || record.empresa.status === status) &&
        (!search ||
          record.empresa.nombre.toLowerCase().includes(search.toLowerCase()) ||
          record.empresa.rif.toLowerCase().includes(search.toLowerCase()))
      );
    });

    setFilteredRecords(filtered);
  };

  const showEmpresaAvisosPending = async (id, nombre, formatDate, rif) => {
    try {
      // Mostrar ventana de carga
      MySwal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando Avisos de Cobro pendientes de la Empresa seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/get_avisos_cobro_pending/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Extraer datos de la empresa
      const avisoss = response.data.avisos;
      const deuda = response.data.deuda;
      setAvisos(response.data);
      MySwal.close();

      // Verificar si la empresa tiene rutas
      if (avisoss.length > 0) {
        MySwal.fire({
          title: `Avisos Pendientes ${nombre}`,
          html: `<div class="custom-table-container m-0 d-flex" style="flex-direction: column">
          <div class="d-flex" style="width: 100%; position: absolute">
              <button
                id="showDTDetailsPdfButton"
                class="btn btn-info text-light desktop-er65g48e9r7we984fwe594"
                style="font-size: 15px; margin-right: 10px"
              >
                <i class="fa-solid fa-file-pdf m-0" style="color: white; font-size: 12px"></i>
              </button>
              <button
                id="showDTDetailsPdfButtonMobile"
                class="btn btn-info text-light mobile-er65g48e9r7we984fwe594"
                style="font-size: 15px; margin-right: 10px"
              >
                <i class="fa-solid fa-file-pdf m-0" style="color: white; font-size: 12px"></i>
              </button>
          </div>
            <table class="custom-table rounded-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4" style="width: 100%; min-width: 500px">
              <thead>
                <tr>
                  <th class="table-header" style="font-size: 14px;">Fecha de Viaje</th>
                  <th class="table-header" style="font-size: 14px;">Listín</th>
                  <th class="table-header" style="font-size: 14px;">Listin %</th>
                  <th class="table-header" style="font-size: 14px;">Tasa Salida</th>
                  <th class="table-header" style="font-size: 14px;">Total</th>
                </tr>
              </thead>
              <tbody>
                ${avisoss
                  .map(
                    (aviso, index) => `
                  <tr key="${index}" class="table-row">
                    <td class="table-cell" style="font-size: 12px;">${formatDate(aviso.listines.activation.fecha_de_viaje)}</td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${aviso.listines.n_listin_terminal}
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${aviso.precio_listin_bolivares} Bs.
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${aviso.precio_tasa_bolivares} Bs.
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${aviso.total_bolivares} Bs.
                    </td>
                  </tr>`
                  )
                  .join("")}
                  <tr class="table-row">
                    <td class="table-cell" style="font-size: 12px; text-align: center" colspan="3">TOTAL</td>
                    <td class="table-cell" style="font-size: 14px; text-align: center" colspan="2">
                    ${deuda}Bs.
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>`,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const buttonRoute = document.getElementById(
              "showRouteDetailsPdfButton"
            );
            if (buttonRoute) {
              buttonRoute.addEventListener("click", () => {
                showRouteDetailsPdf(id);
              });
            }
            const buttonRouteMobile = document.getElementById(
              "showRouteDetailsPdfButtonMobile"
            );
            if (buttonRouteMobile) {
              buttonRouteMobile.addEventListener("click", () => {
                showRouteDetailsPdfDownload(id);
              });
            }
            const buttonDT = document.getElementById("showDTDetailsPdfButton");
            if (buttonDT) {
              buttonDT.addEventListener("click", () => {
                showDTDetailsPdf(id, nombre, rif);
              });
            }
            const buttonDTMobile = document.getElementById(
              "showDTDetailsPdfButtonMobile"
            );
            if (buttonDTMobile) {
              buttonDTMobile.addEventListener("click", () => {
                showDTDetailsPdfDownload(id, nombre, rif);
              });
            }
            const buttonDriver = document.getElementById(
              "showDriverDetailsPdfButton"
            );
            if (buttonDriver) {
              buttonDriver.addEventListener("click", () => {
                showDriverDetailsPdf(id);
              });
            }
            const buttonDriverMobile = document.getElementById(
              "showDriverDetailsPdfButtonMobile"
            );
            if (buttonDriverMobile) {
              buttonDriverMobile.addEventListener("click", () => {
                showDriverDetailsPdfDownload(id);
              });
            }
            document
              .querySelectorAll(".dtContingenciaUpdate-status-dt")
              .forEach((button) => {
                button.addEventListener("click", async () => {
                  const dt_id = button.dataset.id; // Obtener el ID de la ruta
                  const currentStatus = button.dataset.status; // Obtener el estado actual
                  const newStatus =
                    currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE"; // Alternar el estado

                  try {
                    MySwal.fire({
                      title: "Actualizando estado",
                      text: `La DT seleccionada está siendo marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });
                    // Llamada a la API para actualizar el estado
                    const response = await axios.put(
                      `${process.env.BACKEND_URL}/api/dt_status_contingencia/${dt_id}/updateStatus`,
                      { status: newStatus },
                      {
                        headers: {
                          Authorization: `Bearer ${store.token}`,
                        },
                      }
                    );

                    if (response.status === 200) {
                      MySwal.fire({
                        title: "Estado actualizado",
                        text: `La DT ha sido marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                        icon: "success",
                      });
                      // Recargar la tabla o actualizar dinámicamente
                      showDTDetails(id); // Vuelve a cargar los detalles
                    } else {
                      throw new Error("No se pudo actualizar el estado.");
                    }
                  } catch (error) {
                    console.error("Error al actualizar el estado:", error);
                    MySwal.fire({
                      title: "Error",
                      text: "No se pudo actualizar el estado de la DT. Inténtelo nuevamente.",
                      icon: "error",
                    });
                  }
                });
              });
          },
        });
      } else {
        MySwal.fire({
          title: `La Empresa ${nombre}, no tiene Avisos de Cobro pendientes!`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const showDTDetailsPdf = async (id, nombre, rif) => {
    try {
      setIsLoading(true);

      // Mostrar ventana de carga
      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando Avisos de la empresa seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/get_avisos_cobro_pending/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const avisosTodos = response.data;

      // Verificar si hay rutas disponibles
      if (avisosTodos.avisos.length > 0) {
        Swal.close(); // Cerrar la ventana de carga

        // Generar el PDF
        const pdfBlob = await pdf(
          <AvisosDeCobroPendientes
            avisos={avisosTodos}
            nombre={nombre}
            rif={rif}
          />
        ).toBlob();

        // Crear una URL temporal para el Blob y abrirla en una nueva ventana
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      } else {
        Swal.fire({
          title: `La Empresa ${nombre}, no tiene avisos de cobro pendientes!`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const showDTDetailsPdfDownload = async (id, nombre, rif) => {
    try {
      setIsLoading(true);

      // Mostrar ventana de carga
      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando los avisos de la Empresa seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/get_avisos_cobro_pending/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Extraer datos de la empresa
      const avisosTodos = response.data;

      // Verificar si hay rutas disponibles
      if (avisosTodos.avisos.length > 0) {
        Swal.close(); // Cerrar la ventana de carga

        // Generar el PDF
        const pdfBlob = await pdf(
          <AvisosDeCobroPendientes
            avisos={avisosTodos}
            nombre={nombre}
            rif={rif}
          />
        ).toBlob();

        // Crear un enlace de descarga programático
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download = "dt-details.pdf";
        document.body.appendChild(link);
        link.click(); // Simular clic para descargar el archivo
        document.body.removeChild(link);
      } else {
        Swal.fire({
          title: `La Empresa ${nombre}, no tiene avisos de cobro pendientes!`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateStatus = async (id, status) => {
    MySwal.fire({
      title: "Representa el Estatus de la empresa",
      html: "Solo el Director del Terminal puede bloquear las Empresas",
      icon: "warning",
    });
  };

  const handleAvisosPendientes = async (id, status) => {
    MySwal.fire({
      title: "Número de Avisos de Cobro Pendientes",
      html: "La Empresa con más Avisos de Cobro Pendientes se coloca de primero en la lista",
      icon: "warning",
    });
  };

  const handleChangeStatus = (record) => {
    MySwal.fire({
      title: (
        <h6>
          <i
            className="fa-solid fa-pen-to-square"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Editar Correo
        </h6>
      ),
      html: `
        <div class="input-group-login2">
          <label for="swal-email-empresa" style="display: block; margin-bottom: 5px;">Correo</label>
          <input 
            type="email" 
            id="swal-email-empresa" 
            class="inputDeLogin" 
            style="width: 100%; margin-bottom: 20px;" 
            value="${record.email || ""}" 
            placeholder="Ingrese un correo válido" 
            required 
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}" 
            aria-label="Correo"
          >
        </div>
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      preConfirm: () => {
        const emailInput = document.getElementById("swal-email-empresa");
        const emailValue = emailInput.value;

        if (!emailInput.checkValidity()) {
          MySwal.showValidationMessage("Por favor, ingrese un correo válido.");
          return false;
        }

        return { email_empresa: emailValue };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { email_empresa } = result.value;

        try {
          const response = await axios.put(
            `${process.env.BACKEND_URL}/api/edit_empresa/${record.id}/updateEmail`,
            {
              email_empresa,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          Swal.fire({
            icon: "success",
            title: response.data.message,
          });

          fetchTodayRecords();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error al cambiar el estatus",
            text: error.response?.data?.message || "Error interno",
          });
        }
      }
    });
  };

  const handleChangeTasas = (record) => {
    MySwal.fire({
      title: (
        <h6>
          <i
            className="fa-solid fa-pen-to-square"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Cambia las tasas por Empresa
        </h6>
      ),
      html: `
        <div class="input-group-login2" style="margin-top: 20px;">
          <label for="swal-listin-porcentaje" style="display: block; margin-bottom: 5px;">Precio listín (%)</label>
          <input 
            type="number" 
            id="swal-listin-porcentaje" 
            class="inputDeLogin" 
            style="width: 100%; margin-bottom: 20px;" 
            value="${record.listin_por || record.tipo_terminal_listin_por || ""}" 
            placeholder="Ejemplo: 10" 
            required 
            min="1" 
            max="99" 
            aria-label="Precio listín (%)"
          >
        </div>
        <div class="input-group-login2">
          <label for="swal-tasa-salida-petro" style="display: block; margin-bottom: 5px;">Tasa Salida (Petro)</label>
          <input 
            type="text" 
            id="swal-tasa-salida-petro" 
            class="inputDeLogin" 
            style="width: 100%;" 
            value="${record.tasa_pet || record.tipo_terminal_tasa_pet || ""}" 
            placeholder="Ejemplo: 0.12345678" 
            required 
            pattern="^0\\.[0-9]{8}$" 
            aria-label="Tasa Salida (Petro)"
          >
        </div>
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      preConfirm: () => {
        const listinInput = document.getElementById("swal-listin-porcentaje");
        const tasaPetroInput = document.getElementById(
          "swal-tasa-salida-petro"
        );

        const listinValue = parseInt(listinInput.value, 10);
        const tasaPetroValue = tasaPetroInput.value;

        // Validaciones
        if (!listinInput.checkValidity()) {
          MySwal.showValidationMessage(
            "El campo 'Precio listín (%)' debe ser un número entre 1 y 99."
          );
          return false;
        }

        if (!tasaPetroInput.checkValidity()) {
          MySwal.showValidationMessage(
            "El campo 'Tasa Salida (Petro)' debe tener el formato: 0.12345678."
          );
          return false;
        }

        return {
          listin_porcentaje: listinValue,
          tasa_petro: tasaPetroValue,
        };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { listin_porcentaje, tasa_petro } = result.value;

        // Confirmación adicional
        const confirmChange = await Swal.fire({
          title: "¿Está seguro?",
          text: `Va a cambiar los valores:\n- Listín Porcentaje: ${listin_porcentaje}\n- Tasa Petro: ${tasa_petro}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sí, actualizar",
          cancelButtonText: "Cancelar",
        });

        if (confirmChange.isConfirmed) {
          try {
            // Llamada al backend
            const response = await axios.put(
              `${process.env.BACKEND_URL}/api/edit_empresa/${record.id}/updateTasas`,
              {
                listin_porcentaje,
                tasa_petro,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );

            Swal.fire({
              icon: "success",
              title: response.data.message,
            });

            fetchTodayRecords(); // Actualiza los registros
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Error al actualizar las tasas",
              text: error.response?.data?.message || "Error interno",
            });
          }
        }
      }
    });
  };

  return (
    <div className="container-fluid contarinerGeneralUserHomejs h-auto">
      <div className="container asklnduiashndaijsnxansxsaas6548 m-0 p-0">
        {/* Filtro y Ordenación */}
        <div className="filter-bar p-3 sd56f4df98g4f8g9hb7er89g4ewr89e7r9we4f98ef9we4v4ds2">
          <div
            style={{ display: "flex", alignItems: "center", color: "white" }}
            className="sddv4er98f4we9cw4edivderegis"
          >
            <i
              className="fa-solid fa-magnifying-glass"
              style={{ fontSize: "20px" }}
            ></i>
            <p style={{ margin: "0", marginLeft: "7px", fontSize: "20px" }}>
              Buscar
            </p>
          </div>
          <input
            type="text"
            placeholder="Buscar por rif o nombre de Empresa"
            value={searchTerm}
            onChange={handleSearchChange}
            className="form-control mb-2"
            style={{ borderRadius: "20px", padding: "10px 15px" }}
          />
        </div>
        {loading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <img
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1731494258/1/dvhl0hwbx4adb8uceymr.gif"
              alt="Cargando..."
              style={{ width: "150px" }}
            />
          </div>
        ) : (
          <div className="contianer-fluid p-0 m-0 w-100">
            <div className="container asklnduiashndaijsnxansxsaas mobile-er65g48e9r7we984fwe594 p-0">
              {filteredRecords.map((record) => {
                const statusConfig =
                  EstatusIcon[record.status] || EstatusIcon.INACTIVO;

                return (
                  <div
                    key={record.id}
                    className="container asklnduiashndaijsnxansxsaas"
                  >
                    <div
                      className="container asune-rv56er48v9wec-s2 mgjmnh98-sdf9w8e7f9wewefwes"
                      style={{ height: "auto" }}
                    >
                      <div
                        className="container aoshdnuqiwhnd897"
                        style={{ flexDirection: "column" }}
                      >
                        <div
                          className="container ds897fq4w9dc9w8c4-we89c7w2 w-100"
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                                fontSize: "10px",
                              }}
                              id="pderegistros"
                            >
                              {record.empresa.rif} -{" "}
                              <strong>{record.taquilla}</strong>
                            </p>
                            <p
                              style={{
                                margin: "0",
                                fontSize: "12px",
                              }}
                              id="pderegistros"
                            >
                              {record.email
                                ? record.email
                                : "No tiene correo asignado"}
                            </p>
                          </div>
                          <h5 style={{ marginTop: "15px" }}>
                            {record.empresa.nombre}
                          </h5>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>
                            {!record.listin_por && !record.tasa_pet ? (
                              <span
                                className="btn-success"
                                style={{
                                  padding: "4px",
                                  borderRadius: "5px",
                                }}
                              >
                                DEFAULT
                              </span>
                            ) : (
                              <table
                                style={{ fontSize: "10px", margin: "0" }}
                                className="custom-table rounded-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4"
                              >
                                <thead>
                                  <tr>
                                    <th style={{ padding: "4px" }}>Listín</th>
                                    <th style={{ padding: "4px" }}>Salida</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{ padding: "4px" }}>
                                      {record.listin_por}%
                                    </td>
                                    <td style={{ padding: "4px" }}>
                                      {record.tasa_pet}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </div>
                          <div
                            className="container ds9f848dsfwef498we4fwd-57982 w-20"
                            style={{
                              width: "100%",
                              height: "100% !important",
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            <button
                              className={`btn d-flex`}
                              style={{
                                color: "white",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                                backgroundImage:
                                  "linear-gradient(to right, #ff6347, #ff856f)",
                                maxHeight: "29px",
                              }}
                            >
                              {record.avisos_de_cobro_pendientes}
                            </button>
                            <button
                              className={`btn d-flex btn-${record.status === "ACTIVE" ? "success" : "danger"}`}
                              onClick={() =>
                                handleUpdateStatus(record.id, record.status)
                              }
                              style={{
                                color: "white",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className={`fa-solid fa-lock${record.status === "ACTIVE" ? "-open" : ""}`}
                                style={{ fontSize: "17px" }}
                              ></i>
                            </button>
                            <button
                              className="btn btn-info d-flex"
                              onClick={() => handleChangeStatus(record)}
                              style={{
                                color: "white",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa-solid fa-envelope"
                                style={{ fontSize: "17px" }}
                              ></i>
                            </button>
                            <button
                              className="btn btn-warning d-flex"
                              onClick={() => handleChangeTasas(record)}
                              style={{
                                color: "white",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa-solid fa-percent"
                                style={{ fontSize: "17px" }}
                              ></i>
                            </button>
                            <button
                              className="btn btn-secondary d-flex"
                              onClick={() =>
                                showEmpresaAvisosPending(
                                  record.id,
                                  record.empresa.nombre,
                                  formatDate,
                                  record.empresa.rif
                                )
                              }
                              style={{
                                color: "white",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa-solid fa-file-invoice"
                                style={{ fontSize: "17px" }}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Vista Escritorio::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::.. */}
            <div className="custom-table-container desktop-er65g48e9r7we984fwe594 m-0">
              <table className="custom-table rounded-table">
                <thead>
                  <tr>
                    <th className="table-header">Taquilla</th>
                    <th className="table-header">Empresa</th>
                    <th className="table-header">RIF</th>
                    <th className="table-header">Correo</th>
                    <th className="table-header">Tasa</th>
                    <th className="table-header">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecords.map((record) => (
                    <tr key={record.id} className="table-row">
                      <td className="table-cell">{record.taquilla}</td>
                      <td className="table-cell">{record.empresa.nombre}</td>
                      <td className="table-cell">{record.empresa.rif}</td>
                      <td className="table-cell">
                        {record.email ? record.email : "No"}
                      </td>
                      <td className="table-cell">
                        {!record.listin_por && !record.tasa_pet ? (
                          <span
                            className="btn-success"
                            style={{
                              padding: "4px",
                              borderRadius: "5px",
                            }}
                          >
                            DEFAULT
                          </span>
                        ) : (
                          <table
                            style={{ fontSize: "10px", margin: "0" }}
                            className="custom-table rounded-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4"
                          >
                            <thead>
                              <tr>
                                <th style={{ padding: "4px" }}>Listín</th>
                                <th style={{ padding: "4px" }}>Salida</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ padding: "4px" }}>
                                  {record.listin_por}%
                                </td>
                                <td style={{ padding: "4px" }}>
                                  {record.tasa_pet}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </td>
                      <td className="table-cell d-flex w-100">
                        <button
                          className={`btn d-flex`}
                          style={{
                            color: "white",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                            backgroundImage:
                              "linear-gradient(to right, #ff6347, #ff856f)",
                            maxHeight: "29px",
                          }}
                        >
                          {record.avisos_de_cobro_pendientes}
                        </button>
                        <button
                          className={`btn d-flex btn-${record.status === "ACTIVE" ? "success" : "danger"}`}
                          onClick={() =>
                            handleUpdateStatus(record.id, record.status)
                          }
                          style={{
                            color: "white",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <i
                            className={`fa-solid fa-lock${record.status === "ACTIVE" ? "-open" : ""}`}
                            style={{ fontSize: "17px" }}
                          ></i>
                        </button>
                        <button
                          className="btn btn-info d-flex"
                          onClick={() => handleChangeStatus(record)}
                          style={{
                            color: "white",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <i
                            className="fa-solid fa-envelope"
                            style={{ fontSize: "17px" }}
                          ></i>
                        </button>
                        <button
                          className="btn btn-warning d-flex"
                          onClick={() => handleChangeTasas(record)}
                          style={{
                            color: "white",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <i
                            className="fa-solid fa-percent"
                            style={{ fontSize: "17px" }}
                          ></i>
                        </button>
                        <button
                          className="btn btn-secondary d-flex"
                          onClick={() =>
                            showEmpresaAvisosPending(
                              record.id,
                              record.empresa.nombre,
                              formatDate,
                              record.empresa.rif
                            )
                          }
                          style={{
                            color: "white",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-invoice"
                            style={{ fontSize: "17px" }}
                          ></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
