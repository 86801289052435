// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ds897fq4w9dc9w8c4-we89c7w {
  text-align: start;
}
.s5d4fe88t7s51f8s4dsdvdre {
  position: relative;
}
.ds54f87er8f94er6ge8r94g8we9f4we897er {
  position: absolute;
}
`, "",{"version":3,"sources":["webpack://./src/front/styles/RegistroActivacionesHoy.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".ds897fq4w9dc9w8c4-we89c7w {\n  text-align: start;\n}\n.s5d4fe88t7s51f8s4dsdvdre {\n  position: relative;\n}\n.ds54f87er8f94er6ge8r94g8we9f4we897er {\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
