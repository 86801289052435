import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../store/appContext";
import { Link, Navigate, useLocation } from "react-router-dom";
import "../../../styles/companyAdminNavbarSuperior.css";
import axios from "axios";
import { motion } from "framer-motion";

export const Navbar_sup = () => {
  const { store, actions } = useContext(Context);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    greeting: "",
  });
  const location = useLocation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = currentDateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const formattedDate = currentDateTime.toLocaleDateString();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.BACKEND_URL}/api/protected`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          greeting: response.data.greeting,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          alert("Su sesión ha expirado, por favor vuelva a iniciar sesión");
          window.location.reload();
        } else {
          console.log("Axios error:", error.message);
        }
      }
    };

    fetchUserData();
  }, [store.token]);

  const handleClick = (menu) => {
    setSelectedMenu(menu);
  };

  const sidebarVariants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        opacity: { duration: 0.3 },
        x: { type: "ease", stiffness: 100 },
      },
    },
    closed: {
      opacity: 0,
      x: "-100%",
      transition: {
        opacity: { duration: 0.3 },
        x: { type: "ease", stiffness: 100 },
      },
    },
  };

  useEffect(() => {
    const pathname = location.pathname;

    const menuMapping = {
      "": {
        icon: "fa-globe",
        name: "Inicio",
        text: "Explora tus estadísticas y mucho más",
      },
      "/": {
        icon: "fa-globe",
        name: "Inicio",
        text: "Explora tus estadísticas y mucho más",
      },
      "/RegistroCps": {
        icon: "fa-file-circle-plus",
        name: "Registro CPS",
        text: "Registra tus CPS aquí",
      },
      "/EmpresasDeTransporte": {
        icon: "fa-user-tie",
        name: "Empresas de Transporte",
        text: "Gestiona las empresas de transporte",
      },
      "/Configuraciones": {
        icon: "fa-bus",
        name: "Configuraciones",
        text: "Configura tu experiencia",
      },
      "/RegistroUsuarios": {
        icon: "fa-users",
        name: "Registro Usuarios",
        text: "Gestiona los usuarios",
      },
      "/Destinos": {
        icon: "fa-location-dot",
        name: "Destinos",
        text: "Gestiona los destinos",
      },
      "/Programacion": {
        icon: "fa-calendar-check",
        name: "Programación",
        text: "Gestiona la programación",
      },
      "/ActivacionParaBillBoard": {
        icon: "fa-bullhorn",
        name: "Terminal en Vivo",
        text: "Gestiona y Registra tus activaciones, y disfruta de una administración con tu Terminal en Vivo",
      },
      "/ActivacionCalendario": {
        icon: "fa-calendar-days",
        name: "Calendario de Salidas",
        text: "Gestiona el calendario de salidas",
      },
      "/PrecioDestinoFinalRL": {
        icon: "fa-tags",
        name: "Rutas Largas Precios",
        text: "Lleva el control del precio de la cobranza por Destino Final",
      },
      "/PrecioDestinoFinalRC": {
        icon: "fa-tags",
        name: "Rutas Cortas Precios",
        text: "Lleva el control del precio de la cobranza por Destino Final",
      },
      "/KmDistancia": {
        icon: "fa-route",
        name: "Destino Final Km",
        text: "Establece a cuantos km de distancia está cada destino",
      },
      "/Autorizacionporpuesto": {
        icon: "fa-road-circle-check",
        name: "Autorización Por Puesto",
        text: "Autoriza Rutas a Salir como Rutas cortas Por Puesto",
      },
      "/RegistrosCpsEdit": {
        icon: "fa-list-check",
        name: "Listado de CPS",
        text: "Aquí podrás visualizas todas las CPS que has registrado hasta el momento, pudiedo así modificar estatus",
      },
      "/CierresRutasCortas": {
        icon: "fa-bus",
        name: "Cierres Rutas Cortas",
        text: "Verifica y controla los cierres de todos tus taquilleros de Rutas Cortas",
      },
      "/LineasTransporte": {
        icon: "fa-user-tie",
        name: "Líneas de Transporte",
        text: "Sasha Tech registra cada empresa de transporte para que puedan usarla en cada terminal.",
      },
      "/Terminales": {
        icon: "fa-school",
        name: "Terminales",
        text: "Cada Terminal que esté en nuestro sistema es Registrado",
      },
      "/RegistroCpsGeneral": {
        icon: "fa-rectangle-list",
        name: "CPS Empresas",
        text: "Registra de forma general, el código de cada CPS que tenga una empresa.",
      },
      "/DestinoFinal": {
        icon: "fa-route",
        name: "Destino Final",
        text: "Registra de manera única los diferentes Destinos Finales en Toda Venezuela de los buses",
      },
      "/TipoTerminal": {
        icon: "fa-font-awesome",
        name: "Tipo Terminal",
        text: "Divide los terminales en categorías según sus características.",
      },
      "/RegistroTasa": {
        icon: "fa-dollar-sign",
        name: "Registro Tasa",
        text: "Registra la tasa del dolar diario segun el BCV.",
      },
    };

    const menu = menuMapping[pathname] || menuMapping["/"];
    if (menu) {
      setSelectedMenu(menu);
    }
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.reload();
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="containerCompanyAdminNavbarSuperior">
      <div className="s56d4as897jk9hy7t98q4wf9r8gt7rwe98">
        <div>
          <div className="a65s48t7yrt977675695847f983273">
            <div className="s56d4asd6897asd987ff98e79w8e7fwe98icon23423">
              <i className={`fa-solid ${selectedMenu?.icon}`}></i>
            </div>
            <div className="d6f5s4df89sd798er7wf98we7fwe98d4qw64">
              <div className="q6oui4uyt89red7we987e9ty8u7tyrt9ere8d7c98">
                {selectedMenu?.name}
              </div>
              <div className="sdf65sd4f89t7r9er8we7w98qe7r9r8e7wqijm">
                {selectedMenu?.text}
              </div>
            </div>
          </div>

          <i
            className="fa-solid fa-bars qpwjaisodnasdasdalsdaspqowm"
            onClick={handleMenuToggle}
          ></i>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <p
              className="pNavbarSuperior"
              style={{
                display: "flex",
                justifyContent: "end",
                color: "rgb(33, 33, 39)",
                textAlign: "end",
              }}
            >
              Buenas, {user.firstName}
            </p>
            <p
              className="pNavbarSuperior"
              style={{
                display: "flex",
                justifyContent: "end",
                color: "rgb(33, 33, 39)",
                textAlign: "end",
              }}
            >
              Bienvenido
            </p>
          </div>

          <div
            style={{
              width: "40px",
              height: "40px",
              border: "solid 0.1px #006AA1",
              borderRadius: "50%",
              marginLeft: "20px",
              backgroundSize: "130%",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "22px",
              backgroundColor: "#22C4B940",
            }}
          >
            {user?.firstName?.charAt()}.
          </div>
        </div>
        {menuOpen && (
          <div
            className={`menu-overlay ${menuOpen ? "menu-open" : ""}`}
            onClick={handleMenuToggle}
          >
            <div
              className={`menu-content ${menuOpen ? "menu-content-open" : ""}`}
            >
              <div className="as56d4s9f87er98fwe4fwr987fwee98d4qw987">
                <img
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1713119110/INTRAVIALCA/truxqodtlg1vzishxhot.png"
                  alt="Logo"
                  style={{ height: "45px" }}
                  className="d65f4sd9f8w7ef98we7qw654er987fw"
                />
                <div onClick={handleMenuToggle}>
                  <i
                    style={{ fontSize: "30px", color: "white" }}
                    className="fa-regular fa-circle-xmark icon-98716598"
                  ></i>
                </div>
              </div>
              <div className="asdw-w9d84c9w8e4dcd2"></div>
              <div className="louqjwnedjsdas">
                <div className="sdsdf98u7yt9htg7wfqe987879467434">
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "Inicio" ? "selected-menu" : ""
                    }`}
                    to="/"
                    onClick={() => handleClick("/")}
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-globe"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Inicio
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "Registro Tasa"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("RegistroTasa")}
                    to="/RegistroTasa"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-dollar-sign m-0"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Registro Tasa
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "Terminales" ? "selected-menu" : ""
                    }`}
                    onClick={() => handleClick("Terminales")}
                    to="/Terminales"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-school"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Terminales
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "Líneas de Transporte"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("LineasTransporte")}
                    to="/LineasTransporte"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-user-tie"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Líneas de Transporte
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "CPS Empresas"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("RegistroCpsGeneral")}
                    to="/RegistroCpsGeneral"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-rectangle-list"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      CPS Empresas
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "Tipo Terminal"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("TipoTerminal")}
                    to="/TipoTerminal"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-font-awesome"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Tipo Terminal
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "Destino Final"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("DestinoFinal")}
                    to="/DestinoFinal"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-route"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Destino Final
                    </div>
                  </Link>
                </div>
                <div
                  className="as65ad4s8d9a7s8d9as4d9reg49wq8ew4v641w"
                  style={{ marginBottom: "15px" }}
                >
                  <Link
                    className={`subtitulosMenuUserHomeCompany btn b65d4fs8d9f7q98wd4qw98d7q8w9d4q ${
                      selectedMenu === "Configuraciones" ? "selected-menu" : ""
                    }`}
                    onClick={() => handleClick("Configuraciones")}
                    to="/Configuraciones"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-gear"
                      style={{ color: "#151b26" }}
                    ></i>
                    <div style={{ color: "#151b26" }}>Configuraciones</div>
                  </Link>
                </div>
                <div
                  className="as65ad4s8d9a7s8d9as4d9reg49wq8ew4v641w"
                  style={{ height: "0px" }}
                >
                  <button
                    className="btn b65d4fs8d9f7q98wd4qw98d7q8w9d4q w-100"
                    onClick={handleLogout}
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-right-from-bracket"
                      style={{ color: "#151b26" }}
                    ></i>
                    <div
                      href="#"
                      style={{
                        color: "#151b26",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      Cerrar sesión
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
