import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useLocation } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const RegistroDePago2 = () => {
  const { store } = useContext(Context);
  const { state } = useLocation();
  const [reporte, setReporte] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [formData, setFormData] = useState({
    empresa_terminal_id: "",
    empresa: "",
  });
  const { empresaId, empresaName } = state || {};
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleOptionClick = async (option) => {
    if (option === "PAGAR_TODOS") {
      try {
        // Mostrar ventana emergente de carga
        const loadingSwal = MySwal.fire({
          title: "Espere, cargando avisos...",
          html: `
            <div style="text-align: center;">
              <i class="fa-solid fa-spinner fa-spin-pulse" style="font-size: 24px; color: #007bff;"></i>
              <p style="font-size: 14px;">Redirigiendo a la vista de pagos...</p>
            </div>
          `,
          allowOutsideClick: false,
          showConfirmButton: false,
          willClose: () => {
            // Este código se ejecuta justo antes de cerrar la ventana emergente
            MySwal.showLoading(); // Mostrar indicador de carga
          },
        });

        // Llamada a la API para obtener los avisos
        const response = await axios.get(
          `${process.env.BACKEND_URL}/api/get_avisos_cobro_pending/${empresaId}`,
          {
            headers: { Authorization: `Bearer ${store.token}` },
          }
        );

        // Mapeo de avisos
        const avisos = (response.data.avisos || []).map((aviso) => ({
          id: aviso.id,
          total_bolivares: aviso.total_bolivares,
          total_dolares: parseFloat(
            (aviso.total_bolivares / aviso.valor_dolar).toFixed(2)
          ),
          listin_terminal: aviso.listines.n_listin_terminal,
          fecha_viaje: formatDate(aviso.listines.activation.fecha_de_viaje),
          valor_tasa: aviso.valor_dolar,
          destino_final:
            aviso.listines.activation.ruta.destino_final.destino_final_detalle
              .descripcion,
          numero_de_unidad:
            aviso.listines.activation?.numero_de_unidad || "No disponible",
        }));

        if (avisos.length === 0) {
          // Mostrar mensaje si no hay avisos
          MySwal.fire({
            title: "Sin avisos pendientes",
            text: "No tienes avisos de cobro pendientes.",
            icon: "info",
          });
          return; // Detener flujo si no hay avisos
        }

        console.log(avisos);

        // Cerrar la ventana emergente antes de redirigir
        loadingSwal.close();

        // Redirigir al usuario
        navigate("/RegistroPago3", {
          state: { empresaId, empresaName, avisos },
        });
      } catch (error) {
        console.error("Error al cargar los avisos:", error);

        // Mostrar mensaje de error en caso de fallo
        MySwal.fire({
          title: "Error",
          text: "No se pudieron cargar los avisos para el pago.",
          icon: "error",
        });
      }
    } else if (option === "SELECCIONAR_AVISOS") {
      let totalBolivares = 0;
      let totalDolares = 0;

      MySwal.fire({
        title: "Seleccionar Avisos de Cobro",
        html: `
          <div style="margin-bottom: 10px; font-size: 14px;">
            <strong>Total en Bolívares:</strong> <span id="totalBolivares">0.00</span> Bs.<br />
            <strong>Total en Dólares:</strong> <span id="totalDolares">0.00</span> $.
          </div>
          <div id="loadingAvisos" style="text-align: center; margin: 20px 0;">
            <i class="fa-solid fa-spinner fa-spin-pulse" style="font-size: 24px; color: #007bff;"></i>
            <p style="font-size: 14px;">Cargando avisos...</p>
          </div>
          <table class="custom-table rounded-table" style="width: 100%; min-width: 500px; display: none;" id="avisosTableContainer">
            <thead>
              <tr>
                <th class="table-header" style="font-size: 11px;"><input type="checkbox" id="selectAll" /></th>
                <th class="table-header" style="font-size: 11px;">N° Listín</th>
                <th class="table-header" style="font-size: 11px;">Fecha Viaje</th>
                <th class="table-header" style="font-size: 11px;">Valor Tasa</th>
                <th class="table-header" style="font-size: 11px;">Total (Bs)</th>
                <th class="table-header" style="font-size: 11px;">Total ($)</th>
              </tr>
            </thead>
            <tbody id="avisosTable">
              <!-- Aquí se inyectan los datos -->
            </tbody>
          </table>
        `,
        showCancelButton: true,
        confirmButtonText: "Continuar",
        preConfirm: () => {
          const selectedAvisos = Array.from(
            document.querySelectorAll("input[name='aviso']:checked")
          ).map((checkbox) => {
            return {
              id: checkbox.value,
              total_bolivares: parseFloat(
                checkbox.getAttribute("data-bolivares")
              ),
              total_dolares: parseFloat(checkbox.getAttribute("data-dolares")),
              listin_terminal: checkbox.closest("tr").children[1].textContent,
              fecha_viaje: checkbox.closest("tr").children[2].textContent,
              valor_tasa: parseFloat(
                checkbox.closest("tr").children[3].textContent
              ),
              numero_de_unidad: checkbox.getAttribute("data-unidad"),
              destino_final: checkbox.getAttribute("data-destino"),
            };
          });
          return selectedAvisos;
        },

        didOpen: async () => {
          const loadingElement = document.getElementById("loadingAvisos");
          const tableContainer = document.getElementById(
            "avisosTableContainer"
          );
          try {
            const response = await axios.get(
              process.env.BACKEND_URL +
                `/api/get_avisos_cobro_pending/${empresaId}`,
              {
                headers: { Authorization: `Bearer ${store.token}` },
              }
            );
            const avisos = response.data.avisos;
            const tbody = document.getElementById("avisosTable");

            if (avisos.length === 0) {
              // Mostrar mensaje si no hay avisos
              MySwal.fire({
                title: "Sin avisos pendientes",
                text: "No tienes avisos de cobro pendientes.",
                icon: "info",
              });
              return; // Detener flujo si no hay avisos
            }

            tbody.innerHTML = avisos
              .map(
                (aviso) => `
                <tr>
                  <td class="table-cell" style="font-size: 11px;">
                    <input type="checkbox" name="aviso" value="${aviso.id}" 
                      data-bolivares="${aviso.total_bolivares}" 
                      data-dolares="${(aviso.total_bolivares / aviso.valor_dolar).toFixed(2)}" 
                      data-unidad="${aviso.listines.activation?.numero_de_unidad || "No disponible"}" 
                      data-destino="${aviso.listines.activation?.ruta?.destino_final?.destino_final_detalle?.descripcion || "No disponible"}" />
                  </td>
                  <td class="table-cell" style="font-size: 11px;">${aviso.listines.n_listin_terminal}</td>
                  <td class="table-cell" style="font-size: 11px;">${formatDate(aviso.listines.activation.fecha_de_viaje)}</td>
                  <td class="table-cell" style="font-size: 11px;">${aviso.valor_dolar}</td>
                  <td class="table-cell" style="font-size: 11px;">${aviso.total_bolivares} Bs.</td>
                  <td class="table-cell" style="font-size: 11px;">${(aviso.total_bolivares / aviso.valor_dolar).toFixed(2)} $.</td>
                </tr>`
              )
              .join("");

            loadingElement.style.display = "none";
            tableContainer.style.display = "table";

            const checkboxes = document.querySelectorAll("input[name='aviso']");
            const totalBolivaresElement =
              document.getElementById("totalBolivares");
            const totalDolaresElement = document.getElementById("totalDolares");

            checkboxes.forEach((checkbox) => {
              checkbox.addEventListener("change", (e) => {
                const bolivares = parseFloat(
                  e.target.getAttribute("data-bolivares")
                );
                const dolares = parseFloat(
                  e.target.getAttribute("data-dolares")
                );

                if (e.target.checked) {
                  totalBolivares += bolivares;
                  totalDolares += dolares;
                } else {
                  totalBolivares -= bolivares;
                  totalDolares -= dolares;
                }

                totalBolivaresElement.textContent = totalBolivares.toFixed(2);
                totalDolaresElement.textContent = totalDolares.toFixed(2);
              });
            });

            const selectAllCheckbox = document.getElementById("selectAll");
            selectAllCheckbox.addEventListener("change", (e) => {
              // Reinicia los totales antes de recalcular
              totalBolivares = 0;
              totalDolares = 0;

              checkboxes.forEach((checkbox) => {
                checkbox.checked = e.target.checked;

                const bolivares = parseFloat(
                  checkbox.getAttribute("data-bolivares")
                );
                const dolares = parseFloat(
                  checkbox.getAttribute("data-dolares")
                );

                if (e.target.checked) {
                  totalBolivares += bolivares;
                  totalDolares += dolares;
                }
              });

              totalBolivaresElement.textContent = totalBolivares.toFixed(2);
              totalDolaresElement.textContent = totalDolares.toFixed(2);
            });
          } catch (error) {
            console.error("Error al cargar los avisos:", error);
            MySwal.fire({
              title: "Error",
              text: "No se pudieron cargar los avisos.",
              icon: "error",
            });
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/RegistroPago3", {
            state: { empresaId, empresaName, avisos: result.value },
          });
        }
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Evita que el formulario envíe datos y recargue la página
    setIsSubmitting(true);

    // Aquí puedes manejar el envío del formulario si es necesario
    console.log("Enviando formulario...");
    setTimeout(() => setIsSubmitting(false), 2000); // Simula un retraso
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-2"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Selecciona una opción.</p>
        </div>
        <div className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100">
          <div
            className="container asklnduiashndaijsnxansxsaas3"
            style={{ marginBottom: "20px" }}
          >
            <form
              className="formularioDeLogin s6d54fsd89f7sd98f4we98f7wr89 w-100"
              onSubmit={handleSubmit} // Prevenir recarga
            >
              <div>
                <h2 style={{ fontSize: "15px", textAlign: "center" }}>
                  Opciones de Pago para {empresaName}
                </h2>
                <div className="aoshduiahsdkasnmdasdas">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleOptionClick("PAGAR_TODOS")}
                  >
                    Pagar Todos los Avisos
                  </button>
                  <button
                    type="button"
                    className="btn"
                    style={{ backgroundColor: "#24827c", color: "white" }}
                    onClick={() => handleOptionClick("SELECCIONAR_AVISOS")}
                  >
                    Seleccionar Avisos
                  </button>
                </div>
              </div>
            </form>
            <hr style={{ width: "90%" }}></hr>
            <Link
              to="/RegistroPago"
              className="btn btn-secondary"
              style={{ color: "white" }}
            >
              Volver
            </Link>
          </div>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
        <hr style={{ width: "90%" }}></hr>
      </div>
    </div>
  );
};
