import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Context } from "../../store/appContext";
import { Link, useNavigate } from "react-router-dom";
import "../../../styles/UserHome.css";
import Chart from "chart.js/auto";
import ApexCharts from "react-apexcharts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const UserHome_activacion = () => {
  const { actions, store } = useContext(Context);
  const navigate = useNavigate();
  const [usdRate, setUsdRate] = useState(null);

  // Nuevas

  const [totalActivaciones, setTotalActivaciones] = useState([]);
  const [activactionesCanceladas, setActivacionesCanceladas] = useState([]);
  const [activacionesSalieron, setActivacionesSalieron] = useState([]);
  const [totalActivacionesChart, setTotalActivacionesChart] = useState([]);

  const newsData = [
    {
      title: "¡Nueva actualización!",
      content:
        "Hemos lanzado una emocionante nueva actualización. ¡Descúbrelo ahora!",
    },
    {
      title: "Conéctate con nosotros",
      content:
        "Estamos emocionados de conectarnos contigo. Mantente actualizado con nuestras últimas noticias y características.",
    },
  ];

  const maxLines = 3;

  const areaChartOptions2 = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: "white",
        },
      },
      type: "category",
      categories: totalActivacionesChart.map((data) => data.date),
    },
    yaxis: {
      title: {
        text: "",
        style: {
          color: "#ffffff",
        },
      },
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
      lines: Array.from({ length: maxLines }, (_, i) => ({
        value: i + 1, // Ajusta los valores de referencia según tus necesidades
        borderColor: "#ffffff",
        label: {
          borderColor: "#ffffff",
          style: {
            color: "#ffffff",
          },
          text: `Línea ${i + 1}`,
        },
      })),
    },
    colors: ["#151b26", "#151b26"],
    fill: {
      opacity: 0.7,
    },
    legend: {
      labels: {
        colors: "#ffffff",
      },
    },
  };

  const areaChartOptions = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: "white",
        },
      },
      type: "category",
      categories: totalActivacionesChart.map((data) => data.date),
    },
    yaxis: {
      title: {
        text: "",
        style: {
          color: "#ffffff",
        },
      },
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
      lines: Array.from({ length: maxLines }, (_, i) => ({
        value: i + 1, // Ajusta los valores de referencia según tus necesidades
        borderColor: "#ffffff",
        label: {
          borderColor: "#ffffff",
          style: {
            color: "#ffffff",
          },
          text: `Línea ${i + 1}`,
        },
      })),
    },
    colors: ["#151b26", "#151b26"],
    fill: {
      opacity: 0.7,
    },
    legend: {
      labels: {
        colors: "#ffffff",
      },
    },
  };

  const areaChartSeries = [
    {
      name: "Total Activaciones",
      data: totalActivacionesChart.map((data) => data.total_activations),
    },
  ];

  const areaChartSeries2 = [
    {
      name: "Total Cancelados",
      data: totalActivacionesChart.map((data) => data.canceled_activations),
    },
  ];

  useEffect(() => {
    obtenerTasaDeCambio();
    fetchMovements();
  }, []);

  const obtenerTasaDeCambio = async () => {
    try {
      const respuesta = await fetch(
        "https://ve.dolarapi.com/v1/dolares/oficial"
      );

      // Convertir la respuesta a JSON
      const datos = await respuesta.json(); // Aquí se agrega await para obtener los datos en formato JSON

      if (datos.promedio) {
        // Limitar a dos decimales y reemplazar el punto por una coma
        const tasaFormateada = datos.promedio
          .toFixed(2)
          .toString()
          .replace(".", ",");
        setUsdRate(tasaFormateada);
      } else {
        console.error(
          "La propiedad promedio no está presente en la respuesta del servidor."
        );
      }
    } catch (error) {
      console.error("Error al obtener la tasa de cambio:", error);
    }
  };

  const fetchMovements = async () => {
    // Ventana mientras se cargan los datos
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Datos de la página principal...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/activations/today-summary",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      setTotalActivaciones(data.summary_totals.total_activations_sum);
      setActivacionesCanceladas(data.summary_totals.canceled_activations_sum);
      setActivacionesSalieron(data.summary_totals.salio_activations_sum);
      setTotalActivacionesChart(data.activations_data);

      MySwal.close();
    } catch (error) {
      console.error("Error al obtener Categorías", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener categorías. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ flexDirection: "column" }}
    >
      <div
        className="container suiahdisodm-we98f4wec"
        style={{
          paddingRight: "0",
          paddingLeft: "0",
        }}
      >
        <div className="container d-flex p-0 s98d7fa1sd9c8a4sc9as4ca-s5ca4s89s">
          <div className="container asune-rv56er48v9wec-s">
            <div className="container aoshdnuqiwhnd897">
              <div className="container ds9f848dsfwef498we4fwd-5798">
                <div className="sad8qw9d4asas6x4a89s42">
                  <i className="fa-solid asd98asdas894xsa fa-check"></i>
                </div>
              </div>
              <div className="container ds897fq4w9dc9w8c4-we89c7w">
                <p style={{ margin: "0" }}>Acivaciones Hoy</p>
                <h5 style={{ color: "green" }}>
                  {/* {pointsData !== null ? `${pointsData} pts` : "Muy Pronto..."} */}
                  {totalActivaciones}
                </h5>
              </div>
            </div>
            <div className="asdw-w9d84c9w8e4dcd"></div>
            <div className="container aoshdnuqiwhnd8972">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ margin: "0" }} className="asd98as79d4sd98as4x">
                  <strong>+0</strong>
                </p>
                <p style={{ margin: "0", marginLeft: "7px" }}> </p>
              </div>
            </div>
          </div>
          <div className="container asune-rv56er48v9wec-s mgjmnh98-sdf9w8e7f9wewefwes">
            <div className="container aoshdnuqiwhnd897 ">
              <div className="container ds9f848dsfwef498we4fwd-5798">
                <div className="sad8qw9d4asas6x4a89s42">
                  <i className="fa-solid asd98asdas894xsa fa-ban"></i>
                </div>
              </div>
              <div className="container ds897fq4w9dc9w8c4-we89c7w">
                <p style={{ margin: "0" }}>Cancelados Hoy</p>
                <h5 style={{ color: "red" }}>{activactionesCanceladas}</h5>
              </div>
            </div>
            <div className="asdw-w9d84c9w8e4dcd"></div>
            <div className="container aoshdnuqiwhnd8972">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ margin: "0" }} className="asd98as79d4sd98as4x">
                  <strong>+0</strong>
                </p>
                <p style={{ margin: "0", marginLeft: "7px" }}></p>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex as98d798qwd465qe-ew9f8qw8e9d s98d7fa1sd9c8a4sc9as4ca-s5ca4s89s">
          <div className="container asune-rv56er48v9wec-s ">
            <div className="container aoshdnuqiwhnd897">
              <div className="container ds9f848dsfwef498we4fwd-5798">
                <div className="sad8qw9d4asas6x4a89s42">
                  <i className="fa-solid asd98asdas894xsa fa-dollar-sign"></i>
                </div>
              </div>
              <div className="container ds897fq4w9dc9w8c4-we89c7w">
                <p style={{ margin: "0" }}>Dolar BCV</p>
                <h5 style={{ color: "black" }}>{usdRate} Bs</h5>
              </div>
            </div>
            <div className="asdw-w9d84c9w8e4dcd"></div>
            <div className="container aoshdnuqiwhnd8972">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p
                  style={{ margin: "0", color: "red" }}
                  className="asd98as79d4sd98as4x"
                >
                  <strong>+0</strong>
                </p>
                <p style={{ margin: "0", marginLeft: "7px" }}></p>
              </div>
            </div>
          </div>
          <div className="container asune-rv56er48v9wec-s mgjmnh98-sdf9w8e7f9wewefwes">
            <div className="container aoshdnuqiwhnd897">
              <div className="container ds9f848dsfwef498we4fwd-5798">
                <div className="sad8qw9d4asas6x4a89s42">
                  <i className="fa-solid asd98asdas894xsa fa-calendar-days"></i>
                </div>
              </div>
              <div className="container ds897fq4w9dc9w8c4-we89c7w">
                <p style={{ margin: "0" }}>Salieron</p>
                <h5 style={{ color: "black" }}>{activacionesSalieron}</h5>
              </div>
            </div>
            <div className="asdw-w9d84c9w8e4dcd"></div>
            <div className="container aoshdnuqiwhnd8972">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ margin: "0" }} className="asd98as79d4sd98as4x">
                  <strong>+0</strong>
                </p>
                <p style={{ margin: "0", marginLeft: "7px" }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container containerDeChartsUserHome">
        <div className="container asdasc987asc8a98dsc4sdv8e-v65sd4">
          <div className="asodhnoiuashnxiuasnx1">
            <ApexCharts
              options={areaChartOptions}
              series={areaChartSeries}
              type="area"
              height="100%"
              width="100%"
            />
          </div>

          <p className="letrasPequenasEnUserHomeDetalles">
            Últimos 7 días de Activaciones
          </p>
        </div>
        <div className="container asdasc987asc8a98dsc4sdv8e-v65sd42">
          <div className="asodhnoiuashnxiuasnx2">
            <ApexCharts
              options={areaChartOptions2}
              series={areaChartSeries2}
              type="area"
              height="100%"
              width="100%"
            />
          </div>
          <p className="letrasPequenasEnUserHomeDetalles">
            Últimos 7 días de Cancelaciones
          </p>
        </div>
      </div>
      <div className="container containerDeChartsUserHome2">
        <div className="container ohasoidjqw-d48w9d8as7">
          <h5 style={{ textAlign: "center", color: "gray" }}>
            Últimos 7 días (Total Activaciones y Cancelaciones)
          </h5>
          <table className="custom-table rounded-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4">
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th className="table-header" style={{ textAlign: "center" }}>
                  Fecha
                </th>
                <th className="table-header" style={{ textAlign: "center" }}>
                  Total Activaciones
                </th>
                <th className="table-header" style={{ textAlign: "center" }}>
                  Total Cancelaciones
                </th>
              </tr>
            </thead>
            <tbody>
              {totalActivacionesChart.map((data) => (
                <tr key={data.date}>
                  <td className="table-cell" style={{ textAlign: "center" }}>
                    {data.date}
                  </td>
                  <td className="table-cell" style={{ textAlign: "center" }}>
                    {data.total_activations}
                  </td>
                  <td className="table-cell" style={{ textAlign: "center" }}>
                    {data.canceled_activations}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container ohasoidjqw-d48w9d8as72">
          <ul className="list-group">
            {newsData.map((news, index) => (
              <li key={index} className="list-group-item">
                <div className="accordion" id={`newsAccordion${index}`}>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`collapse${index}`}
                      >
                        {news.title}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${index}`}
                      data-bs-parent={`#newsAccordion${index}`}
                    >
                      <div className="accordion-body">{news.content}</div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
