import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "../../../../styles/SolicitudActivaciones.css";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const ActivacionNuevoRegistro = () => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    contingencia_dt: false,
    contingencia_ruta: false,
    empresa_terminal_id: "",
    empresa: "",
    rif: "",
    ruta_id: "",
    ruta: "",
    dt_id: "",
    dt: "",
    fecha_de_viaje: "",
    hora_de_salida: "",
    destino_final: "",
    numero_de_unidad: "",
    puerta: "",
    parada: "",
  });
  const [cpsData, setCpsData] = useState([]); // Data completa del CPS
  const [empresas, setEmpresas] = useState([]);
  const [filteredParadas, setFilteredParadas] = useState([]); // Paradas filtradas por empresa
  const [filteredRutas, setFilteredRutas] = useState([]); // Rutas filtradas por parada
  const [referencia, setReferencia] = useState([]); // Referencias filtradas por parada
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [records, setRecords] = useState([]);
  const [filteredHorarios, setFilteredHorarios] = useState([]);

  const showRouteDetails = async (id) => {
    // Mostrar ventana de carga
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Rutas de la Empresa Seleccionada...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });
    try {
      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/empresa-terminal-rutas-DT/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Actualizar el estado de records con los datos recibidos
      setRecords(response.data);

      // Cerrar el modal de carga
      MySwal.close();
    } catch (error) {
      console.error("Error al obtener datos:", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  useEffect(() => {
    fetchempresasysuscps();
  }, []);

  const fetchempresasysuscps = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_empresas_terminal_solo_cps",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setEmpresas(response.data.empresas_terminal);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar las empresas disponibles.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const fetchTodayRecords2 = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Activaciones...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/get_activations_new",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.activations;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Activaciones de Hoy",
          html: `<p style="text-align:center; margin:20px;">No se encontraron Activaciones.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
          <tr>
            <th class="table-header" style="text-align: start; font-size: 12px">EMPRESA</th>
            <th class="table-header" style="text-align: start; font-size: 12px">DESTINO FINAL</th>
            <th class="table-header" style="text-align: start; font-size: 12px; width: 90px">UNIDAD</th>
            <th class="table-header" style="text-align: start; font-size: 12px; width: 100px">PLACA</th>
            <th class="table-header" style="text-align: start; font-size: 12px; width: 100px">ACCIONES</th>
          </tr>
        `;
        const statusMap = {
          ACTIVE: "ACTIVO",
          NO_ACTIVE: "BLOQUEADO",
        };

        const tableRows = data
          .map(
            (item) => `
            <tr>
              <td>${item.empresa?.nombre || "N/A"}</td>
              <td>${item.ruta?.destino_final?.destino_final_detalle?.descripcion || "No disponible"}</td>
              <td>${item.numero_de_unidad}</td>
              <td>${item.dt_terminal_empresa?.placa || "No disponible"}</td>
              <td><button class="btn btn-danger" id="delete-${item.id}" style="font-size: 10px; padding: 5px 10px 5px 10px"><i class="fa-solid fa-trash"></i></button></td>
            </tr>
          `
          )
          .join("");

        const table = `
          <input type="text" id="search-listines" placeholder="Buscar..." class="swal2-input" style="margin-bottom: 10px;">
          <div style="max-height: 400px; overflow-y: auto;">
            <table class="custom-table rounded-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4" style="width: 600px; border-collapse: collapse; font-size: 12px; min-width: 100%">
              <thead>
                ${tableHeaders}
              </thead>
              <tbody id="listines-list" style="text-align: start">
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;

        MySwal.fire({
          title: "Registros de Hoy",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didRender: () => {
            data.forEach((item) => {
              const deleteButton = document.getElementById(`delete-${item.id}`);
              deleteButton.addEventListener("click", () =>
                deleteActivation(item.id)
              );
            });

            const searchInput = document.getElementById("search-listines");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const listinesList = document.getElementById("listines-list");
              const filteredRows = data
                .filter(
                  (item) =>
                    item.empresa.nombre.toLowerCase().includes(searchTerm) ||
                    item.ruta.destino_final.destino_final_detalle.descripcion
                      .toLowerCase()
                      .includes(searchTerm) ||
                    item.numero_de_unidad.toLowerCase().includes(searchTerm) ||
                    item.dt_terminal_empresa.placa
                      .toLowerCase()
                      .includes(searchTerm)
                )
                .map(
                  (item) => `
                    <tr>
                      <td>${item.empresa?.nombre || "N/A"}</td>
                      <td>${item.ruta?.destino_final?.destino_final_detalle?.descripcion || "No disponible"}</td>
                      <td>${item.numero_de_unidad}</td>
                      <td>${item.dt_terminal_empresa?.placa || "No disponible"}</td>
                      <td><button class="btn btn-danger" id="delete-${item.id}" style="font-size: 10px; padding: 5px 10px 5px 10px"><i class="fa-solid fa-trash"></i></button></td>
                    </tr>
                  `
                )
                .join("");
              listinesList.innerHTML = filteredRows;
            });

            // Delegación de eventos para los botones de cambio de estado
            // Delegación de eventos para los botones de eliminación
            document
              .getElementById("listines-list")
              .addEventListener("click", (event) => {
                if (event.target.closest(".btn-danger")) {
                  const button = event.target.closest(".btn-danger");
                  const itemId = button.id.replace("delete-", "");
                  deleteActivation(itemId);
                }
              });

            document
              .getElementById("listines-list")
              .addEventListener("click", async (event) => {
                if (event.target.classList.contains("change-status")) {
                  const button = event.target;
                  const userId = button.getAttribute("data-id");
                  const userStatus = button.getAttribute("data-status");

                  const actionText =
                    userStatus === "ACTIVE" ? "bloquear" : "activar";
                  const confirmationText =
                    userStatus === "ACTIVE"
                      ? "¡Si haces esto la Empresa será bloqueada!"
                      : "¡La Empresa podrá usar su cuenta nuevamente!";

                  const confirmation = await MySwal.fire({
                    title: `¿Estás seguro de que quieres ${actionText} esta empresa?`,
                    text: confirmationText,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: `Sí, ${actionText}`,
                    cancelButtonText: "Cancelar",
                  });

                  if (confirmation.isConfirmed) {
                    MySwal.fire({
                      title: `${actionText.charAt(0).toUpperCase() + actionText.slice(1)}...`,
                      html: `<p style="text-align:center; margin:20px;">Actualizando estado de la empresa...</p>`,
                      allowOutsideClick: false,
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });

                    try {
                      await axios.put(
                        `${process.env.BACKEND_URL}/api/empresadeterminal/${userId}/updateStatus`,
                        {},
                        {
                          headers: {
                            Authorization: `Bearer ${store.token}`,
                          },
                        }
                      );

                      MySwal.fire({
                        title: `Empresa ${actionText === "bloquear" ? "bloqueado" : "activado"}`,
                        text: `La Empresa ha sido ${actionText === "bloquear" ? "bloqueada" : "activada"} exitosamente.`,
                        icon: "success",
                        confirmButtonText: "Cerrar",
                      });

                      fetchTerminal();
                    } catch (error) {
                      MySwal.fire({
                        title: "Error",
                        text: "Hubo un error al intentar actualizar el estado de la empresa",
                        icon: "error",
                        confirmButtonText: "Cerrar",
                      });
                      console.error(
                        "Error al cambiar el estado de la empresa",
                        error
                      );
                    }
                  }
                }
              });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener usuarios", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener usuarios. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const deleteActivation = async (activationId) => {
    try {
      const result = await MySwal.fire({
        title: "Confirmar eliminación",
        text: "¿Estás seguro de que deseas eliminar este registro?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Eliminar",
      });

      if (result.isConfirmed) {
        const response = await axios.delete(
          `${process.env.BACKEND_URL}/api/eliminar_activation/${activationId}`,
          {
            headers: { Authorization: `Bearer ${store.token}` },
          }
        );

        console.log("Respuesta del backend:", response.data);

        await MySwal.fire("Eliminado", response.data.message, "success");
        return true;
      }
    } catch (error) {
      console.error("Error en la eliminación:", error);
      const errorMsg =
        error.response?.data?.message || "Error al eliminar el registro.";
      await MySwal.fire("Error", errorMsg, "error");
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/postActivationNuevo",
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      setFormData({
        contingencia_dt: false,
        contingencia_ruta: false,
        empresa_terminal_id: "",
        empresa: "",
        rif: "",
        ruta_id: "",
        ruta: "",
        dt_id: "",
        dt: "",
        fecha_de_viaje: "",
        hora_de_salida: "",
        destino_final: "",
        numero_de_unidad: "",
        puerta: "",
        parada: "",
      });

      MySwal.fire({
        title: "Activación de Pista Registrada",
        text: "La activación de pista ha sido registrada exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });

      fetchempresasysuscps();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar la activación de pista.";
      MySwal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsSubmitting(false); // Rehabilitar el botón al finalizar
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const openCompanySelection = () => {
    setRecords([]);
    setFilteredParadas([]);
    setFilteredRutas([]);
    setFilteredHorarios([]);
    setFormData({
      contingencia_dt: false,
      contingencia_ruta: false,
      empresa_terminal_id: "",
      empresa: "",
      rif: "",
      ruta_id: "",
      ruta: "",
      dt_id: "",
      dt: "",
      hora_de_salida: "",
      destino_final: "",
      numero_de_unidad: "",
      puerta: "",
      parada: "",
    });
    setTimeout(() => {
      MySwal.fire({
        title: (
          <p>
            <i
              className="fa-solid fa-user-tie"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Empresas de Transporte
          </p>
        ),
        html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${empresas
            .map(
              (company) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${company.id}"
              data-name="${company.empresa.nombre}"
              data-rif="${company.empresa.rif}"
              >  <!-- Añadimos data-name con el nombre -->
              ${company.empresa.nombre} <br> ${company.empresa.rif}
            </button>`
            )
            .join("")}
        </div>
      `,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Cerrar",
        didOpen: () => {
          const companySearch = document.getElementById("companySearch");
          const companyList = document.getElementById("companyList");

          // Manejador de filtro para el input de búsqueda
          companySearch.addEventListener("input", (e) => {
            const query = e.target.value.toLowerCase();
            const filteredEmpresas = empresas.filter(
              (company) =>
                company.empresa.nombre.toLowerCase().includes(query) ||
                company.empresa.rif.toLowerCase().includes(query)
            );
            companyList.innerHTML = filteredEmpresas
              .map(
                (company) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${company.id}"
                  data-name="${company.empresa.nombre}"
                  data-rif="${company.empresa.rif}"
                  >  <!-- Añadimos data-name con el nombre -->
                  ${company.empresa.nombre} <br> ${company.empresa.rif}
                </button>`
              )
              .join("");

            // Agregar listeners a cada opción filtrada
            const buttons = companyList.querySelectorAll(".company-option");
            buttons.forEach((button) => {
              button.addEventListener("click", () => {
                const companyId = button.dataset.id;
                const companyName = button.dataset.name; // Usamos el nombre de la empresa
                const companyRif = button.dataset.rif; // Usamos el nombre de la empresa
                selectCompany(companyId, companyName, companyRif); // Pasamos el ID y el nombre
              });
            });
          });

          // Listener para opciones de empresa inicialmente
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const companyId = button.dataset.id;
              const companyName = button.dataset.name;
              const companyRif = button.dataset.rif; // Usamos el nombre de la empresa
              selectCompany(companyId, companyName, companyRif); // Pasamos el ID y el nombre
            });
          });
        },
      });
    }, 100);
  };

  const openCompanyDT = () => {
    console.log(formData);
    MySwal.fire({
      title: (
        <p>
          <i
            className="fa-solid fa-bus"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          DT's disponibles
        </p>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${
            records?.dts?.length > 0
              ? records.dts
                  .map(
                    (dt) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${dt.id}"
                  data-name="${dt.placa}"
                  data-contingencia="${dt.contingencia}"
                >
                  ${dt.placa}
                </button>`
                  )
                  .join("")
              : '<p style="text-align: center; color: gray;">No hay datos disponibles</p>'
          }
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Validar si hay datos antes de agregar eventos
        if (records?.dts?.length > 0) {
          companySearch.addEventListener("input", (e) => {
            const query = e.target.value.toLowerCase();
            const filteredEmpresas = records.dts.filter((dt) =>
              dt.placa.toLowerCase().includes(query)
            );
            companyList.innerHTML = filteredEmpresas
              .map(
                (dt) => `
                  <button 
                    class="btn company-option company-list-activation-list" 
                    data-id="${dt.id}"
                    data-name="${dt.placa}"
                    data-contingencia="${dt.contingencia}"
                  >
                    ${dt.placa}
                  </button>`
              )
              .join("");

            // Agregar listeners a las opciones filtradas
            const buttons = companyList.querySelectorAll(".company-option");
            buttons.forEach((button) => {
              button.addEventListener("click", () => {
                const DT_id = button.dataset.id;
                const DT = button.dataset.name;
                const contingencia = button.dataset.contingencia;
                selectDT(DT_id, DT, contingencia);
              });
            });
          });

          // Listener para opciones de empresa inicialmente
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const DT_id = button.dataset.id;
              const DT = button.dataset.name;
              const contingencia = button.dataset.contingencia;
              selectDT(DT_id, DT, contingencia);
            });
          });
        }
      },
    });
  };

  const filterCompanies = (query) => {
    const filteredCompanies = companies.filter((company) =>
      company.toLowerCase().includes(query.toLowerCase())
    );
    // Actualiza el HTML del Swal para mostrar solo los resultados filtrados
    document.getElementById("companyList").innerHTML = filteredCompanies
      .map((company) => `<button class="company-option">${company}</button>`)
      .join("");
  };

  const selectCompany = (companyId, companyName, companyRif) => {
    setRecords([]);
    setFilteredParadas([]);
    setFilteredRutas([]);
    setFilteredHorarios([]);
    setFormData({
      contingencia_dt: false,
      contingencia_ruta: false,
      empresa_terminal_id: "",
      empresa: "",
      rif: "",
      ruta_id: "",
      ruta: "",
      dt_id: "",
      dt: "",
      hora_de_salida: "",
      destino_final: "",
      numero_de_unidad: "",
      puerta: "",
      parada: "",
    });
    const empresaSeleccionada = empresas.find(
      (empresa) => empresa.id === parseInt(companyId)
    );

    // Cargar rutas de la empresa seleccionada
    showRouteDetails(companyId);

    setFormData({
      ...formData,
      empresa: companyName,
      empresa_terminal_id: companyId,
      rif: companyRif,
      contingencia_dt: false,
      contingencia_ruta: false,
      ruta_id: "",
      ruta: "",
      dt_id: "",
      dt: "",
      hora_de_salida: "",
      destino_final: "",
      numero_de_unidad: "",
      puerta: "",
      parada: "",
    });

    MySwal.close(); // Cierra el modal
  };

  const selectDT = (DT_id, DT, contingencia) => {
    setFormData({
      ...formData,
      dt: DT,
      dt_id: DT_id,
      contingencia_dt: contingencia,
    });

    MySwal.close(); // Cierra el modal
  };

  useEffect(() => {
    if (records && records.rutas) {
      // Obtener paradas únicas de las rutas
      const paradasUnicas = Array.from(
        new Set(records.rutas.map((ruta) => ruta.parada))
      );

      setFilteredParadas(paradasUnicas);
    }
  }, [records]); // Se ejecuta solo cuando `records` cambia

  // Filtrar rutas basadas en la Parada seleccionada
  const handleParadaChange = (e) => {
    const selectedParada = e.target.value;

    // Filtrar las rutas según la parada seleccionada
    const rutasFiltradas = records.rutas.filter(
      (ruta) => ruta.parada === selectedParada
    );

    // Extraer rutas únicas (en este caso, ya deberían ser únicas por la base de datos)
    const rutasUnicas = rutasFiltradas.map((ruta) => ruta.ruta);

    setFilteredRutas(rutasUnicas);

    setFormData((prevData) => ({
      ...prevData,
      parada: selectedParada,
    }));
  };

  const handleRutaChange = (e) => {
    const selectedRuta = e.target.value;

    // Buscar la ruta seleccionada
    const rutaSeleccionada = records.rutas.find(
      (ruta) => ruta.ruta === selectedRuta
    );

    // Extraer los horarios de salida únicos
    const horariosUnicos = Array.from(
      new Set(rutaSeleccionada.turnos.map((turno) => turno.turno_hora_real))
    );

    setFilteredHorarios(horariosUnicos);

    // Guardar el ID de la ruta en ruta_id
    setFormData((prevData) => ({
      ...prevData,
      ruta: selectedRuta, // Guardar el nombre de la ruta
      ruta_id: rutaSeleccionada.id, // Guardar el ID de la ruta
      contingencia_ruta: rutaSeleccionada.contingencia, // Guardar el ID de la ruta
      destino_final:
        rutaSeleccionada.destino_final.destino_final_detalle.descripcion,
    }));
  };

  const handleReferenciaChange = (e) => {
    const selectedRef = e.target.value;

    // Actualizar el estado de referencia en formData
    setFormData((prevData) => ({
      ...prevData,
      referencia: selectedRef,
    }));
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div
          style={{
            textAlign: "center",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
          }}
          className="sdfdfgrt54w98e7we9contewe"
        >
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-bullhorn"
              id="icon-tittle-general"
            ></i>
          </div>
          <p
            style={{ fontSize: "13px", margin: "0" }}
            className="asdjhfnerer4pdeterminal"
          >
            La activación que hagas podrá ser eliminada en limitados casos, y
            aparecerá en la programación en vivo
          </p>
        </div>
        <div className="container asklnduiashndaijsnxansxsaas3">
          <form
            className="d-flex sd98f4e8wf4cwe987cwe984cwe"
            onSubmit={handleSubmit}
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="date"
                  name="fecha_de_viaje"
                  value={formData.fecha_de_viaje}
                  onChange={handleChange}
                  required
                  style={{ cursor: "pointer" }}
                />
                <label>Fecha del viaje</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="empresa"
                  onClick={openCompanySelection}
                  readOnly
                  onChange={handleChange}
                  value={formData.empresa}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Línea de Transporte</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="rif"
                  value={formData.rif}
                  required
                  readOnly
                />
                <label>RIF</label>
              </div>
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="parada"
                  value={formData.parada}
                  onChange={handleParadaChange}
                  required
                  style={{ cursor: "pointer" }}
                >
                  <option value="" disabled>
                    Selecciona Parada
                  </option>
                  {filteredParadas.map((parada, index) => (
                    <option key={index} value={parada}>
                      {parada}
                    </option>
                  ))}
                </select>
                <label>Parada</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="ruta"
                  value={formData.ruta}
                  onChange={handleRutaChange}
                  required
                  style={{ cursor: "pointer" }}
                >
                  <option value="" disabled>
                    Selecciona Ruta
                  </option>
                  {filteredRutas.map((ruta, index) => (
                    <option key={index} value={ruta}>
                      {ruta}
                    </option>
                  ))}
                </select>
                <label>Ruta</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="destino_final"
                  value={formData.destino_final}
                  readOnly
                  required
                />
                <label>Destino Final</label>
              </div>

              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="hora_de_salida"
                  value={formData.hora_de_salida}
                  onChange={(e) =>
                    setFormData({ ...formData, hora_de_salida: e.target.value })
                  }
                  required
                  style={{ cursor: "pointer" }}
                >
                  <option value="" disabled>
                    Selecciona Hora de Salida
                  </option>
                  {filteredHorarios.map((hora, index) => (
                    <option key={index} value={hora}>
                      {hora}
                    </option>
                  ))}
                </select>
                <label>Hora de Salida</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="numero_de_unidad"
                  value={formData.numero_de_unidad}
                  onChange={handleChange}
                  required
                />
                <label>Número de Unidad</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="dt"
                  value={formData.dt}
                  onClick={openCompanyDT}
                  onChange={handleChange}
                  required
                  style={{ textTransform: "uppercase" }}
                  readOnly
                />
                <label>Placa</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="puerta"
                  value={formData.puerta}
                  onChange={handleChange}
                  required
                />
                <label>Puerta</label>
              </div>
            </div>

            <div
              style={{
                flexDirection: "column",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className="buttonCargadeDatosDeLoginregistromovimiento"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Registrando..." : "Registrar"}{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </form>
        </div>
        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
          <button
            className="asd98q7wd65q1wd8xqw49x8e4wc"
            onClick={fetchTodayRecords2}
          >
            <i
              className="fa-solid fa-list"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Activaciones Hoy
          </button>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
