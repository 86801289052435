import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#FFF",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    // backgroundColor: "#24827c",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeader2: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#8c1010",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableColTotal: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#980000",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    color: "white",
    fontSize: 9,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
    textAlign: "center",
    // color: "white",
  },
  tableCell: {
    margin: 4,
    fontSize: 7,
  },
  tableCellTotal: {
    margin: 4,
    fontSize: 9,
    backgroundColor: "#980000",
    color: "white",
    fontWeight: "bold",
  },
});

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "7%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOtherPasajeros = {
  ...tableStyles.tableColHeader,
  width: "8%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOtherViajes = {
  ...tableStyles.tableColHeader,
  width: "6%", // Mayor ancho para los demás
  backgroundColor: "#9fc5e8",
};

tableStyles.tableColHeaderOtherViajes2 = {
  ...tableStyles.tableColHeader,
  width: "7%", // Mayor ancho para los demás
  backgroundColor: "#b6d7a8",
};

tableStyles.tableColHeaderOther2 = {
  ...tableStyles.tableColHeader,
  width: "9%", // Mayor ancho para los demás
  backgroundColor: "#b6d7a8",
};

tableStyles.tableColHeaderOther3 = {
  ...tableStyles.tableColHeader,
  width: "14%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther4 = {
  ...tableStyles.tableColHeader,
  width: "16%", // Mayor ancho para los demás
  backgroundColor: "#b6d7a8",
};

tableStyles.tableColHeaderRuta = {
  ...tableStyles.tableColHeader,
  width: "30%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderEstatus = {
  ...tableStyles.tableColHeader,
  width: "10%", // Mayor ancho para los demás
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "7%", // Mayor ancho para los demás
};

tableStyles.tableColOtherTotal = {
  ...tableStyles.tableColTotal,
  width: "7%", // Mayor ancho para los demás
};

tableStyles.tableColOtherPasajeros = {
  ...tableStyles.tableCol,
  width: "8%", // Mayor ancho para los demás
};

tableStyles.tableColOtherViajes = {
  ...tableStyles.tableCol,
  width: "6%", // Mayor ancho para los demás
};

tableStyles.tableColOtherPasajerosTotal = {
  ...tableStyles.tableColTotal,
  width: "8%", // Mayor ancho para los demás
};

tableStyles.tableColOtherViajesTotal = {
  ...tableStyles.tableColTotal,
  width: "6%", // Mayor ancho para los demás
};

tableStyles.tableColOther2 = {
  ...tableStyles.tableCol,
  width: "9%", // Mayor ancho para los demás
};

tableStyles.tableColOther2Total = {
  ...tableStyles.tableColTotal,
  width: "9%", // Mayor ancho para los demás
};

tableStyles.tableColRuta = {
  ...tableStyles.tableCol,
  width: "30%", // Mayor ancho para los demás
};

tableStyles.tableColEstatus = {
  ...tableStyles.tableCol,
  width: "10%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  page: {
    padding: 20, // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 10,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    paddingTop: 5,
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
});

const EntreFechasDetailPDF = ({
  records,
  fechaInicio,
  fechaFinal,
  formatDate,
  totales,
}) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.header} fixed>
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
          style={styles.logo2}
        />
      </View>
      {/* <View style={styles.header}>
        <Text style={styles.letter2}>
          Rutas de {empresaDetalle.empresa.empresa.nombre}
        </Text>
      </View> */}
      <View>
        {/* Primera columna */}
        <View>
          <Text style={{ textAlign: "center" }}>
            TERMINAL LA BANDERA, ESTADÍSTICAS OPERACIONALES
          </Text>
          <Text
            style={{ textAlign: "center", fontSize: "12px", color: "gray" }}
          >{`FECHA: DEL ${fechaInicio} AL ${fechaFinal} `}</Text>
        </View>
      </View>
      {/* Rutas CPS */}
      <View style={{ marginTop: 10 }}></View>
      <View style={styles.section}>
        <View style={{ marginTop: 10 }}></View>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow} fixed>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}></Text>
            </View>
            <View style={tableStyles.tableColHeaderOther3}>
              <Text style={tableStyles.tableCellHeader}>
                SALIDAS EN {"\n"} UNIDADES POR {"\n"} PUESTO
              </Text>
            </View>
            <View style={tableStyles.tableColHeaderOther3}>
              <Text style={tableStyles.tableCellHeader}>
                SALIDAS EN {"\n"} INTERURBANOS {"\n"} CORTOS
              </Text>
            </View>
            <View style={tableStyles.tableColHeaderOther3}>
              <Text style={tableStyles.tableCellHeader}>
                SALIDAS EN {"\n"} INTERURBANOS {"\n"} LARGOS
              </Text>
            </View>
            <View style={tableStyles.tableColHeaderOther3}>
              <Text style={tableStyles.tableCellHeader}>TOTAL SALIDAS</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther3}>
              <Text style={tableStyles.tableCellHeader}>RETORNOS</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}></Text>
            </View>
            <View style={tableStyles.tableColHeaderOther4}>
              <Text style={tableStyles.tableCellHeader}>TOTAL MOVILIDAD</Text>
            </View>
          </View>
          <View style={tableStyles.tableRow} fixed>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>FECHA</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherPasajeros}>
              <Text style={tableStyles.tableCellHeader}>PASAJEROS</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherViajes}>
              <Text style={tableStyles.tableCellHeader}>VIAJES</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherPasajeros}>
              <Text style={tableStyles.tableCellHeader}>PASAJEROS</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherViajes}>
              <Text style={tableStyles.tableCellHeader}>VIAJES</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherPasajeros}>
              <Text style={tableStyles.tableCellHeader}>PASAJEROS</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherViajes}>
              <Text style={tableStyles.tableCellHeader}>VIAJES</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherPasajeros}>
              <Text style={tableStyles.tableCellHeader}>PASAJEROS</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherViajes}>
              <Text style={tableStyles.tableCellHeader}>VIAJES</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherPasajeros}>
              <Text style={tableStyles.tableCellHeader}>PASAJEROS</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherViajes}>
              <Text style={tableStyles.tableCellHeader}>VIAJES</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>ACOMPAÑANTES</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther2}>
              <Text style={tableStyles.tableCellHeader}>USUARIOS</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherViajes2}>
              <Text style={tableStyles.tableCellHeader}>VIAJES</Text>
            </View>
          </View>
          {records.map((detalle, index) => (
            <View style={tableStyles.tableRow} key={index}>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {formatDate(detalle.fecha)}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherPasajeros}>
                <Text style={tableStyles.tableCell}>0</Text>
              </View>
              <View style={tableStyles.tableColOtherViajes}>
                <Text style={tableStyles.tableCell}>0</Text>
              </View>
              <View style={tableStyles.tableColOtherPasajeros}>
                <Text style={tableStyles.tableCell}>0</Text>
              </View>
              <View style={tableStyles.tableColOtherViajes}>
                <Text style={tableStyles.tableCell}>0</Text>
              </View>
              <View style={tableStyles.tableColOtherPasajeros}>
                <Text style={tableStyles.tableCell}>
                  {detalle.total_pasajeros}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherViajes}>
                <Text style={tableStyles.tableCell}>{detalle.viajes}</Text>
              </View>
              <View style={tableStyles.tableColOtherPasajeros}>
                <Text style={tableStyles.tableCell}>
                  {detalle.total_pasajeros}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherViajes}>
                <Text style={tableStyles.tableCell}>{detalle.viajes}</Text>
              </View>
              <View style={tableStyles.tableColOtherPasajeros}>
                <Text style={tableStyles.tableCell}>0</Text>
              </View>
              <View style={tableStyles.tableColOtherViajes}>
                <Text style={tableStyles.tableCell}>0</Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>0</Text>
              </View>
              <View style={tableStyles.tableColOther2}>
                <Text style={tableStyles.tableCell}>
                  {detalle.total_pasajeros}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>{detalle.viajes}</Text>
              </View>
            </View>
          ))}
          <View style={tableStyles.tableRow}>
            <View style={tableStyles.tableColOtherTotal}>
              <Text style={tableStyles.tableCellTotal}>TOTALES</Text>
            </View>
            <View style={tableStyles.tableColOtherPasajerosTotal}>
              <Text style={tableStyles.tableCellTotal}>0</Text>
            </View>
            <View style={tableStyles.tableColOtherViajesTotal}>
              <Text style={tableStyles.tableCellTotal}>0</Text>
            </View>
            <View style={tableStyles.tableColOtherPasajerosTotal}>
              <Text style={tableStyles.tableCellTotal}>0</Text>
            </View>
            <View style={tableStyles.tableColOtherViajesTotal}>
              <Text style={tableStyles.tableCellTotal}>0</Text>
            </View>
            <View style={tableStyles.tableColOtherPasajerosTotal}>
              <Text style={tableStyles.tableCellTotal}>
                {totales.total_pasajeros}
              </Text>
            </View>
            <View style={tableStyles.tableColOtherViajesTotal}>
              <Text style={tableStyles.tableCellTotal}>
                {totales.total_viajes}
              </Text>
            </View>
            <View style={tableStyles.tableColOtherPasajerosTotal}>
              <Text style={tableStyles.tableCellTotal}>
                {totales.total_pasajeros}
              </Text>
            </View>
            <View style={tableStyles.tableColOtherViajesTotal}>
              <Text style={tableStyles.tableCellTotal}>
                {totales.total_viajes}
              </Text>
            </View>
            <View style={tableStyles.tableColOtherPasajerosTotal}>
              <Text style={tableStyles.tableCellTotal}>0</Text>
            </View>
            <View style={tableStyles.tableColOtherViajesTotal}>
              <Text style={tableStyles.tableCellTotal}>0</Text>
            </View>
            <View style={tableStyles.tableColOtherTotal}>
              <Text style={tableStyles.tableCellTotal}>0</Text>
            </View>
            <View style={tableStyles.tableColOther2Total}>
              <Text style={tableStyles.tableCellTotal}>
                {totales.total_pasajeros}
              </Text>
            </View>
            <View style={tableStyles.tableColOtherTotal}>
              <Text style={tableStyles.tableCellTotal}>
                {totales.total_viajes}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.footer} fixed>
        <Text>Reporte generado por el sistema login.intravialca-ve.com</Text>
      </View>
    </Page>
  </Document>
);

export const ReporteEntreFechasEst = () => {
  const { store } = useContext(Context);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [reporte, setReporte] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const generarReporte = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/reporte/avisos_cobro",
        { fecha_inicio: fechaInicio, fecha_final: fechaFinal },
        {
          headers: { Authorization: "Bearer " + store.token },
        }
      );

      Swal.fire("Éxito", "Reporte generado exitosamente", "success");
      setReporte(response.data);
    } catch (error) {
      Swal.fire(
        "Error",
        error.response.data.message || "Error interno",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const showRecordsPdfDownload = async (records) => {
    try {
      setIsLoading(true);

      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Elaborando PDF...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const detalles = records.detalles_por_dia;
      const totales = records.totales_generales;

      if (Array.isArray(detalles) && detalles.length > 0) {
        Swal.close();

        const formatDate = (dateString) => {
          const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
          const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
          const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
          const year = date.getUTCFullYear();
          return `${day}-${month}-${year}`;
        };

        // Generar el PDF
        const pdfBlob = await pdf(
          <EntreFechasDetailPDF
            records={detalles}
            fechaInicio={formatDate(fechaInicio)}
            fechaFinal={formatDate(fechaFinal)}
            formatDate={formatDate}
            totales={totales}
          />
        ).toBlob();

        // Crear un enlace de descarga programático
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download =
          formatDate(fechaInicio) +
          "-" +
          formatDate(fechaFinal) +
          "-reporteRL.pdf";
        document.body.appendChild(link);
        link.click(); // Simular clic para descargar el archivo
        document.body.removeChild(link);
      } else {
        Swal.fire({
          title: `No datos suficientes para poder generar un reporte`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos. Por favor, intentelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-chart-simple"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Reporte Entre Fechas</p>
        </div>
        <div className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100">
          <div
            className="container asklnduiashndaijsnxansxsaas3"
            style={{ marginBottom: "20px" }}
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="date"
                  required
                  value={fechaInicio}
                  onChange={(e) => setFechaInicio(e.target.value)}
                />
                <label>Fecha Inicio:</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="date"
                  required
                  value={fechaFinal}
                  onChange={(e) => setFechaFinal(e.target.value)}
                />
                <label>Fecha Final:</label>
              </div>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              onClick={generarReporte}
              disabled={isSubmitting}
              style={{ width: "200px" }}
            >
              {isSubmitting ? "Generando..." : "Generar Reporte"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
          <button
            className="asd98q7wd65q1wd8xqw49x8e4wc"
            onClick={() => showRecordsPdfDownload(reporte)}
          >
            <i
              className="fa-solid fa-file-pdf"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Descargar Reporte
          </button>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
        <hr style={{ width: "90%" }}></hr>

        {reporte && (
          <div style={{ padding: "10px", width: "100%" }}>
            <h2 style={{ textAlign: "center", margin: "0" }}>Estadísticas</h2>
            <div
              className="custom-table-container m-0"
              style={{
                maxWidth: "100%",
                padding: "10px",
                overflow: "auto",
                maxHeight: "80vh",
              }}
            >
              <table className="custom-table rounded-table">
                <thead>
                  <tr>
                    <th className="table-header">Total Viajes</th>
                    <th className="table-header">Discapacitados</th>
                    <th className="table-header">Tercera Edad</th>
                    <th className="table-header">Niños 4 o menos</th>
                    <th className="table-header">Cortesías</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-row">
                    <td className="table-cell">
                      {reporte.totales_generales.total_viajes}
                    </td>
                    <td className="table-cell">
                      {reporte.totales_generales.discapacitados}
                    </td>
                    <td className="table-cell">
                      {reporte.totales_generales.tercera_edad}
                    </td>
                    <td className="table-cell">
                      {reporte.totales_generales.ninos_4_menos}
                    </td>
                    <td className="table-cell">
                      {reporte.totales_generales.cortesias}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h2
              style={{
                textAlign: "center",
                marginBottom: "0",
                marginTop: "20px",
              }}
            >
              Detalle
            </h2>
            <div
              className="custom-table-container m-0"
              style={{
                maxWidth: "100%",
                padding: "10px",
                overflow: "auto",
                maxHeight: "80vh",
              }}
            >
              <table
                className="custom-table rounded-table"
                style={{ minWidth: "600px" }}
              >
                <thead>
                  <tr>
                    <th className="table-header" style={{ minWidth: "100px" }}>
                      Fecha
                    </th>
                    <th className="table-header" style={{ minWidth: "100px" }}>
                      adultos
                    </th>
                    <th className="table-header" style={{ minWidth: "100px" }}>
                      Tercera Edad
                    </th>
                    <th className="table-header" style={{ minWidth: "100px" }}>
                      Discapacitados
                    </th>
                    <th className="table-header" style={{ minWidth: "100px" }}>
                      Niños 4 o menos
                    </th>
                    <th className="table-header" style={{ maxWidth: "100px" }}>
                      Cortesias
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reporte.detalles_por_dia.map((detalle) => {
                    return (
                      <tr className="table-row">
                        <td className="table-cell">
                          {formatDate(detalle.fecha)}
                        </td>
                        <td className="table-cell">{detalle.adultos}</td>
                        <td className="table-cell">{detalle.tercera_edad}</td>
                        <td className="table-cell">{detalle.discapacitados}</td>
                        <td className="table-cell">{detalle.ninos_4_menos}</td>
                        <td className="table-cell">{detalle.cortesias}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
